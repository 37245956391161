import * as React from "react";
import {
  Container,
  Dropdown,
  Accordion,
  Card,
  Button,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ProductSlideout } from "./ProductSlideout";
import cellEditFactory from "react-bootstrap-table2-editor";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
// import { SortableContainer, SortableElement,SortableHandle } from "react-sortable-hoc";
import createDOMPurify from "dompurify";
import { CustomerService } from "../../Common/Services/CustomerService";
import "react-confirm-alert/src/react-confirm-alert.css";
import { faArrowUp, faListSquares } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  FaArrowUpFromBracket,
  FaCirclePlus,
  FaEllipsis,
  FaCircleMinus,
} from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import "../../Modules/customer.scss";
export class PriceBreakdownMaster extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      pricebreakdown: [],
      productid: 0,
      isSaveOpen: false,
      loading: false,
      isWait: false,
      newRow: { id: 0, itemName: "", srno: 0, status: 1 },
      formData: { processlist: [] },
      templateColumns: this.templateColumns,
    };
  }

  private templateColumns = [
    {
      dataField: "rowId",
      text: "Sr No.",
      headerStyle: { width: "5%"},

      formatter: (cell: any, row: any, rowIndex: any) => {
        return <div>{rowIndex + 1}</div>;
      },
    },
    {
      dataField: "isSelected",
      text: "",
      classes: "expanding-bar",
      headerStyle: { width: "1%" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center txt-bold">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="toolp">Click to move up this step</Tooltip>}
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                size="xs"
                onClick={(e) => this.handleRowOrdering(e, row, "up")}
              />
            </OverlayTrigger>
          </div>
        );
      },
    },

    {
      dataField: "isSelected",
      text: "",
      classes: "expanding-bar",
      headerStyle: { width: "1%"},
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center txt-bold">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="toolp">Click to move down this step</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faArrowDown}
                size="xs"
                onClick={(e) => this.handleRowOrdering(e, row, "down")}
              />
            </OverlayTrigger>
          </div>
        );
      },
    },

    {
      dataField: "itemName",
      text: "Item Name",
      headerStyle: { width: "15%" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            <Form.Control
              // tabIndex={this.state.tabEnable}
              id={"txtitemName" + rowIndex + "txt"}
              type="text"
              // placeholder="Enter Part No"
              value={row.itemName}
              onChange={(e) =>
                this.updateState(e, rowIndex, "itemName", rowIndex)
              }
              autoComplete="off"
              disabled={false}
              title=""
              maxLength={500}
            />
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Active",
      headerStyle: { width: "5%" , textAlign:"center"},
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtdescription" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Check
              className="knob"
              // tabIndex={this.state.tabEnable}
              id={"txtstatus" + rowIndex + "txt"}
              type="switch"
              checked={row.status}
              // placeholder="Enter Part No"
              //value={value.pDescription}
              onChange={(e) =>
                this.updateState(e, rowIndex, "status", rowIndex)
              }
              autoComplete="off"
              style={{textAlign:"center"}}
              // disabled={ row.id==6 ? true: false }
              title=""
              //maxLength={500}
            />
          </div>
        );
      },
    },
    {
      dataField: "Attachment",
      text: "",
      //headerFormatter: this.iconFormatter,
      headerStyle: { width: "30%"},
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];

        if (row.deleteAllowed == false) {
          return <div></div>;
        } else {
          return (
            <Dropdown
              className="more-action confirmation-modal"
              style={{textAlign:"start"}}
              //tabIndex={this.state.tabDisable}
            >
              <Dropdown.Toggle
                className="btn-outline-primary btn btn-primary more fa-trash"
                id="dropdown-more-delete"
                //  tabIndex={this.state.tabDisable}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    },
   
  ];

 

  componentDidMount() {
    this.GetPricebreakdownlist();
  }

  GetPricebreakdownlist() {
    CustomerService.GetPricebreakdown()
      .then(async (result: any[] | null) => {
        console.log("enter");

        if (result != null) {
          console.log("pricebreakdown", result);
          this.setState({ pricebreakdown: result });
        }
        resolve();
      })
      .catch((err: any) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  }

  handleRowOrdering = (e: any, row: any, type: any) => {
    let isSaveOpen = true;
    let searcResult = this.state.pricebreakdown;

    let nextOrderNo = type == "down" ? row.rowId + 1 : row.rowId - 1;
    let currentRow = searcResult.filter((x) => x.rowId == row.rowId);
    let nextRow = searcResult.filter((x) => x.rowId == nextOrderNo);

    if (nextRow != null && nextRow.length > 0) {
      nextRow[0].rowId = row.rowId;
      currentRow[0].rowId = nextOrderNo;

      searcResult = searcResult.sort((a, b) => a.rowId - b.rowId);

      this.setState({ pricebreakdown: searcResult }, () => {
        this.setState({ pricebreakdown: searcResult, isSaveOpen: isSaveOpen });
      });
    }
  };

  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let isSaveOpen = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let pricebreakdown = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.pricebreakdown))
      );

      if (type === "itemName") {
        e.preventDefault();

        pricebreakdown[index].itemName = e.target.value;

        this.setState({ pricebreakdown, isStateChanged, isSaveOpen }, () => {
          $("input[id^='txtitemName" + txtID + "txt']").focus();

          let txtdesc = $("input[id^='txtitemName" + txtID + "txt']")[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      if (type === "status") {
        e.preventDefault();

        if (e.target.checked) {
          pricebreakdown[index].status = 1;
        } else {
          pricebreakdown[index].status = 0;
        }
        this.setState({ pricebreakdown, isStateChanged, isSaveOpen }, () => {});
      }
    }
  };

  ishandleDeleteRow(row: any, mode: any) {
    let data = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.pricebreakdown))
    );
    const filteredData = data.filter((item) => item.rowId !== row.rowId);

    this.setState({
      pricebreakdown: filteredData,
      isStateChanged: true,
    });
  }

  handleNewRow = () => {
    let searcResult: any = [];
    let newRow: any = {};
    let isStateChanged = true;

    searcResult = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.pricebreakdown))
    );
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));

    let maxValueOfRow = Math.max(...searcResult.map((o) => o.rowId), 0);
    let len = searcResult.length;
    let maxRno = maxValueOfRow;
    let maxSrno = searcResult[len - 1].rowId;
    newRow.id = 0;
    newRow.rowId = Number(maxSrno) + 1;
    newRow.deleteAllowed = true;
    searcResult.push(newRow);
    this.setState({ searcResult: [] }, () => {
      this.setState({ pricebreakdown: searcResult, isStateChanged });
    });
  };

  discard = () => {
    this.GetPricebreakdownlist();
    this.setState({ isSaveOpen: false }, () => {});
  };

  saveApi = () => {
    let { pricebreakdown } = this.state;

    this.setState({ isWait: true });
    let processlistItem: any = [];
    pricebreakdown.forEach((items: any, index: any) => {
      let newIndex = index + 1;

      let item: any = {};

      item.rowId = items.rowId == null ? 0 : items.rowId;
      item.itemName = items.itemName;
      item.status = items.status;
      processlistItem.push(item);
    });
    console.log("processlistItem", processlistItem);
    var hasDuplicate = false;

    processlistItem
      .map((v) => v.itemName)
      .sort()
      .sort((a, b) => {
        if (a != null && b != null && a.toLowerCase() === b.toLowerCase())
          hasDuplicate = true;
      });

    if (
      processlistItem != undefined &&
      processlistItem.filter((x) => x.itemName == "").length > 0
    ) {
      this.setState({ isWait: false });
      toast.error("Please check empty Item Name ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userManagementHierarchy",
      });
      return;
    }

    if (hasDuplicate) {
      this.setState({ isWait: false });
      toast.error("Please check duplicate Item Name ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userManagementHierarchy",
      });
    } else {
      CustomerService.SavePriceBeakup(processlistItem).then(
        async (result: any | null) => {
          console.log("result",result);
          toast.success("Saved successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "userManagementHierarchy",
          });

          this.setState({ isSaveSpinner: false , isWait: false});
        }
      );
    }
  };

  render() {
    let confirmModalContent = [
      {
        title: "Delete Row",
        desc: "Are you sure you want to delete?",
        cancleAction: "Cancel",
        ActionTrue: "Delete",
      },
    ];
    const { showSlideout } = this.state;

    let { searcResult, isSaveOpen, loading, isWait, d } = this.state;

    return (
      <>
        <React.Fragment>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              Price Breakdown Master
              <div className="action-group d-flex flex-row ml-auto"></div>
            </div>
            <ToastContainer
              enableMultiContainer
              autoClose={3000}
              containerId={"userManagementHierarchy"}
            />

            {this.state.pricebreakdown.length > 0 && (
              <div className="table-responsive table-responsive1">
                <section className="pb-3">
                  <div className="table table-borderless align-middle transactions-list">
                    <BootstrapTable
                      id="tbltemplate2"
                      keyField="templateid"
                      data={this.state.pricebreakdown}
                      columns={this.state.templateColumns}
                    />
                  </div>
                </section>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-end height-20">
              <div className="d-flex action-btn align-items-center">
                <Button
                  type="button"
                  className="btn-ghost mr-auto"
                  onClick={this.handleNewRow}
                >
                  <FaCirclePlus className="align-middle lh-1`" />{" "}
                  <span className="align-middle lh-1">Add Row</span>
                </Button>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end height-20">
              {isSaveOpen && (
                <div
                  className="TabFooter-Pay"
                  style={{
                    minWidth: "83.7%",
                    width: "992px",
                    maxWidth: "100%",
                  }}
                >
                  <div className="bulkSelectFooter">
                    <div className="mr-auto message">
                      <span>You have unsaved changes</span>
                    </div>
                    <button
                      type="button"
                      onClick={this.discard}
                      className="btn btn-primary btn-discard"
                    >
                      Discard
                    </button>
                    <button
                      type="button"
                      disabled={isWait}
                      onClick={this.saveApi}
                      className="btn btn-primary"
                    >
                      {isWait ? "Loading..." : "Save Changes"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Container>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
        </React.Fragment>
      </>
    );
  }
}
