import React from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import cellEditFactory from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import { Utils } from "../Common/Utilis";
import { VendorService } from "../Common/Services/VendorService";
import {Customerorderslideout}  from './Customerorderslideout';
import { resolve, reject } from "q";
import chevronRight from "../Common/Assets/Images/icons/bx-chevron-right.svg";
import "../App.scss";
import "./slideout.scss";
import { VendorSearch } from "../Common/Components/VendorSearch";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { ProductService } from "../Common/Services/ProductService";
import { ConfirmationModal } from "../Common/Components/ConfirmationModal";
import { ConfirmationModalNonTable } from "../Common/Components/ConfirmationModalNonTable";
import { FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {JobCreations}  from './JobCreations';
import { MdDesignServices } from "react-icons/md";
import {MdBuild} from 'react-icons/md';
import { debug } from "console";
import Dropzone from "react-dropzone";
import {
  faPlus,
  faMinus,
  faPaperclip,
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../Common/Components/CommentBox";

import { SingleDropdownListWithoutSearch } from "../Common/Components/SingleDropdownListWithoutSearch";

let routeLeavingGuard: any = null;
export class VendorReceivingSlideout extends React.Component<any, any> {
  private customerSearch: any;
  private trDate: any;
  private parentScroll: any;
  private ChildComment: any;
 

  private transactionsColumns = [
    
    {
      dataField: "",
      text: "#",
     
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
      
        return (
          <div>
         {rowIndex + 1}
          </div>
        );
      },
    },
    
    {
      dataField: "itemName",
      text: "Item Name",
      headerClasses: "coa",

      headerStyle: { width: "400px" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

      formatter: (cell: any, row: any, rowIndex: any) => {
        
        return (
          <div className="disabled-area">
            <div className="more-action single-select-image dropdown coa-select">
            {row.itemName}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "glcode",
      text: "GL Code",
      headerStyle: { width: "100px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtglcode" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            {row.glCode}          
          </div>
        );
      },
    },


    {
      dataField: "qty",
      text: "Qty",

      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtqty" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            {row.qty}
          </div>
        );
      },
    },


  ];

  private templateColumns = [
  
    {
      dataField: "",
      text: "No.",
     
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
      
        return (
          <div>
               {rowIndex + 1}
          </div>
        );
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      headerStyle: { width: '150px' },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txttype" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
           {row.type}
          </div>
        );



      },
    },  
    {
      dataField: "JobNo",
      text: "Job No",

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
           <EllipsisWithTooltip placement="bottom">
           <Button className="btn-link" onClick={() => this.showSlideOut(row,'job')}>
           {row.jobNo}
           </Button>
           </EllipsisWithTooltip>
        );
      },
    },
    {
      dataField: "customerPoNumber",
      text: "CO #",
      hidden: false,
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <EllipsisWithTooltip placement="bottom">
          <Button className="btn-link" onClick={() => this.showSlideOut(row,'customer')}>
          {row.customerPoNumber}
          </Button>
        
       </EllipsisWithTooltip>
          
        );
      },
    }, 
    {
      dataField: 'tdescription',
      text: 'Description',
      headerStyle: { width: '400px', },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.jdescription}
          </div>

        );
      },

    },
    {
      dataField: 'processName',
      text: 'Process',
      headerStyle: { width: '200px' },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.processname}
          </div>
        );
      },

    }   
    ,
    {
      dataField: 'orderedQty',
      text: 'Qty',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.qtyOrdered}            
          </div>
        );
      },

    },
    {
      dataField: 'unitPrice',
      text: 'Unit Price',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.unitPrice}
          </div>
        );
      },

    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
          {row.unitPrice*row.qtyOrdered}
          
          </div>
        );
      },

    },
    {
      dataField: 'received',
      text: 'Receive Status',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
              <Form.Label>
           <SingleDropdownListWithoutSearch
                       // tabIndex={this.state.tabEnable}
                        itemList={this.state.selectOptions}
                        handleSelectedItem={this.handleSelectedItem.bind(this,rowIndex,1,row.id)}                        
                        defaultItem={row.received}
                        defaultText={"Select Filter"}
                        defaultName={row.received}
                        controlID="1"
                        id={"ddlselected"}
                      //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                      // disabled={disable}
                      /></Form.Label>
     
          
          </div>
        );
      },

    }      
  ];


  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.customerSearch = React.createRef();
    this.trDate = React.createRef();
    this.handleVendorChange = this.handleVendorChange.bind(this);     
    this.ChildComment = React.createRef();    
    templateColumns: this.templateColumns,
    this.state = {
      ship_via:"",
      showCustomerSlideout:false,
      showJobSlideout:false,
       CustomerPoNumber: this.props.CustomerPoNumber,
        id: this.props.id,
        productid: this.props.productid,
        partname: this.props.partname,
        partNo: this.props.partNo,
        qtyOrdered: this.props.qtyOrdered,
        itemNo: this.props.itemNo,
        poNumber: this.props.poNumber,
        dueDate: this.props.dueDate,
        jobDesc: this.props.jobDesc,
        selectedCustomer: this.props.selectedCustomer,
        orderdate: this.props.orderdate,
      tabEnable: 0,
      vendororderid: this.props.vendororderid,
      vendorordertype: this.props.vendorordertype,
      orderid: this.props.orderid,
      orderdetailid: 0,
      jobid:0,
      address: this.props.address,
      customercode: this.props.customercode,
      customerNameLatest: this.props.selectedCustomer,
      jobOrderlistByItem: [],
      orderlistitem: [],
      transactionsColumns: this.transactionsColumns,
      templateColumns: this.templateColumns,
      totalCount: 0,
      currentZoom: 0.7,
      
      orderData: [
        {
          defaultPartname: [],
          id: 0,
          orderID: 0,
          itemNo: 1,
          partname: "",
          partNo: "",
          dueDate: "",
          jobDesc: "",
          qtyOrdered: 0,
          unit: "",
          unitPrice: 0,
          discount: 0,
          total: 0,
          tenantid: 0,
          productid: 0,

        },
        // ,
        // {
        //     // defaultPartname: [],
        //     id: 0, orderID: 0, itemNo: 2, partname: '', partNo: '', dueDate: '', jobDesc: '', qtyOrdered: 0, unit: '', unitPrice: 0, discount: 0, total: 0, tenantid: 0, productid: 0,
        // }
      ],

      jobdetailsdata:[],
      
      formData: {
        OrderID: 0,
        CustomerID: 0,
        CustomerName: "",
        CustomerPoNumber: "",
        shippingInstruction: "",
        OrderDate: new Date(),
        ship_via:"",
        Address: "",
        VendorOrderType: "normal",
        customercode: "",
        IsEdit: "",
        OrderItems: [],
        Vendor3PartyOrderItems:[],
      },
      fileSize: 5242880,
      itemListdatam: [],
      inventorydata: [],
      defaultitemname : [],
      itemdetailsdata: [
        {
          defaultitemname: [],
          itemNo: "1",
          id: 0,
          itemname: "",
          glcode: "",
          description: "",
          itemid: 0,
          stockqty: 0,
          qty: 0,
          orderqty: 0,
          unitPrice: 0,
          unit: "",
        },

      ],
      itemdetailsdatanew: [
        {
          defaultitemname: [],
          itemNo: "1",
          id: 0,
          glcode: "",
          itemname: "",
          description: "",
          itemid: 0,
          stockqty: 0,
          qty: 0,
          orderqty: 0,
          unitPrice: 0,
          unit: "",
        },

      ],
      
    
       selectOptions :  [
      {  id: 'Completed',name: 'Completed'},
        {id: 'Partial',name:  'Partial'}     
       
     ]
 };
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.updatedCommentItem = this.updatedCommentItem.bind(this);
  }

  handleSelectedItem = (rowIndex: any,control: any,rowid:any, value: any) => {
 
    let jobdetailsdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );

    let jobOrderlistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
    );
    if(control === 1){
     jobdetailsdata[rowIndex].received = value;
    }
    else{ 
      let selectedRow = jobOrderlistByItem.filter((x)=> x.id == rowid);
      if(selectedRow.length > 0)
         selectedRow[0].received = value;
    }
    this.setState({
      jobdetailsdata: jobdetailsdata,
      jobOrderlistByItem: jobOrderlistByItem
    },
      () => {
        this.setState({ jobdetailsdata: jobdetailsdata,jobOrderlistByItem: jobOrderlistByItem });
      }
    );
  
  };
 
  scrollToBottom = () => {
    this.parentScroll.current.scrollTo({
      //top: this.parentScroll.current.clientHeight,
      top: this.parentScroll.current.scrollHeight,
      behavior: "smooth",
    });
  };
  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = this.state.formData.transcationDate;
    updatedItem.vendorName = this.state.formData.vendorName;
    updatedItem.amountTotal = this.state.totalAmount;
    return updatedItem;
};
CommentModalClose = () => {

  let uniqueno = this.state.formData.uniqueno;
  this.handleDiscard(uniqueno);
};

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  closeCustomerSlideout = () => {
    this.setState({ showCustomerSlideout: false, isSpinner:false, });
  };
  
  closeJobSlideout = () => {
    this.setState({ showJobSlideout: false, isSpinner:false, });
  };

  closeSlideOut2 = () => {
    this.setState({ showjobrModal: false, isSpinner:false, });
  };





  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {
    this.onLoadData();
    this.GetorderEntryColoumn();
  }


  GetorderEntryColoumn = () => {
    let request: any = {};
    request.orderid = this.props.vendororderid;
    request.tenantid = 0;
    request.vendorordertype = this.state.vendorordertype;
    request.jobid = this.props.jobid;
    
    let orderData: any = [];
    VendorService.GetVendorReceivingData(request)
      .then(async (result: any | null) => {
        if (result !== null) { 
          if (result.vendorOrderToUpdateList.length > 0) {

            let custommerSelected: any = [];            
            let mainArray = result.vendorOrderToUpdateList[0];

            let formData = { ...this.state.formData };
            formData.OrderID = mainArray.orderID;
            formData.CustomerID = mainArray.vendorID;
            formData.CustomerName = mainArray.vendorName;
            formData.customercode = mainArray.vendorcode;
            formData.CustomerPoNumber = mainArray.vendorPoNumber;
            formData.ship_via = mainArray.ship_via;
            formData.OrderDate = mainArray.orderDate;
            formData.Address = mainArray.address;
            custommerSelected.company_name = mainArray.vendorName;
            custommerSelected.address = mainArray.address;
            custommerSelected.customer_id = mainArray.vendorID;
            custommerSelected.customercode = mainArray.vendorcode;
           this.customerSearch?.current.handleSelectFromParent(
              custommerSelected
            );
           
            this.setState(
              {
                ship_via: formData.ship_via,
                orderData: orderData,
                formData: formData,
                productid: 0,
                isSpinner: false,
              },
              () => {
                
                 }
            );
          }
          
          let jobdetails: any = [];
          if (result.vendor3PartyOrderDetailList.length > 0){
            jobdetails = result.vendor3PartyOrderDetailList;
          }

          this.setState({ jobOrderlistByItem: result.vendorOrderDetailsToUpdateList,isStateChanged:1,jobdetailsdata: jobdetails });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  };


  onLoadData() {
    this.setState({ isjobcreate: false });
    let pros = this.props;
    let requestlist = { ...this.state.requestlist };
     if (this.customerSearch.current !== null) {
      this.customerSearch.current.GetCustomerTranlist(requestlist);
    }
  }

  updateCustomerList = (result: any) => {
    this.setState({ customerList: [] }, () => {
      this.setState({ customerList: result });
    });
  };

  handleChange = (event: any, FieldType: string,index: any) => {
    let isStateChanged = true;
    let start = 0;
    let end = 0;

    let formData = { ...this.state.formData };
    let jobdetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );


   
     if (FieldType === "orderDate") {
      //   formData.OrderDate = event.target.value.trim().length > 0 ? event.target.value : "";
      let today = new Date(event);
      let dateNew =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear().toString().substring(2, 4);
      let todaynew = new Date(dateNew);
      let dateNewt =
        todaynew.getMonth() +
        1 +
        "/" +
        todaynew.getDate() +
        "/" +
        todaynew.getFullYear().toString().substring(0, 4);
      formData.OrderDate = dateNewt;
      this.setState({ formData, isStateChanged });
    } else if (FieldType === "check") {
      if (event.target.value.length > 0) {
        if (Number(event.target.value)) {
          formData.checkNo = Number(event.target.value);
        }
      } else {
        formData.checkNo = event.target.value;
      }
    } else if (FieldType === "repeat") {
      formData.isRepeat = event.target.checked ? "Yes" : "No";
      this.setState({ isRepeat: event.target.checked });
    } else if(FieldType === "ship_via"){
      formData.ship_via = event.target.value;
      this.setState({ ship_via: event.target.value });
    } else if(FieldType === "orderedqty"){
     
      event.preventDefault();
      let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      jobdetails[index].qtyOrdered = event.target.value;

      this.setState({ jobdetailsdata:jobdetails, isStateChanged }, () => {
        //$("#tblOrder")
        //  .find("input[id^='" + txtID + "']")
        //  .focus();
    
      
      });
    } else if(FieldType === "unitprice"){
      
      event.preventDefault();
      let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      jobdetails[index].unitPrice = event.target.value;

      this.setState({ jobdetailsdata:jobdetails, isStateChanged }, () => {
        //$("#tblOrder")
        //  .find("input[id^='" + txtID + "']")
        //  .focus();
    
      
      });
    }
    

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {
      if (FieldType === "invoiceno") {
        let txtID = "txtInvoice";
        let txtInvoice = $("input[id^='" + txtID + "']")[0] as any;
        txtInvoice.selectionStart = start;
        txtInvoice.selectionEnd = end;
      }
    });
  };

  handleDatePicker = (event: any, refName: any) => {
    refName.current.setOpen(true);
  };

  handleVendorChange = (e: any) => {
       
    let isStateChanged = true;
    if (e.length > 0) {
      let requestlist = { ...this.state.requestlist };

      let formData = { ...this.state.formData };
      requestlist.vid = e[0].vendor_id;
      formData.CustomerID = e[0].vendor_id;
      formData.CustomerName = e[0].company_name;
      formData.address = e[0].address;
      formData.customercode = e[0].vendorcode;
      this.setState({
        CustomerName: e[0].company_name,
        address: e[0].address,
        customercode: e[0].vendorcode,
      });
      this.state.formData.CustomerID = e[0].vendor_id;
      this.state.formData.CustomerName = e[0].company_name;

      this.state.formData.Address =
        e[0].firstname +
        "\n" +
        e[0].address +
        "\n" +
        e[0].apartment +
        "\n" +
        e[0].city +
        ", " +
        e[0].state +
        ", " +
        e[0].zipcode +
        "\n" +
        e[0].country;
      this.state.formData.customercode = e[0].vendorcode;

      //this.GetProductlist(requestlist);

      //this.GetorderEntryColoumn();

      let txtID = "txtOrderDate";
      setTimeout(() => {
        this.trDate.current.setOpen(true);
        $("input[id^='" + txtID + "']").focus();
      }, 50);
    } else {
    }
  };



  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

  onTAB = (control, rowIndex1, event) => {
    let controlID = control.split("-")[0];
    let rowIndex = Number(rowIndex1);

    if (event.key.toLowerCase() === "enter") {
      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
    }

    if (event.key.toLowerCase() === "tab") {
      let ChecktxtID = "partname" + rowIndex + "ddl";

      if (controlID === ChecktxtID) {
        let txtID = "txtpartno" + rowIndex + "txt";

        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
      }

      if (controlID === "txtduedate") {
        let txtID = "txtdescription" + rowIndex + "txt";

        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
      }

      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
    }

    if (event.key.toLowerCase() === "backspace") {
      let controlID = control.split("-")[0];
      let rowIndex = Number(rowIndex1);
      let orderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.orderData))
      );
      let defaultPartname: any = [];
      let productList = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.productList))
      );

      orderData[rowIndex].partname = event.target.value;
      orderData[rowIndex].productid = 0;
      orderData[rowIndex].partNo = "";
      orderData[rowIndex].unitPrice = 0;
      orderData[rowIndex].unit = "";
      orderData[rowIndex].jobDesc = "";

      let objPartname: any = {};
      objPartname.partname = event.target.value;
      objPartname.label = event.target.value;
      objPartname.id = 0;
      objPartname.partno = "";

      objPartname.tenantid = 0;
      objPartname.unit = "";
      objPartname.unitPrice = 0;
      objPartname.noofday = null;
      objPartname.jobDesc = "";
      defaultPartname.push(objPartname);
      productList.push(objPartname);

      //element.defaultPartname = defaultPartname;
      orderData[rowIndex].defaultPartname = defaultPartname;

      this.setState({ orderData, defaultPartname, productList }, () => {
        let txtID =
          "rbt-input-main form-control rbt-input partname" + rowIndex + "ddl";
        $("#tblOrder")
          .find("input[class^='" + txtID + "']")
          .focus();
      });

      let txtID =
        "rbt-input-main form-control rbt-input partname" + rowIndex + "ddl";

      let tvalue =
        event.target.value.trim().length > 0 ? event.target.value : "";
      if (tvalue === "") {
        setTimeout(() => {
          $("input[class^='" + txtID + "']").focus();
        }, 50);
      }
    }
  };

  HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


  Hidespinner() {
    this.setState({ isSpinner: false });
  }

  saveOrderfinal = (entryType, isCopy) => {
    let isFormValid = true;
    let request: any = {};
    let orderlistitem: any = [];
    let jobOrderList =this.state.jobOrderlistByItem;
    jobOrderList.forEach(element => {

      let item: any = {};
      item.id = element.id;
      item.received = element.received;
      item.orderID = element.orderId;
      item.itemNo = element.itemId;
      item.itemname =  element.itemName;
      item.glcode = element.glCode;
      item.dueDate = element.dueDate === "" || element.dueDate === undefined || element.dueDate === null ? new Date() : element.dueDate;
      item.jobDesc = element.jobDesc;
      item.unit = element.unit;
      item.unitPrice = element.unitPrice;
      item.qtyOrdered = element.qty;
      item.discount = element.discount;
      item.total = element.total;
      item.tenantid = element.tenantid;
      item.JobId = element.jobId;

      if (element.productid == 0 || element.productid == null ||element.productid == undefined) {
        item.productid = 0;
      }
      else {
        if (element.productid[0] == undefined) {
          item.productid = 0;

        }
        else {
          item.productid = element.productid[0].id === undefined ? element.productid : element.productid[0].id;
        }
      }
      orderlistitem.push(item);
    
    });
    this.state.formData.OrderItems = orderlistitem;

    if(this.state.vendorordertype==='Process')
    {
      let saveItems:any = [];
      let jobdetailsList =this.state.jobdetailsdata;
      jobdetailsList.forEach(element => {
        let item: any = {};
        
        item.orderid = element.orderid;
        item.jobid = element.jobid;
        item.processid =  element.processid;
        item.processname = element.processname;
        item.type = element.type;
        item.jdescription = element.jdescription;
        item.tenantid = element.tenantid; 
        item.qtyOrdered = element.qtyOrdered;
        item.unitPrice = element.unitPrice; 
        item.id = element.id;
        item.received = element.received;
        saveItems.push(item);
        
      });
      this.state.formData.Vendor3PartyOrderItems = saveItems;

    }
    if(this.state.vendorordertype==='Process')
    {
      if(this.state.formData.Vendor3PartyOrderItems.length == 0)
      {
         alert('Please select at least one item to save');
         return;
      }
    }
    else
    {
      if(this.state.formData.OrderItems.length == 0)
      {
         alert('Please select at least one item to save');
         return;
      }
    }
    this.setState({ ispopupshow: false, isSpinner: true });
     this.state.formData.VendorOrderType = this.state.vendorordertype;
    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));



    if (request.customer_id === 0 && !isCopy) {
      toast.error("Please select Vendor.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      // this.setState({ isSaveSpinner: false });
      return false;
    }


    isFormValid = true;


    if (isFormValid) {
      this.setState({ saveRequest: request, ispopupshow: false });

      VendorService.SaveReceivingData(request)
        .then(async (result: any | null) => {
          if (result !== null) { 

              toast.success("Order has been updated.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
              this.closeSlideOut();
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };


  saveOrder = (entryType, isCopy) => {
      this.saveOrderfinal("order", false);
  };

  
 
 

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      //this.setState({ showSlideout: true ,orderid:row.orderID
      // });
    },
  };

  handleOnSelect = (row, isSelect) => {    

    let jobOrderlistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
    );

    let listItem = jobOrderlistByItem.filter(
      (x) => x.rowId === row.rowId
    );

    listItem[0].selected = isSelect;

this.setState({ jobOrderlistByItem: jobOrderlistByItem });


  };

  handleOnSelectAll = (isSelect, rows) => {
    
    const ids = rows.map(r => r.rowId);

    let jobOrderlistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
    );

    let listItems = jobOrderlistByItem.filter(
      (x) => ids.includes(x.rowId)
    );

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

   this.setState({ jobOrderlistByItem: jobOrderlistByItem });
  };

  handleOnSelectForJob = (row, isSelect) => {  
    let jobdetailsItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );

    let listItem = jobdetailsItem.filter(
      (x) => x.jobid === row.jobid
    );

    listItem[0].selected = isSelect;

this.setState({ jobdetailsdata: jobdetailsItem });


  };

  handleOnSelectAllForJob = (isSelect, rows) => {
    
    const ids = rows.map(r => r.jobid);

    let jobdetailsItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );

    let listItems = jobdetailsItem.filter(
      (x) => ids.includes(x.jobid)
    );

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

   this.setState({ jobdetailsdata: jobdetailsItem });
  };

  showSlideOut = (row,type) => {  
    if(type === 'customer'){
      this.setState({ showCustomerSlideout: true ,showJobSlideout: false,orderid: row.customerOrderId ? row.customerOrderId: row.orderId});
    }
    else if(type === 'job')
    {
      this.setState({ showJobSlideout: true,showCustomerSlideout: false,orderdetailid: row.customerOrderDetailId ? row.customerOrderDetailId:row.orderDetailId,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,CustomerPoNumber:row.customerPoNumber,itemNo:row.itemId});
    }
  };
  

  
   
  render() {
    const {
      
  } = this.state;
   
  
    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row,rowIndex),
      showExpandColumn: true,
      expandByColumnOnly: true,
      style:{ overflow: 'inherit' },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
       
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow,rowIndex) => {
   
      let splitDetail: any = [];  
      splitDetail = this.state.jobOrderlistByItem.filter(
        (x) => x.itemId === mainrow.itemId && x.rowType === 2 
      );


      const columnCOAChild = [
        
        {
          dataField: "partName",
          text: "Part Name",

          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
             
                <EllipsisWithTooltip placement="bottom">
                  {row.partName}
                </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "partNo",
          text: "Part No",

          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.partNo}
              </EllipsisWithTooltip>
            );
          },
        },
        
        {
          dataField: "JobNo",
          text: "Job No",

          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
               <EllipsisWithTooltip placement="bottom">
               <Button className="btn-link" onClick={() => this.showSlideOut(row,'job')}>
               {row.jobNo}
               </Button>
               </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "customerPoNumber",
          text: "CO #",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
              <Button className="btn-link" onClick={() => this.showSlideOut(row,'customer')}>
              {row.customerPoNumber}
              </Button>
            
           </EllipsisWithTooltip>
              
            );
          },
        },
        {
          dataField: "qty",
          text: "Qty",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.qty}
              </EllipsisWithTooltip>
            );
          },
        },
        
        {
          dataField: "unit",
          text: "Unit",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.unit}
              </EllipsisWithTooltip>
            );
          },
        },
        
        {
          dataField: "unitPrice",
          text: "Unit Price",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.unitPrice}
              </EllipsisWithTooltip>
            );
          },
        },
        
        {
          dataField: "jobId",
          text: "JobId",
          hidden: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.jobId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "ItemId",
          text: "ItemId",
          hidden: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.itemId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "ItemId",
          text: "ItemId",
          hidden: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.itemId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: 'received',
          text: 'Receive Status',
          headerStyle: { width: '100px' },
          style: { overflow: 'inherit' },
          hidden: false,
          editable: false,
          headerAttrs: (column, colIndex) => (
            { 'tabIndex': '-1' }
          ),
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div onClick={()=>$('#tblOrder td.reset-expansion-style').css('overflow', 'inherit')}>
                
               <SingleDropdownListWithoutSearch
                           // tabIndex={this.state.tabEnable}
                            itemList={this.state.selectOptions}
                            handleSelectedItem={this.handleSelectedItem.bind(this,rowIndex,2,row.id)}                        
                            defaultItem={row.received}
                            defaultText={"Select Filter"}
                            defaultName={row.received}
                            controlID="2"
                            id={"ddlselected2"}
                            onClick={() => this.closeSlideOut()}
                          //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                          // disabled={disable}
                          />
         
              
              </div>
            );
          },
    
        } , 
        {
          dataField: "",
          text: "",
          headerClasses: "blankLastChildofChild",
        },
       
       
      ];

      return (
        <>
        <div className='px-4 p-4'>

          <BootstrapTable
            id="tblCOAchild"
            keyField="rowId"
            data={splitDetail}
            columns={columnCOAChild}
            //rowEvents={this.rowEvents}
            selectRow={ { mode: 'checkbox', clickToSelect: false,  hideSelectAll: false,
             selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
             onSelect: this.handleOnSelect,
             onSelectAll: this.handleOnSelectAll,
             hideSelectColumn: this.state.vendororderid > 0 && !this.state.editMode
           } }
           
          />
          </div>
        </>
      );
    };

  
   
   
    return (

      
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <div className="trasaction-slideout invoice-entry pos-fxd index-1000 "  >
        <ToastContainer
          containerId={"userDetailSlideoutcontainer"}
          autoClose={3000}
        />

        <div className="d-flex">
        <div
              className="back-drop1  viewer-panel1 opacity "
              onClick={() => this.closeSlideOut()}
            ></div>

          <Container
            fluid
            ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-0"

          >
            <div
              className="page-heading underline d-flex top-0"
             
              style={{
                position: "fixed",
                top: 0,
                zIndex: 999,
                backgroundColor: "#fff",
                // backgroundColor: "red",
                padding: "25px",
                width: "65%",
                // borderRadius:"1px",
                borderBottom: "1px solid #fff",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              }}
            >
              {this.state.vendororderid == 0 && <span>Create Vendor Order</span>}
              {this.state.vendororderid != 0 && (
                <span>Vendor Order No : {this.props.CustomerPoNumber}</span>
              )}

              <div className="header-popout ml-auto">


              <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
    {this.state.vendororderid == 0 && <BootstrapSwitchButton width={150} height={40} offstyle="success" 
    checked={this.state.vendorordertype !== 'Process'}
    onlabel='Material'
    offlabel='Process'
    disabled={this.state.vendororderid > 0}
    onChange={(checked: boolean) => {
        this.setState({ vendorordertype: (checked ? 'Material':'Process') });
    }}
/>}
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>

            
              
              </div>
            </div>

            <Container fluid className="p-10">
              <div className="filter-sec" style={{ marginTop: "80px" }}>
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-8">
                        <div>
                          <Form.Group controlId="customerName">
                            <div className="parent my-data">
                              <Form.Label className="font-semibold">
                                Vendor Name
                              </Form.Label>
                              <div
                                tabIndex={this.state.tabEnable}
                                className="type-head"
                              >
                                <VendorSearch
                                  ref={this.customerSearch}
                                  updateCustomerList={this.updateCustomerList}
                                  handleVendorChange={
                                    this.handleVendorChange
                                  }
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div>
                          <Form.Group controlId="vendorName">
                            <div className="parent my-data">
                              <Form.Label className="font-semibold">
                                Order Date
                              </Form.Label>
                              <div className="date-picker calender-picker">
                                {/* <Form.Control type="text" id="txtOrderDate"
                                                             value={this.state.formData.OrderDate}
                                                             onChange={(event: any) => this.handleChange(event, 'orderDate')} 
                                                            autoComplete='off'
                                                            title=""

                                                        /> */}
                                {/* {  <div className="drop-arrow" onClick={(event: any) => this.handleDatePicker(event, this.trDate)}>
                                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                            </svg>
                                                        </div> } */}

                                <DatePicker
                                  ref={this.trDate}
                                  id="txtOrderDate"
                                  tabIndex={this.state.tabEnable}
                                  className="form-control px-0 w-75 text-center"
                                  placeholderText={"MM/DD/YY"}
                                  dateFormat="MM/dd/yy"
                                  selected={
                                    this.state.formData.OrderDate === null
                                      ? new Date()
                                      : this.state.formData.OrderDate === ""
                                        ? new Date()
                                        : new Date(this.state.formData.OrderDate)
                                  }
                                  onChange={(event: any) =>
                                    this.handleChange(event, "orderDate",0)
                                  }
                                  // onKeyDown={(e: any) =>
                                  //   this.onTAB("txtOrderDate-1", 0, e)
                                  // }
                                  onClick={(e: any) =>
                                    this.onTAB("txtOrderDate-1", 0, e)
                                  }
                                  autoComplete="off"
                                // disabled={isBedgeProcessing && true}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">                      
                      <div className="col-8">
                        <div>
                          <Form.Group controlId="customerName">
                            <div className="parent my-data">
                              <Form.Label className="font-semibold">
                                Ship Via
                              </Form.Label>
                              <div
                                tabIndex={this.state.tabEnable}
                                className="type-head"
                              >
                               <Form.Control
              tabIndex={this.state.tabEnable}
              id="txtShipVia"
              type="text"
              placeholder="Enter Ship Via"
              value={this.state.ship_via}
              onChange={(event: any) => this.handleChange(event, 'ship_via',0)} 
              autoComplete="off"

              disabled={false}
              title=""
            />
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>


                    </div>
                    
                  </div>
                 
                </div>
              </div>

             
             
              <div style={{backgroundColor:'white'}}>
                {this.state.transactionsColumns.length > 0 && this.state.vendorordertype!=='Process'  && (
                  <div style={{backgroundColor:'white'}} >
                    <BootstrapTable
                      id="tblOrder"
                      keyField="rowId"
                      data={this.state.jobOrderlistByItem.filter(
                        (x) => x.rowType === 1 && this.state.jobOrderlistByItem.filter(
                          (a) => a.itemId === x.itemId && a.rowType === 2
                        ).length > 0
                      )}
                      columns={this.state.transactionsColumns}
                      expandRow={expandSplitRow}
                     
                     // rowEvents={this.rowEvents1}
                    />
                  </div>
                )}

              </div>

               <div className="transactions-list inpute">
                    {this.state.templateColumns.length > 0 && this.state.vendorordertype==='Process' && (
                      <div style={{backgroundColor:'white'}} className="split-assign-coa">
                        <BootstrapTable
                          id="tbltemplate"
                          keyField="jobid"
                          data={this.state.jobdetailsdata.filter((x) => true)}
                          columns={this.state.templateColumns}
                          selectRow={ { mode: 'checkbox', clickToSelect: false,  hideSelectAll: false,selected: this.state.jobdetailsdata.filter((x) => x.selected === true).map(r => r.jobid),
             //selected: this.state.selected,
             onSelect: this.handleOnSelectForJob,
             onSelectAll: this.handleOnSelectAllForJob,
             hideSelectColumn: this.state.vendororderid > 0 && !this.state.editMode
           } }
                        />
                      </div>
                    )}
                  </div>

            

              {(this.state.orderid != 0 &&
                                        <div className="footer-show" >
                                           
                                            <CommentBox
                                                ref={this.ChildComment}
                                                hotelid={this.state.hotelValue}
                                                entryType={this.props.pageType}
                                                uniqueNo={this.state.formData.actionType === "copy" ? 0 : this.props.pageType === "Invoice" ? this.state.formData.uniqueno : this.state.formData.incompleteUniqueno}
                                                precommentlist={this.state.htmlcomment}
                                                parentScrollMethod={this.scrollToBottom}
                                                updatedCommentItem={this.updatedCommentItem}
                                                commentModalClose={this.CommentModalClose}
                                            
                                            />
                                        </div>
                                        // </div>
                                    )}
              <div className="filter-sec"></div>
              <div className="filter-sec"></div>

              <div className={"footer-section viewer-panel"}>
                <div>
                  <div className="d-flex align-items-center justify-content-end height-72">
                    <div className="d-flex align-items-center">
                            <Button
                              variant="primary"
                              type="button"
                              className="space btn btn-primary btn-discard"
                              onClick={() =>
                                this.handleDiscard(this.state.orderUniqueNo)
                              }
                            >
                              Discard
                            </Button>
                        
                      {this.state.isStateChanged && this.state.formData.CustomerID > 0 && this.state.vendororderid > 0 
                         && (
                          <>
                           
                            <Button
                              id="btnSaveVendor"
                              variant="primary"
                              type="button"
                              onClick={() => this.saveOrder("order", false)}
                              disabled={
                                this.state.formData.CustomerID === 0
                                  ? false
                                  : false
                              }
                              onKeyDown={(e: any) =>
                                this.onTAB("btnSaveVendor-1", 0, e)
                              }
                            >
                              {"Save"}
                            </Button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-sec">
                <div className="d-flex flex-wrap mr-auto"></div>
              </div>
             
            </Container>
            {(this.state.showCustomerSlideout &&
            <Customerorderslideout
            closeSlideOut={this.closeCustomerSlideout}
            orderid={this.state.orderid}
            >
            </Customerorderslideout>
              )}

{this.state.showJobSlideout && (

<JobCreations
  closeSlideOut={this.closeJobSlideout}
  jobid={this.state.jobid}
  orderid={this.state.orderid}
  CustomerPoNumber={this.state.CustomerPoNumber}
  poNumber={this.state.poNumber}
  productid={this.state.productid}
  partname={this.state.partname}
  partNo={this.state.partNo}
  qtyOrdered={this.state.qtyOrdered}

  itemNo={this.state.itemNo}
  selectedCustomer={this.state.selectedCustomer}
  orderdate={this.state.orderdate}
  dueDate={this.state.dueDate}
  jobDesc={this.state.jobDesc}
  id={this.state.orderdetailid}

>
</JobCreations>
)}

</Container>

         

          



          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
