import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  FormControl,
  FormGroup,
} from "react-bootstrap";

import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";

import { ToastContainer, toast } from "react-toastify";
import paginationFactory from "react-bootstrap-table2-paginator";

import { User } from "../../Common/Services/User";
import { RolePremissionSlideout } from "./RolePremissionSlideout";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import createDOMPurify from "dompurify";

import moment from "moment";
import { Roles } from "../../Common/Services/RolePermissionSetting";
import { idText } from "typescript";
const columns = [
  {
    dataField: "roleName",
    text: "Role",
  },
  {
    dataField: "roleName",
    text: "Role",
    hidden: true,    
    //style:{ backgroundColor: '#c8e6c9':'none' },
    formatter: (cell: any, row: any, rowIndex: any) => {
      return (
        
       <div style={{backgroundColor: 'none'}}>
          {row.roleName}
          </div>
      );
    },
  },
];

const pcolumns = [
  {
    dataField: "displayPermissionName",
    text: "Permission Name",
  },
];

const tcolumns = [
  {
    dataField: "displayPermissionName",
    text: "",
  },
];

const ucolumns = [
  {
    dataField: "userName",
    text: "User Name",
  },
  {
    dataField: "roleName",
    text: "Role",
    hidden: true,    
    //style:{ backgroundColor: '#c8e6c9':'none' },
    formatter: (cell: any, row: any, rowIndex: any) => {
      return (
        
       <div style={{backgroundColor: 'none'}}>
          {row.roleName}
          </div>
      );
    },
  },
];


export class RolePermission extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      roleID:0,
      roleName:'',
      permissionData:[],
      users:[],
      formdata: {
        RoleName: "",
        RoleId: "",
        OrderNo: "",
        ResetPwd: "false",
        TenantId: 0,
        RoleTag: "",
        PermissionRoles:[]
      },
      showNewRoleTextbox:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleResetPwd = this.handleResetPwd.bind(this);
  }

  componentDidMount() {
  
    let requestlist = { ...this.state.requestlist };
    this.getroledata(requestlist);
    this.getPermissionData();
    this.getAllUsers();

  }

  
  getAllUsers = () => {

    let TypeUserlist: any = [];

    User.getAllUsers()
      .then(async (result: any | null) => {

        if (result !== null) {
          this.setState({ users: result });

        }
        resolve();

      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });

    
  };

  getroledata = (requestlist: any) => {
    Roles.GetRoleData(requestlist)
      .then(async (result: any[] | null) => {
        console.log(result);
        if (result != null) {
          result.forEach(element => {
            element.selected = (element.roleID == this.state.roleID);
          });
          this.setState({ searcResult: result }, () => {});
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  getPermissionData = () => {
    let requestlist = { ...this.state.requestlist };
    requestlist.roleId = this.state.roleID;
    Roles.GetPermissionData(requestlist)
      .then(async (result: any[] | null) => {
        console.log(result);
        if (result != null) {
          this.setState({ permissionData: result }, () => {});
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  SavePermissionRole = () => {

    let request: any = {};
   let permissionRoles: any = [];
   let permissionData = this.state.permissionData;
   let storage = JSON.parse(localStorage.getItem("storage")!);
   let tenantID = storage === null ? 0 : (storage.tenantID as any);
   this.setState({ isSaveSpinner: true });
   permissionData.forEach(element => {
    let item: any = {};
    if(element.selected || this.state.formdata.RoleName.toLowerCase() == 'administration')
    {
    item.PermissionID = element.permissionId;
    item.RoleID = this.state.roleID;
    item.TenantID =  tenantID;
    permissionRoles.push(item);
  }
  });

  this.state.formdata.RoleId = this.state.roleID;
    this.state.formdata.PermissionRoles = permissionRoles;
    

    if (this.state.formdata.RoleId == 0 && (this.state.formdata.RoleName == '' || this.state.formdata.RoleName == undefined)) {
      toast.success("Please select Role.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      this.setState({ isSaveSpinner: false });
      return;
    }
    request = Object.assign(
      {},
      
      JSON.parse(JSON.stringify(this.state.formdata))
    );
    Roles.SavePermissionRole(request)
      .then(async (result: any | null) => { 
        if (result !== null) {
            toast.success("Role permission has been saved successfully.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          let requestlist = { ...this.state.requestlist };
          this.getroledata(requestlist);
        }
        
        this.setState({ isSaveSpinner: false,showNewRoleTextbox: false,isStateChanged:false });
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSaveSpinner: false });
        reject();
      });
  };

  handleDiscard() {
    this.resetPermissionGrid();
    this.setState({ isStateChanged: false });

  }

  showNewRoleTextbox = () => {
    this.resetPermissionGrid();
    this.setState({ showNewRoleTextbox: true });
  };

  resetPermissionGrid = () => 
  {
    let permissionData = this.state.permissionData;
    permissionData.forEach(element => {
      element.selected = false;
    });
    this.setState({ roleID: 0,roleName:'',permissionData:permissionData });
  };


  closeSlideOut = () => {
    this.setState({ showSlideout: false });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({
        showSlideout: false,
        templateid: row.id,
        roleID:row.roleID,
        roleName:row.roleName
        
      },()=>{
        
      });
    },
  };

  handleChange = (event: any, FieldType: string) => {
    let isStateChanged = true;
    let start = 0;
    let end = 0;

    let formData = { ...this.state.formdata };

    if (FieldType === "RoleName") {
      formData.RoleName =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "OrderNo") {
      formData.OrderNo =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "ResetPwd") {
      /// this.generateUsername;
      formData.ResetPwd =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "RoleTag") {
      formData.RoleTag =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }

    this.setState({ formdata:formData, isStateChanged, isSpinner: false }, () => {
      // if (FieldType === "invoiceno") {
      //   let txtID = "txtInvoice";
      //   let txtInvoice = $("input[id^='" + txtID + "']")[0] as any;
      //   txtInvoice.selectionStart = start;
      //   txtInvoice.selectionEnd = end;
      // }
    });
  };

  handleRoleOnSelect = (row, isSelect) => {  
    let roles = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    roles.forEach(element => {
        if(element.roleID == row.roleID)
        {
          element.selected = isSelect;
        }
        else         
          element.selected = false;
    });

  
    this.setState({ searcResult: roles,roleID:row.roleID,showNewRoleTextbox:false },()=>{
      this.getPermissionData();
    });

  };

  handleOnSelect = (row, isSelect) => {  

    let permissionData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.permissionData))
    );

    
    let listItem :any = [];

    if(row.levelInfo == 2)
    {
      listItem = permissionData.filter(
        (x) => x.permissionId === row.permissionId || x.parentId === row.permissionId 
      );
    }
    else if(row.levelInfo == 3)
    {
      if(isSelect)
      {
      listItem = permissionData.filter(
        (x) => x.permissionId === row.permissionId 
        || x.permissionId  === row.parentId  && row.parentId
      );
     }
     else
     {
      //let siblings = permissionData.filter((x) => x.parentId == row.parentId);
      let selectedSiblings = permissionData.filter((x) => x.parentId == row.parentId && x.selected == true);
     
      listItem = permissionData.filter(
        (x) => x.permissionId === row.permissionId 
        || x.permissionId  === row.parentId  && selectedSiblings.length == 1 && row.permissionId == selectedSiblings[0].permissionId
      );

     }
    }

    //listItem[0].selected = isSelect;
    listItem.forEach(element => {
      element.selected = isSelect;
    });

this.setState({ permissionData: permissionData,isStateChanged:true });


  };

  handleOnSelectAll = (isSelect, rows) => {
    
    const ids = rows.map(r => r.permissionId);
    const pids = rows.map(r => r.parentId);

    let permissionData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.permissionData))
    );

    let listItems:any = [];

    if(rows[0].levelInfo == 2)
    {
      listItems = permissionData.filter(
        (x) => ids.includes(x.permissionId) || ids.includes(x.parentId)
      );
    }
    else if(rows[0].levelInfo == 3)
    {
      listItems = permissionData.filter(
        (x) => ids.includes(x.permissionId) || pids.includes(x.permissionId)
      );
    }

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

   this.setState({ permissionData: permissionData,isStateChanged:true });
  };

  // handlechange=(event:any)=>
  // {

  //   this.setState({  inputvalue: event.target.value });
  // };

  // SaveRole=()=>{
  //   debugger;
  //  const {roleName,inputvalue}=this.state;
  //  if(inputvalue.trim()!=='')
  //  {
  //  // roleName.push(inputvalue);
  //   this.setState({
  //     roleName:[...roleName,inputvalue],
  //     inputvalue:'',

  //   });
  //  };
  // };

  handleResetPwd = (event: any) => {
    const ResetPwd = event.target.checked;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ResetPwd,
      },
    }));
  };

  render() {
    const { showSlideout, roleName, inputvalue } = this.state;

    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row,rowIndex),
      expanded:this.state.permissionData.filter((a) => a.levelInfo === 1).map(r => r.permissionId), 

      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow,rowIndex) => {
   
      let splitDetail: any = [];  
      splitDetail = this.state.permissionData.filter(
        (x) => x.parentId === mainrow.permissionId && x.levelInfo === 2 );


      

      return (
        <>
         {/* <div style={{ paddingLeft: '40px',paddingTop: '30px' }}> */}
         <div className="pt-4 ps-5">
         <BootstrapTable
            id="tblCOAchild"
            keyField="permissionId"
            data={splitDetail}
            columns={tcolumns}
            expandRow={expandSplitRow2}
            //rowEvents={this.rowEvents}
            selectRow={ { mode: 'checkbox', clickToSelect: false,  hideSelectAll: false,
             selected: splitDetail.filter((x) => x.selected === true).map(r => r.permissionId),
             onSelect: this.handleOnSelect,
             onSelectAll: this.handleOnSelectAll,
             hideSelectColumn: false
           } }
           
          />
          </div>
        </>
      );
    };

    const expandSplitRow2 = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild2(row,rowIndex),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const renderItemTableChild2 = (mainrow,rowIndex) => {
   
      let splitDetail2: any = [];  
      splitDetail2 = this.state.permissionData.filter(
        (x) => x.parentId === mainrow.permissionId && x.levelInfo === 3 );


      

      return (
        <>
        {/* <div style={{ paddingLeft: '100px',paddingTop: '30px'}}> */}
        <div className="pt-4 ps-5">

          <BootstrapTable
            id="tblRolePermissionLevel3"
            keyField="permissionId"
            data={splitDetail2}
            columns={tcolumns}
            //rowEvents={this.rowEvents}
            
            selectRow={ { mode: 'checkbox', clickToSelect: false,  hideSelectAll: false,
             selected: splitDetail2.filter((x) => x.selected === true).map(r => r.permissionId),
             onSelect: this.handleOnSelect,
             onSelectAll: this.handleOnSelectAll,
             hideSelectColumn: false
           } }
           
          />
          </div>
        </>
      );
    };

    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
            Roles & Permisions
              <div className="action-group d-flex flex-row ml-auto">
              <Button onClick={this.showNewRoleTextbox}>New Roles</Button>
              </div>
            </div>
            <div className="container my-3">
                <div className="row">
                  <div className="col-3">
                    <BootstrapTable
                      keyField="roleID"
                      data={this.state.searcResult}
                      columns={columns}
                       rowEvents={this.rowEvents}
                       selectRow = {{
                        mode: 'radio',
                        clickToSelect: true,
                        style: { backgroundColor: '#c8e6c9' },
                        hideSelectColumn: true,  
                        onSelect: this.handleRoleOnSelect,
                        //selected: this.state.searcResult.filter((x) => x.roleID === this.state.roleID).map(r => r.roleID),
                        selected: this.state.searcResult.filter((x) => x.selected==true).map(r => r.roleID),
                      }}
                       
                    />
                  </div>
                  <div className="col-6" style={this.state.roleName.toLowerCase() == 'administration' ?{ opacity:0.5, pointerEvents:'none' } :{}}>
                  {this.state.showNewRoleTextbox && <div className="container py-2 border-bottom my-2 ">
                      <Form>
                        <FormGroup>
                          <Form.Control
                            placeholder="Enter RoleName"
                            // value={this.state.formData.RoleName}
                            onChange={(event: any) => {
                              this.handleChange(event, "RoleName");
                            }}
                          ></Form.Control>
                        </FormGroup>
                        </Form>
                  </div>}
                  <BootstrapTable
                      keyField="permissionId"
                      data={this.state.permissionData.filter(
                        (a) => a.levelInfo === 1)}
                      columns={pcolumns}
                      expandRow={expandSplitRow}
                       //rowEvents={this.rowEvents}
                     
                    />
                  
                  

                    <div className="d-flex align-items-center justify-content-end height-20">
          {this.state.isStateChanged && this.state.roleName.toLowerCase() !== 'administration' && (
            <div className="fixed-action">
              <div className="d-flex align-content-center flex-wrap">
                <div className="mr-auto message" style={{ marginLeft: '40px' }}
                >
                  <span>You have unsaved changes</span>
                </div>
                <Button
                                  variant="primary"
                                  type="button"
                                  className="space btn btn-primary btn-discard"
                                  onClick={() => this.handleDiscard()}
                                >
                                  Discard
                                </Button>
                                <Button
                                  id="btnSave"
                                  variant="primary"
                                  type="button"
                                  style={{ marginRight: '40px' }}
                                  onClick={() => this.SavePermissionRole()} 
                                >
                                  {"Save"}
                                </Button>
              </div>
            </div>
          )}
         </div>
                  
                   
                   </div>
                  
                   <div className="col-3">
                    <BootstrapTable
                      keyField="user_UniqueID"
                      data={this.state.users.filter((x)=>x.role == this.state.roleID)}
                      columns={ucolumns}
                      //rowEvents={this.rowEvents}
                    />
                  </div>

                 
                </div>
              </div>

     
              {showSlideout && (
                <RolePremissionSlideout
                  closeSlideOut={this.closeSlideOut}
                  templateid={this.state.templateid}
                ></RolePremissionSlideout>
              )}
            </Container>




            {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}



        </React.Fragment>
      </>
    );
  }
}