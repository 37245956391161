import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button ,Form,Spinner,} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../../Common/Services/VendorService";
import {Utils}  from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {JobCreations}  from '../JobCreations';
import {NCRSlideout}  from './NCRSlideout';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

const { SearchBar } = Search;


//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';


const DOMPurify = createDOMPurify(window);

export class NCR extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOrderDate:true,
      isvendorname:true,
      isvendorOrderType:true,
      isvendorPoNumber:true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],      
      isNCRDate:true,      
      isPartNo:true,
      isPartName:true,
      isCustomerName:true,
      isExplanation:true,
      isJobNo:true,
      selectedcolumn:"All",
      taboption:"first",
      IsAdded:false,
      selectOptions :  [ {
        id: 'All',name: 'All'},
      {  id: 'Part No',name: 'Part No'},
        {id: 'Customer Name',name:  'Customer Name'},
        {id: 'Part Name',name:  'Part Name'},
        { id: 'Job #',name:  'Job No.'},       
        { id: 'Explanation',name:  'Explanation'},       
     ],
    };
    

 
  }
 

  componentDidMount() { 
   this.getJobItems();
  }

  getJobItems()
  {
    let requestlist = { ...this.state.requestlist };
    requestlist.checkForReworkCount = 1;
    JobTrackerServices.GetNCRJobData(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
        this.setState({ searcResult: result}, () => {    
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

  shownewslideout   = () => {
    this.setState({ showSlideout: true ,id:0,jobid:0,jobno:0,isSpinner:false,});
  };


  showslideout = () => {
    this.setState({ showSlideout: true , isSpinner:false, });
  };

  closeSlideOut = () => {
    this.setState({ showJobSlideout: false, isSpinner:false,showSlideout:false,IsAdded:true });
    this.getJobItems();
};

closeSlideOut1 = () => {
  this.setState({ showJobSlideout: false, isSpinner:false,showSlideout:false,IsAdded:false ,taboption:"second"});
  this.getJobItems();
};

 rowEvents = {

  onClick: (e: any, row: any, rowIndex: any) => {
   
    if (e.target.tagName !== 'BUTTON') 
    {     
     
     this.setState({ showSlideout: true ,id:row.id,jobid:row.jobId,jobno:row.jobNo, isSpinner:false,}); 
    } 
  
  },
};

handleSelectedItem1 = (control: any, id: any) => {
  let searcResult1 = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResult))
  );

  let isPartNo = id == "Part No" ? true : false;
  let isCustomerName = id == "Customer Name" ? true : false;
  let isPartName = id == "Part Name" ? true : false;
  let isJobNo = id == "Job #" ? true : false;
  let isNCRDate = id == "NCR Date" ? true : false;
  let isExplanation = id == "Explanation" ? true : false;

  if (id == "All") {  
    isNCRDate=true;      
    isPartNo=true;
    isPartName=true;
    isCustomerName=true;
    isExplanation=true;
    isJobNo=true;
  }


  this.setState({
    searcResult: [], selectedcolumn: id, isNCRDate: isNCRDate, isPartNo: isPartNo,
    isPartName: isPartName, isjobNo: isJobNo,isCustomerName:isCustomerName,isExplanation:isExplanation
  },
    () => {
      this.setState({ searcResult: searcResult1 });
    }

  );

};

handleSelectedItem = (rowIndex: any,control: any,rowid:any, value: any) => {
  let searcResultReceiving = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResultReceiving))
  );

 
 
    let selectedRow = searcResultReceiving.filter((x)=> x.rowId == rowid);
    if(selectedRow.length > 0)
       selectedRow[0].received = value;
if(value=="Partial")
  {
    selectedRow[0].qtyReceived = 0;
  }
  this.setState({
    searcResultReceiving: searcResultReceiving,isStateChanged: true
  },
    () => {
      this.setState({ searcResultReceiving: searcResultReceiving});
    }
  );
};





 
reopenSlideOutWithReworkJob = () => {
  this.setState({ showJobSlideout: false}, () => {
   
   let rNo = Utils.extractIncrementedRNumber(this.state.jobNo);
   this.setState({ showJobSlideout: true,showCustomerSlideout: false, jobid: 0,rNo:rNo});
  });
  

};

showJobSlideOut = (row,type) => {  

  if(type === 'job')
  {
   
    let jobNo =  Utils.removeRNumber(row.jobNo);
    let itemNo = Utils.extractNumberAfterHyphen(jobNo);
    let rNo = Utils.extractRNumber(row.jobNo);
    this.setState({ showJobSlideout: true,showCustomerSlideout: false, orderid:row.orderDetailId ,jobid: row.jobId,qtyOrdered:row.qty,partname:row.partName,partNo:row.partNo,selectedCustomer:row.customerName,productid:row.partId,CustomerPoNumber:row.customerPoNumber,itemNo:itemNo,jobNo:row.jobNo,rNo:rNo});
  }
   
};

printOrder = (ncrid,jobId) => {
  let request: any = {};
  request.ncrId = ncrid;
  request.jobId = jobId;

  this.setState({ isSpinner: true });

  JobTrackerServices.DownloadNCRFile(request)
    .then(async (result: any | null) => {
      //this.HidecloseSlideOut();
      this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
      resolve();
    })
    .catch((error) => {
      reject();
      this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
    });
};
// handleSelect = (key) => {
//   console.log(key,';;',typeof key);
//   if(key === '1') {
//       console.log('ll', key);
//   } else if(key === '2') {
//     console.log('ll', key);
//   } else if(key === '3') {
//     console.log('ll', key);
//   }
// };

handleSelect = (key: string | null) => {
  this.setState({ taboption: key || "first" });
};


   render() { 
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
  
      
      {
        dataField: 'ncrNo',
        text: 'NCR No',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isNCRNo,
        hidden:false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.ncrNo}
             </EllipsisWithTooltip>
          );
        },
      }, 
      
      {
        dataField: 'ncrDate',
        text: 'NCR Date',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isNCRDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
              <EllipsisWithTooltip placement="bottom">
                  {row.ncrDate != null && row.ncrDate.length > 0 ? moment(row.ncrDate).format("MM/DD/YYYY"):''}
              </EllipsisWithTooltip>
          );
      },
      },
      {
        dataField: 'typeOfNCR',
        text: 'NCR Type',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.typeOfNCR}
             </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'jobNo',
        text: 'Job No.',
        sort: true,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             <Button className="btn-link" onClick={() => this.showJobSlideOut(row,'job')}>
             {row.jobNo}
             </Button>
             </EllipsisWithTooltip>
          );
        },
        searchable:this.state.isJobNo,
      },
      {
        dataField: 'partNo',
        text: 'Part No',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isPartNo,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.partNo}
             </EllipsisWithTooltip>
          );
        },
      }, 
      {
        dataField: 'partName',
        text: 'Part Name',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isPartName,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.partName}
             </EllipsisWithTooltip>
          );
        },
      },     
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        hidden:true,
        headerStyle: { width: '150px' },
        searchable:this.state.isCustomerName,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.customerName}
             </EllipsisWithTooltip>
          );
        },
      },
    
      
      {
        dataField: 'ncrStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.ncrStatus}
             </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'TrackerStatus',
        text: 'Recomended Action',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.trackerStatus}
             </EllipsisWithTooltip>
          );
        },
      },
      // {
      //   dataField: '',
      //   text: 'Print',
      //   sort: true,
      //   hidden: false,
      //   headerStyle: { width: '150px' },
      //  // searchable:this.state.isExplanation,
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <Button variant="primary"   className="btn btn-primary px-4" onClick={()=>this.printOrder(row.id,row.jobId)}> Print</Button>
      //     );
      //   },
      // },

      // {
      //   dataField: '',
      //   text: 'Action',


      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //       <div className="btn-group">
      //         <Dropdown
      //           onClick={(e) => { e.stopPropagation(); }}
      //           className="more-action"
      //           >
      //           <Dropdown.Toggle
      //             className="btn-outline-primary btn btn-primary-white more"
      //             id={"dropdown-more" + rowIndex}

      //           >
      //             <svg
      //               width="20"
      //               height="20"
      //               viewBox="0 0 20 20"
      //               fill="none"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
      //               <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
      //               <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
      //             </svg>
      //           </Dropdown.Toggle>
      //           <Dropdown.Menu>
      //           <Dropdown.Item eventKey={"print"} onClick={()=>this.printOrder(row.id,row.jobId)}>Print </Dropdown.Item>
                  
                  
      //           </Dropdown.Menu>
      //         </Dropdown>
      //       </div>
      //     );
      //   },
      // },


    ];

    const columnsReported = [
     
      // {
      //   dataField: 'ncrDate',
      //   text: 'NCR Date',
      //   sort: true,
      //   headerStyle: { width: '100px' },
      //   searchable:this.state.isNCRDate,
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //         <EllipsisWithTooltip placement="bottom">
      //             {row.ncrDate != null && row.ncrDate.length > 0 ? moment(row.ncrDate).format("MM/DD/YYYY"):''}
      //         </EllipsisWithTooltip>
      //     );
      // },
      //},
      {
        dataField: 'jobNo',
        text: 'Job No.',
        sort: true,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             <Button className="btn-link" onClick={() => this.showJobSlideOut(row,'job')}>
             {row.jobNo}
             </Button>
             </EllipsisWithTooltip>
          );
        },
        searchable:this.state.isJobNo,
      },
      {
        dataField: 'partNo',
        text: 'Part No',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isPartNo,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.partNo}
             </EllipsisWithTooltip>
          );
        },
      }, 
      {
        dataField: 'partName',
        text: 'Part Name',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isPartName,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.partName}
             </EllipsisWithTooltip>
          );
        },
      },     
      {
        dataField: 'customerName',
        text: 'Customer Name',
        sort: true,
        headerStyle: { width: '150px' },
        searchable:this.state.isCustomerName,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.customerName}
             </EllipsisWithTooltip>
          );
        },
      },
    
      {
        dataField: 'isFixed',
        text: 'Type',
        sort: true,
        headerStyle: { width: '100px' },
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
             <EllipsisWithTooltip placement="bottom">
             {row.isFixed ? "Process":"Material"}
             </EllipsisWithTooltip>
          );
        },
      },
      // {
      //   dataField: 'TrackerStatus',
      //   text: 'Recomended Action',
      //   sort: true,
      //   headerStyle: { width: '100px' },
      //   //searchable:this.state.isExplanation,
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //        <EllipsisWithTooltip placement="bottom">
      //        {row.trackerStatus}
      //        </EllipsisWithTooltip>
      //     );
      //   },
      // },
      // {
      //   dataField: 'explanation',
      //   text: 'Explanation',
      //   sort: true,
      //   headerStyle: { width: '150px' },
      //   searchable:this.state.isExplanation,
      //   formatter: (cell: any, row: any, rowIndex: any) => {
      //     return (
      //        <EllipsisWithTooltip placement="bottom">
      //        {row.explanation}
      //        </EllipsisWithTooltip>
      //     );
      //   },
      // },
    ];

  

 

    const {
      
      showSlideout,
  } = this.state;

 
  

 

 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

              Non-Compliance Report
              <div className="action-group d-flex flex-row ml-auto">
              <Button onClick={this.shownewslideout}>Add NCR</Button>

              </div>
            </div>

            <Tab.Container transition={false} activeKey={this.state.taboption}  onSelect={this.handleSelect}>

              <section className="pb-3">
                <Nav className="nav nav-underline border-bottom">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="first">Reported <span className="badge badge-light">{this.state.searcResult.filter((x)=>(x.ncrNo == null || x.ncrNo.length == 0)).length}</span>


                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="second">Generated
                    <span className="badge badge-light">   {this.state.searcResult.filter((x)=>x.ncrNo != null && x.ncrNo.length > 0).length}</span>

                    </Nav.Link>
                  </Nav.Item>

                </Nav>
              </section>

              <Tab.Content>
                <Tab.Pane eventKey="first">

                  <ToolkitProvider
                    keyField="id"
                    data={ this.state.searcResult.filter((x)=>(x.ncrNo == null || x.ncrNo.length == 0)) }
                    columns={ columnsReported }
                   
                     
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  {...props.baseProps}
                                  rowEvents={this.rowEvents}
                                    pagination={paginationFactory(options)}

                                />
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>


                </Tab.Pane>
                <Tab.Pane eventKey="second">

                  <ToolkitProvider
                    keyField="id"

                    data={ this.state.searcResult.filter((x)=>x.ncrNo !== null && x.ncrNo.length > 0) }
                    columns={ columns }
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                              <BootstrapTable id ="tblGenerated"
          { ...props.baseProps }
          keyField="rowId"
          rowEvents={this.rowEvents}
        pagination={ paginationFactory(options) }
        defaultSorted={[
          {
            dataField: 'ncrDate',
            order: 'desc' 
          }
        ]}
        />
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>


                </Tab.Pane>

              </Tab.Content>

            </Tab.Container>




            {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

{this.state.showSlideout && (

<NCRSlideout
  closeSlideOut={this.closeSlideOut1}
  jobid={this.state.jobid}
  id={this.state.id}
  jobno={this.state.jobno}

>
</NCRSlideout>
)}

{this.state.showJobSlideout && (

<JobCreations
  closeSlideOut={this.closeSlideOut}
  reopenSlideOutWithReworkJob = {this.reopenSlideOutWithReworkJob}
  jobid={this.state.jobid}
  orderid={this.state.orderid}
  CustomerPoNumber={this.state.CustomerPoNumber}
  poNumber={this.state.poNumber}
  productid={this.state.productid}
  partname={this.state.partname}
  partNo={this.state.partNo}
  qtyOrdered={this.state.qtyOrdered}

  itemNo={this.state.itemNo}
  selectedCustomer={this.state.selectedCustomer}
  orderdate={this.state.orderdate}
  dueDate={this.state.dueDate}
  jobDesc={this.state.jobDesc}
  id={this.state.orderid}
  rNo={this.state.rNo}

>
</JobCreations>
)}

          </Container>



        </React.Fragment>
      </>
    );
  }
}