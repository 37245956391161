import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { ISingleSearchDropdownListProps } from "../Contracts/ISingleSearchDropdownListProps";
export class SingleSearchDropdownListBootrap extends React.Component<ISingleSearchDropdownListProps, any> {
  private inputElement: any;
  private listDiv: any;
  private toggleRef: any;
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.listDiv = React.createRef();
    this.toggleRef = React.createRef();
    
    this.state = {
      
      defaultSelectedName: props.defaultName,
      filterData: this.props.itemList,
      itemList: this.props.itemList,
      inputValue: "",
      searchText: props.defaultText,
      controlID: props.controlID,
      isdisable: this.props?.isdisable || false,

      pageType: props.pageType,
      isStatusbadge: props.isStatusbadge,
      status: "",
      


    };
    this.handleChange = this.handleChange.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  handleChange(e: any) {
    const updatedList = this.state.itemList.filter((item: any) => {
      return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
    });
    this.setState({ filterData: updatedList, inputValue: e.target.value });
  }


  refhandleDropdownChange = (selectedOprID) => {
    let filterList = this.state.itemList.filter(item => item.id === Number(selectedOprID));
    if (filterList.length > 0) {
      this.handleDropdownChange(filterList[0]);
    }
  };


  handleDropdownChange(e: any) {
    // 
    debugger;
    let { pageType } = this.state;
    this.setState({ inputValue: "", filterData: this.state.itemList, status: e.status });

    if (this.props.pageType === "TransferEhid" && typeof   this.props["transferControl"] ==="function") {
      this.props.transferControl(e, "TransferEhid");
    }
    else if (pageType === "headerEhid" && typeof   this.props["transferControl"] ==="function") {
      this.props.transferControl(e, "headerEhid");
    }
    else if (pageType === "Register" && typeof   this.props["handleSelectedItem"] ==="function") {
      this.props.handleSelectedItem(e, this.state.controlID);
    }
    else {
      if (typeof   this.props["handleSelectedItem"] ==="function") {
      this.props.handleSelectedItem(e.id, this.state.controlID);
      }
    }
  }

  resetSerchBox = () => {
    this.setState({ inputValue: "", filterData: this.state.itemList });
  };

  resetddlItem = (key: any, isdisable: any) => {
    this.setState({ inputValue: "", defaultSelectedName: key, isdisable: isdisable });
  };


  componentDidUpdate(prevProps) {
    if (prevProps?.isRefresh !== this.props?.isRefresh) {
      this.setState({ inputValue: "", defaultSelectedName: this.props.defaultName });
    }

  }



  render() {
    const hidSelectModal = (eventKey: any) => {
      this.setState({ inputValue: "", defaultSelectedName: eventKey });
    };

    const onFocusInput = (eventKey: any) => {
      setTimeout(() => {
        if (this.inputElement.current !== null) {
          this.setState({ inputValue: "", filterData: this.state.itemList });
          this.inputElement.current.focus();
        }
      }, 200);
    };

    const onTabKeyDown = (e) => {
      e.preventDefault();
      if (this.toggleRef.current.ariaExpanded === "false" && e?.keyCode === 9) {
        this.toggleRef.current.click();
        setTimeout(() => {
          $(`#ddlSpecailSearchText`)?.focus();
        }, 100);
      }
    };



    return (
      <>
        <OutsideClickHandler
          key="outsideclick"
          onOutsideClick={this.resetSerchBox}
        >
          <Dropdown
            tabIndex={0}
            className="more-action bg-blue single-select-imageboot"
            onSelect={hidSelectModal}
            onClick={onFocusInput}
            onKeyUp={(e) => onTabKeyDown(e)}


          >
            <Dropdown.Toggle ref={this.toggleRef} id="Modal-hid" className="filter-btn" disabled={this.state?.isdisable || this.props?.isFromRepeating}>
              {this.state.defaultSelectedName === this.props.defaultName && (
                <div className="d-flex align-items-center">
                  <div className="txt-area">
                    <EllipsisWithTooltip placement="bottom">
                      {this.state.defaultSelectedName} {(this.state.isStatusbadge && this.state.status?.toString().toLowerCase() === "inactive") && (<span className="badge orange-bg">Inactive</span>)}
                    </EllipsisWithTooltip>

                  </div>
                  <div className="drop-arrow ml-0 d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              )}
              {this.state.defaultSelectedName !== this.props.defaultName && (
                <div className="d-flex align-items-center">
                  <div className="txt-area">
                    <EllipsisWithTooltip placement="bottom">
                      {this.state.defaultSelectedName} {(this.state.isStatusbadge && this.state.status?.toString().toLowerCase() === "inactive") && (<span className="badge orange-bg">Inactive</span>)}
                    </EllipsisWithTooltip>
                  </div>
                  <div className="drop-arrow ml-0 d-flex">
                    <FiChevronDown />
                  </div>
                </div>
              )}

              {this.props.calHomeIcon && (
                <div className="cal-icon housekeeping-standalone">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M1.3689 6.31577V9.19367H2.05895V13.5087H1.3689V15.6666H2.05895H4.12913H5.50924H7.57942H8.95953H11.0297H12.4098L14.48 15.6673V15.6666H15.1701V13.5087H14.48V9.19367H15.1701V6.31577L8.26947 2L1.3689 6.31577ZM4.12913 13.5087V9.19367H5.50924V13.5087H4.12913ZM7.57942 13.5087V9.19367H8.95953V13.5087H7.57942ZM12.4098 13.5087H11.0297V9.19367H12.4098V13.5087ZM9.64959 6.31577C9.64959 7.10987 9.0313 7.75436 8.26947 7.75436C7.50765 7.75436 6.88936 7.10987 6.88936 6.31577C6.88936 5.52167 7.50765 4.87718 8.26947 4.87718C9.0313 4.87718 9.64959 5.52167 9.64959 6.31577Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="15.4059" height="16" fill="white" transform="translate(0.406006 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>

                </div>
              )}
              {this.props.calIcon && (
                <div className="cal-icon housekeeping-standalone">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.66675 7.3335H6.00008V8.66683H4.66675V7.3335ZM4.66675 10.0002H6.00008V11.3335H4.66675V10.0002ZM7.33341 7.3335H8.66675V8.66683H7.33341V7.3335ZM7.33341 10.0002H8.66675V11.3335H7.33341V10.0002ZM10.0001 7.3335H11.3334V8.66683H10.0001V7.3335ZM10.0001 10.0002H11.3334V11.3335H10.0001V10.0002Z" />
                    <path d="M3.33333 14.6668H12.6667C13.402 14.6668 14 14.0688 14 13.3335V5.3335V4.00016C14 3.26483 13.402 2.66683 12.6667 2.66683H11.3333V1.3335H10V2.66683H6V1.3335H4.66667V2.66683H3.33333C2.598 2.66683 2 3.26483 2 4.00016V5.3335V13.3335C2 14.0688 2.598 14.6668 3.33333 14.6668ZM12.6667 5.3335L12.6673 13.3335H3.33333V5.3335H12.6667Z" />
                  </svg>
                </div>
              )}


            </Dropdown.Toggle>
            <Dropdown.Menu>
              {(this.props?.allowSearch || this.props?.allowSearch === undefined)   &&
              <div className="search-sec">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  placeholder={this.state.searchText}
                  value={this.state.inputValue}
                  ref={this.inputElement}
                  autoFocus={true}
                />
              
              </div>
  }
              <div className="list-area" ref={this.listDiv}>
                {this.state.filterData.map((item: any, idx: any) => {
                  let isStatusDevider = item.status === "isDevider";
                  return (
                    <>
                     
                      {!isStatusDevider && (<Dropdown.Item
                        disabled={item.disabled}
                        eventKey={item.name}
                        onClick={() => this.handleDropdownChange(item)}
                        key={idx}
                      >
                        <div className="d-flex">
                          <div className={(this.state.isStatusbadge && item.status?.toString().toLowerCase() === "inactive") ? "mr-auto item inactive" : "mr-auto item"}>
                            <EllipsisWithTooltip placement="bottom">{item.name}</EllipsisWithTooltip>
                          </div>
                          
                          {item.isWorkshop == 1 && <div className={"badge color-blue"}>Workstation</div>}
                        </div>
                      </Dropdown.Item>)}
                      {(this.state.filterData.length > 0 && this.state.filterData[idx - 1]?.orderno === 1 && item?.orderno === 2) && (
                        <hr />
                      )}
                     
                    </>
                  );
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </OutsideClickHandler>
      </>
    );
  }
}

