import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class BankService {

public static GetBankMasterById = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Bank/GetBankMasterById`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static SaveBankMaster = async (request: any): Promise<any[] | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

  request.TenantId = tenantID;
  const url =`/Bank/SaveBankMaster`;
  return Instense.post(url,request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static GetBanks = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Bank/GetBanks`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

}