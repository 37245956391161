import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedLayout } from "./Common/Components/ProtectedLayout";

import   Layout   from "./Common/Components/Layout";
import { RestoreSession } from "./Common/Components/RestoreSession";
import { Maintenance } from "../src/Login/Under-Maintenance";
import { Login } from "./Login/Login";
import { Logout } from "./Login/Logout";
import { Home } from "./Modules/Home/Home";
import './Common/Assets/Styles/globals.css';

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "./Common/Components/Header";


const App: React.FC = () => {
  let appClassName: string = "App";
  if (window.innerWidth <= 1200 && window.innerWidth >= 1000) {
    appClassName = `${appClassName} small-width`;
  }

  return (
    <div  >
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {  
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
     
         
          <Route path="/Under-Maintenance" component={Maintenance} />
          <Route path="/RestoreSession" component={RestoreSession} />
          <Route path="/" component={ProtectedLayout}> 
          {/* <Route path="/" component={Header} /> */}
        </Route>
         
        </Switch>
      </Router> }  

{/* {  <Router>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/logout" component={Logout} />
          <Route exact component={ProtectedLayout} />
          <Route path="Home">
            <Route exact component={Home} />
          </Route>
          
        
      </Switch>
    </Router>  } */}
    </div>
  );
};

export default App;
