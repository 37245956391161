import * as React from "react";
import { User } from "../../Common/Services/User";

export class RestoreSession extends React.Component<any, any> {


  componentDidMount() {
    this.isTokenActive();
  }


  isTokenActive = () => {
    const valuesArray = this.getParamValueByParamName("");
    if (valuesArray.length > 1) {
      const { location, history } = this.props;
      const uniqueNo = valuesArray ? window.atob(valuesArray[0]?.split('=')[1] as any) : "";
      const tokenID = valuesArray ? window.atob(valuesArray[1]?.split('=')[1] as any) : "";
      const etype = valuesArray ? window.atob(valuesArray[2]?.split('=')[1] as any) : "";
      const backHid = valuesArray ? window.atob(valuesArray[3]?.split('=')[1] as any) : "";
      const oprID = valuesArray ? window.atob(valuesArray[4]?.split('=')[1] as any) : "";
      const backPage = valuesArray ? window.atob(valuesArray[5]?.split('=')[1] as any) : "";
      const code = valuesArray ? valuesArray[6]?.split('=')[1] as any : "";
      if (tokenID != null || Number(tokenID) > 0) {
         User.restoreTokenDetails(tokenID,code).then((response: any) => {
          if (etype.toLowerCase() === "journal") {
            window.location.href = window.location.origin + "/Journal?Uniqueno=" + btoa(uniqueNo) + "&tokenID=" + btoa(tokenID) + "&Etype=" + btoa(etype) + "&HID=" + btoa(backHid)
              + "&OprID=" + btoa(oprID) + "&BackPage=" + btoa(backPage);
          }
          else {
            window.location.href = window.location.origin + "/Register?Uniqueno=" + btoa(uniqueNo) + "&tokenID=" + btoa(tokenID) + "&Etype=" + btoa(etype) + "&HID=" + btoa(backHid)
              + "&OprID=" + btoa(oprID) + "&BackPage=" + btoa(backPage);
          }

         });


      }


    }

  };

  getParamValueByParamName = (paramName: string) => {
    let valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
    window.history.replaceState(null, '');
    return valuesArray;

  };

  render() {
    return (
      <div>

      </div>
    );
  }
}