import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button ,Form} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { CustomerService } from "../../Common/Services/CustomerService";
import {Customerorderslideout}  from '../Customerorderslideout';
import {Utils}  from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
 
import moment from "moment";
import { JobOrderService } from "../../Common/Services/JobOrderService";
import {TrackerSlideout}  from './TrackerSlideout';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";

const { SearchBar } = Search;
 


 



const DOMPurify = createDOMPurify(window);

export class Tracker extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
     
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      orderid:0,
      jobid:0,
      customername:'',
      customerpo:'',
      orderdate:null,
      jobno: '',
      partno: '',
      partname: '',
      duedate:null,
      qty:0,
      isjobNo:true,
iscustomerPoNumber:true,
iscustomerName:true,
isorderDate:true,

isassignedTo:true,
      selectedcolumn:"All",
      selectOptions :  [ {
       id: 'All',name: 'All'},
     {  id: 'Job #',name: 'Job No.'},
       {id: 'Customer PO Number',name:  'Customer PO Number'},
       {id: 'Customer Name',name:  'Customer Name'},
       { id: 'Order Date',name:  'Order Date'},
       { id: 'Assigned To',name:  'Assigned To'},
      
    ]
    };
    
 
  }
 

  componentDidMount() { 
  
   this.getJobItems();
  }

  getJobItems()
  {
    
    let requestlist = { ...this.state.requestlist };
    JobOrderService.GetJobOrderByAssigned(requestlist)
    .then(async (result: any[] | null) => {
      if (result != null) {
        // console.log(result);
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  }

 
  shownewslideout   = () => {
    this.setState({ showSlideout: true ,orderid:0,jobid:0, customername:'',customerpo:'',orderdate:null, jobno:'',partno:'',partname:'',duedate:null,qty:0, isSpinner:false,});
  };


  showslideout = () => {
    this.setState({ showSlideout: true , isSpinner:false, });

  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner:false, });
    this.getJobItems();
};
 rowEvents = {
  onClick: (e: any, row: any, rowIndex: any) => {
    
    this.setState({ showSlideout: true ,orderid:0,jobid:row.jobId, customername:row.customerName,
      customerpo:row.customerPoNumber,orderdate: row.orderDate,jobno: row.jobNo,partno: row.partno,partname: row.partname,qty: row.qtyordered,duedate: row.dueDate, isSpinner:false,
    });  
    
  },
};


handleSelectedItem1 = (control: any, id: any) => {
  let searcResult1 = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.searcResult))
  );

 
  let isassignedTo = id == "Assigned To" ? true : false;
  let isjobNo = id == "Job #" ? true : false;
  let iscustomerPoNumber = id == "Customer PO Number" ? true : false;
  let iscustomerName = id == "Customer Name" ? true : false;
  let isorderDate = id == "Order Date" ? true : false;

  if (id == "All") {
    isassignedTo = true;
    isjobNo = true;
    iscustomerPoNumber = true;
    iscustomerName = true;
    isorderDate = true;

  }


  this.setState({
    searcResult: [], selectedcolumn: id, isassignedTo: isassignedTo, isjobNo: isjobNo,
    iscustomerPoNumber: iscustomerPoNumber, iscustomerName: iscustomerName,isorderDate:isorderDate
  },
    () => {
      this.setState({ searcResult: searcResult1 });

    }


  );

};


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };


    const columns = [
      
      {
        dataField: 'jobNo',
        text: 'Job No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.isjobNo,
      },
      {
        dataField: 'customerPoNumber',
        text: 'CO No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.iscustomerPoNumber,
      },
      {
        dataField: 'customerName',
        text: 'Customer',
        sort: true,
        headerStyle: { width: '100px' },
        searchable:this.state.iscustomerName,
      },
      {
        dataField: 'totalSteps',
        text: 'Total Process',
        sort: true,
        headerStyle: { width: '100px' },
      },
      {
        dataField: 'runningSteps',
        text: 'Running Process',
        sort: true,
        headerStyle: { width: '100px' },
      },
      {
        dataField: 'completedSteps',
        text: 'Completed Process',
        sort: true,
        headerStyle: { width: '100px' },
      },
    ];
    
    const {
      
      showSlideout,
      
  } = this.state;
 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

               Tracker 
              <div className="action-group d-flex flex-row ml-auto">
                {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
              </div>
            </div>
            <section className="mt-3">
         
                  <ToolkitProvider
                    keyField="id"
                    data={ this.state.searcResult }
                    columns={ columns }
                   
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  {...props.baseProps}
                                    rowEvents={this.rowEvents}
                                   pagination={paginationFactory(options)}

                                />
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>
 
</section>

                  {(showSlideout &&

<TrackerSlideout
closeSlideOut={this.closeSlideOut}

jobid = {this.state.jobid}
jobno={this.state.jobno}
customerpo={this.state.customerpo}
customername={this.state.customername}
orderdate={this.state.orderdate}
partno={this.state.partno}
partname={this.state.partname}
qty={this.state.qty}
>


</TrackerSlideout>

              )}

          </Container>



        </React.Fragment>
      </>
    );
  }
}