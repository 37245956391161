 
import { Home } from "../Modules/Home/Home";
import { Overview } from "../Modules/Home/Overview";

import { Customerorder } from "../Modules/Customerorder";
import { JobOrder } from "../Modules/Job/JobOrder";
import { Tracker } from "../Modules/Job/Tracker";
import { CustomerMaster } from "../Modules/Masters/CustomerMaster";
import { BankMaster } from "../Modules/Masters/BankMaster";
import { ProductMaster } from "../Modules/Masters/ProductMaster";
import { NCRCodeMaster } from "../Modules/Masters/NCRCodeMaster";
import { WorkstationMaster } from "../Modules/Masters/WorkstationMaster";
import { PartTemplate } from "../Modules/Settings/PartTemplate";
import { Documents } from "../Modules/Settings/Documents";
import { User } from "../Modules/UserManagement/User";
import { RolePermission } from "../Modules/UserManagement/RolePermission";
import { VendorOrder } from "../Modules/Purchasing/VendorOrder";
import { VendorReceiving } from "../Modules/Purchasing/VendorReceiving";
import { Receivable } from "../Modules/Accounts/Receivable";
import { InvoiceApproval } from "../Modules/Purchasing/InvoiceApproval";
import { InvoiceList } from "../Modules/Purchasing/InvoiceList";
import { InvoicePayment } from "../Modules/Purchasing/InvoicePayment";
import { VendorMaster } from "../Modules/Masters/VendorMaster";
import { Quotations } from "../Modules/Quotations";
import { ProcessMaster } from "../Modules/Masters/ProcessMaster";
import { PriceBreakdownMaster } from "../Modules/Masters/PriceBreakdownMaster";
import { Register } from "../Modules/Accounts/Register";
import { NCRReport } from "../Modules/Reports/NCRReport";
import { NCR } from "../Modules/Job/NCR";

import { Component } from "react";
 

export const protectedRoutes = [
 
  
  {
    path: "/overview",
    name: "Overview",
    Component: Overview,
  },

  {
    path: "/home",
    name: "Home",
    Component: Home,
  },
  
  {
    path: "/customerorder",
    name: "Customerorder",
    Component: Customerorder,
  },

  {
    path: "/customermaster",
    name: "CustomerMaster",
    Component: CustomerMaster,
  },
  {
    path: "/bankmaster",
    name: "BankMaster",
    Component: BankMaster,
  },
  {
    path: "/JobOrder",
    name: "JobOrder",
    Component: JobOrder,
  },
  {
    path: "/productmaster",
    name: "ProductMaster",
    Component: ProductMaster,
  },
  {
    path: "/NCRCodeMaster",
    name: "NCRCodeMaster",
    Component: NCRCodeMaster,
  },
  {
    path: "/workstationmaster",
    name: "WorkstationMaster",
    Component: WorkstationMaster,
  },  
  {
    path: "/parttemplate",
    name: "PartTemplate",
    Component: PartTemplate,
  },
  {
    path: "/documents",
    name: "Documents",
    Component: Documents,
  }
  ,
  {
    path: "/User",
    name: "User",
    Component: User,
  },
  {
    path: "/RolePermission",
    name: "Role Permission",
    Component: RolePermission,
  },
  {
    path: "/VendorOrder",
    name: "VendorOrder",
    Component: VendorOrder,
  },
  {
    path: "/Quotations",
    name: "Quotations",
    Component: Quotations,
  },
  {
    path: "/VendorReceiving",
    name: "VendorReceiving",
    Component: VendorReceiving,
  },
  {
    path: "/Tracker",
    name: "Tracker",
    Component: Tracker,
  }
  ,
  {
    path: "/Receivable",
    name: "Receivable",
    Component: Receivable,
  }
  ,
  {
    path: "/InvoiceList",
    name: "InvoiceList",
    Component: InvoiceList,
  } ,
  {
    path: "/InvoicePayment",
    name: "InvoicePayment",
    Component: InvoicePayment,
  }
  ,
  {
    path: "/InvoiceApproval",
    name: "InvoiceApproval",
    Component: InvoiceApproval,
  }
  ,
  {
    path: "/VendorMaster",
    name: "VendorMaster",
    Component: VendorMaster,
  }
  ,
  {
    path: "/ProcessMaster",
    name: "ProcessMaster",
    Component: ProcessMaster,
  }
  ,
  {
    path: "/PriceBreakdown",
    name: "PriceBreakdown",
    Component: PriceBreakdownMaster,
  },
  {
    path: "/Register",
    name: "Register",
    Component: Register,
  }
  ,
  {
    path: "/NCRReport",
    name: "NCRReport",
    Component: NCRReport,
  }
  ,
  {
    path: "/NCR",
    name: "NCR",
    Component: NCR,
  }

];

