import * as React from "react";
import { Container, Dropdown, Accordion, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { WorkstationService } from "../../Common/Services/WorkstationService";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { WorkstationSlideout } from './WorkstationSlideout';
import _ from "lodash";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
const { SearchBar } = Search;

  
export class WorkstationMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          workstationid:0,
          workstationname:'',
          isactive:0,
          selectedcolumn: "All",
          selectOptions: [
            { id: 'All', name: 'All'},
            { id: 'Workstation Name', name: 'Workstation Name' },
            { id: 'isActive', name: 'isActive' },
            { id: 'Assigned User', name: 'Assigned User' },
          ],
        };
        
     
      }
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getWorkstations(requestlist);
      }

      handleSelectedItem1 = (control: any, id: any) => {
        let searcResult1 = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
    
        let chkisactive = id == "isActive" ? true : false;
        let chkisworkstationname = id == "Workstation Name" ? true : false;
    let chkassignedusers=id=="Assigned User" ?true:false;
    
        if (id == "All") {
          chkisactive = true;
          chkisworkstationname = true;
          chkassignedusers=true;
        }
    
    
        this.setState({
          searcResult: [], selectedcolumn: id, isactive: chkisactive,
          isworkstationname: chkisworkstationname,isassignedusers : chkassignedusers,
        },
          () => {
            this.setState({ searcResult: searcResult1 });    
          }
    
    
        );
    
      };
      getWorkstations = (requestlist: any) => {
 
        WorkstationService.GetWorkstations(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {
        result.forEach((element)=>{
          element.isActiveText = element.isActive ? 'Active':'InActive';
        });
        this.setState({ searcResult: result }, () => {
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true, workstationid: 0,workstationname:'',isactive:true });
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false });
    let requestlist = { ...this.state.requestlist };
    this.getWorkstations(requestlist);
    
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({
        showSlideout: true, workstationid: row.id,workstationname: row.workstationName,isactive:row.isActive
      });


    },
  };


  render() {
    const {

      showSlideout,

    } = this.state; 
    const columns = [
      // {
      //   dataField: 'id',
      //   text: 'Workstation ID',
      // },
     
      {
        searchable: this.state.isworkstationname,
        dataField: 'workstationName',
        text: 'Workstation Name',
        sort: true,
      },
      {
        searchable: this.state.isassignedusers,
        dataField: 'userName',
        text: 'Assigned Users',
        sort: true,
      },
      {
        searchable: this.state.isactive,
        dataField: 'isActiveText',
        text: 'Is Active',
        hidden: false,
        editable: false,
        headerAttrs: (column, colIndex) => (
          { 'tabIndex': '-1' }
        ),
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            
            <>
           
            <EllipsisWithTooltip placement="bottom">
              
                
  
                  <span className={ row.isActiveText==="Active" ? "badge badge-pill badge-primary color-green" : "badge badge-pill badge-primary color-red"}> {row.isActiveText} </span>
  
  
              </EllipsisWithTooltip>
        
        
              </>
  
          );
      },
  
      },
    ];

    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
            Workstation Master
              <div className="action-group d-flex flex-row ml-auto">
              <Button  onClick={(e) => this.shownewslideout()}>Add Workstation</Button>
              </div>
            </div>
            <section className="mt-3">
            <ToolkitProvider
        keyField="id"
        data={this.state.searcResult}
        columns={columns}

         //className="table table-striped table-borderless align-middle"
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div>
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    keyField="rowId"
                  

                  />
                  </div>
                </div>
            

              </section>
            </div>

          )
        }
 </ToolkitProvider>
   </section>
 {(showSlideout &&
                <WorkstationSlideout
                  closeSlideOut={this.closeSlideOut}
                  workstationid={this.state.workstationid}
                  workstationname={this.state.workstationname}
                  isactive={this.state.isactive}
                >
                </WorkstationSlideout>
              )}

          </Container>

        </React.Fragment>
      </>
    );
  }
}