import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 
export class DocumentService {

 

 


public static GetDocumentslist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
   
  request.tenantid = tenantID;
  const url = `/Documents/GetDocumentslist`;
  return Instense.get(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
 
 
   



}