import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
    
    const TawkMessenger = () => {
      return (
        <div>
          {/* Other content */}
          <TawkMessengerReact
            propertyId="66eacaea4cbc4814f7da1cf9"
            widgetId="1i82ih9n7"
          />
        </div>
      );

};

export default TawkMessenger;