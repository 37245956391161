import axios from "axios";
import Instense from "./Axios-config";



    export class UserService {


        public static GetUserMaster = async (request: any): Promise<any | null> => {
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
             
            request.tenantid = tenantID;
            const url = `/User/GetUserData`;
            return Instense.get(url, { params: request }).then((response) => {
              let result = response.data.result as any;
              return result;
            });
          };


          public static GetRoleData = async (request: any): Promise<any | null> => {
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
             
            request.tenantid = tenantID;
            const url = `/User/GetRoleDataNew`;
            return Instense.get(url, { params: request }).then((response) => {
              let result = response.data.result as any;
              return result;
            });
          };


          public static GetUserDataByUserid = async (request: any): Promise<any | null> => {
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
             
            request.tenantid = tenantID;
            const url = `/User/GetUserDataByUserid`;
            return Instense.get(url, { params: request }).then((response) => {
              let result = response.data.result as any;
              return result;
            });
          };


          public static SaveUserData = async (request: any): Promise<any> => {
            // debugger;
            let storage = JSON.parse(localStorage.getItem("storage")!);
            let tenantID = storage === null ? 0 : (storage.tenantID as any);
            let userName = storage === null ? "" : (storage.userName as any);
            request.TenantID = tenantID;
            request.UserName = userName;
            const url = `/User/SaveUserData `;
            return Instense.post(url, request).then((response) => {
              // debugger;
                let result = response.data.result as any[];
                return result;
            });
          };
    }