import React from 'react';
import { toast, ToastContainer } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AutosizeInput from "react-input-autosize";
import moment from "moment";
import {
  Container, Form, Button, Dropdown,
  Spinner,
  OverlayTrigger, Tooltip

} from "react-bootstrap";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { Utils } from "../../Common/Utilis";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { CommentBox } from "../../Common/Components/CommentBox";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { User } from "../../Common/Services/User";
import { faArrowUp, faListSquares } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JobServices } from "../../Common/Services/JobServices";
// import { Vendororderslideout } from './Vendororderslideout';
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
import { MdDesignServices } from "react-icons/md";
import { FaLaptopHouse } from 'react-icons/fa';
import { Vendororderslideout } from '../Vendororderslideout';
import { JobCreations } from '../JobCreations';
import { Receivableslideout } from '../Accounts/Receivableslideout';
import { NCRSlideout } from '../Job/NCRSlideout';
import { ShippingSlideout } from '../ShippingSlideout';
import { Customerorderslideout } from '../Customerorderslideout';
const { SearchBar } = Search;
// const DOMPurify = createDOMPurify(window);
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Select Process
  </Tooltip>
);
export class Overview extends React.Component<any, any> {
  private child: any;

  constructor(props: any) {

    super(props);
    this.getoverviewdata = this.getoverviewdata.bind(this);
   // this.handleSelectedItem1=this.handleSelectedItem1(this);
    this.state = {
      selectedcolumn: "All",
      iscustpo:true,
      iscustnm:true,

      isvendornm:true,
      ispart:true,

      isjob:true,
      isvendorno:true,
      isinvoice:true,
      ismaterial:true,
      isshipping:true,
      isorderdate:true,
      isncr:true,
      Partorders: 0,
      JobCreated: 0,
      vendorpendingorders: 0,
      isFirst: 0,
      vendorsent: 0,
      vendorreceived: 0,
      overviewdata: [],
      partoverviewdata: [],
      joboverviewdata: [],
      customeroverviewdata: [],
      processselectedRows: [],
      invoverviewdata: [],
     // columns: this.columns,
      pcolumns: this.pcolumns,
      partcolumns: this.partcolumns,
      jobcolumns: this.jobcolumns,
      ncolumns: this.ncolumns,
      icolumns: this.icolumns,
      vcolumns: this.vcolumns,
      mcolumns: this.mcolumns,
      scolumns: this.scolumns,

      selectOptions: [{
        id: 'All', name: 'All'
      },
      { id: 'Customer PO #', name: 'Customer PO No.' },
      { id: 'Customer Name', name: 'Customer Name' },
      { id: 'Vendor Name', name: 'Vendor Name' },
      { id: 'Part #', name: 'Part No.' },
      { id: 'Job #', name: 'Job No.' },
      { id: 'Vendor No.', name: 'Vendor No.' },
      { id: 'Material', name: 'Material' },
      { id: 'Shipping No', name: 'Shipping No' },

      { id: 'Order Date', name: 'Order Date' },

      { id: 'NCR', name: 'NCR' },
      ],


    };




  }

  handleSelectedItem1 = (control: any, id: any) => {
    let overviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.overviewdata))
    );




    let iscustpo = id == 'Customer PO #' ? true : false;
    let iscustnm = id == 'Customer Name' ? true : false;
    let isvendornm = id == 'Vendor Name' ? true : false;
    let ispart = id == 'Part #' ? true : false;
    let isjob = id == 'Job #' ? true : false;
    let isvendorno = id == 'Vendor No.' ? true : false;
    let isinvoice = id == 'Invoice No.' ? true : false;
    let ismaterial = id == 'Material' ? true : false;
    let isshipping = id == 'Shipping No' ? true : false;

    let isorderdate = id == 'Order Date' ? true : false;

    let isncr = id == 'NCR' ? true : false;

    if (id == "All") {
      iscustpo = true;
      iscustnm = true;
      isvendornm = true;
      ispart = true;

      isjob = true;
      isvendorno = true;
      isinvoice = true;
      ismaterial = true;
      isshipping = true;
      isorderdate = true;
      isncr = true;
    }


    this.setState({
      overviewdata: [], selectedcolumn: id, iscustpo: iscustpo, iscustnm: iscustnm, isvendornm: isvendornm, ispart: ispart, isjob: isjob,
      isvendorno: isvendorno, isinvoice: isinvoice,
      ismaterial: ismaterial, isshipping: isshipping,
      isorderdate: isorderdate, isncr: isncr,
    },
      () => {
        this.setState({ overviewdata: overviewdata });

      }


    );

  };



  private partcolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },
    {
      dataField: "partNumber",
      text: "Part Number",
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <>
            {/* <div>{row.partNumber}</div> */}


            <OverlayTrigger placement="top"
              overlay={<Tooltip id={String(row.rowId)}>{row.partName}, Order Qty - {row.qtyOrdered}</Tooltip>}>
              <div>{row.partNumber}</div>

            </OverlayTrigger>

          </>
        );
      },
    },
  ];

  private jobcolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },
    {
      dataField: "jobNumber",
      text: "Job Number",

    },
  ];



  private mcolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },


    {
      dataField: "material",
      text: "Material",

    },

  ];


  private pcolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },

    {
      dataField: "jobId",
      text: "jobId",
      hidden: true,
    },

    {
      dataField: "process",
      text: "Process",
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.process}
            {row.trackerStatus != '' && row.trackerStatus != 'NCR' &&
              <span className="badge badge-pill badge-primary color-green"> {row.trackerStatus == "progress" ? "running" : row.trackerStatus} </span>}

            {row.ncr != '' &&


              <span className="badge badge-pill badge-primary color-red"> {row.ncr} </span>

            }



          </div>
        );
      },
    },

  ];




  private vcolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },

    {
      dataField: "jobId",
      text: "jobId",
      hidden: true,
    },
    {
      dataField: "vendorOrderId",
      text: "vendororderId",
      hidden: true,
    },


    {
      dataField: "vendorOrder",
      text: "Vendor Order",

    },

  ];


  private scolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "jobId",
      text: "jobId",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },
    {
      dataField: "shipmentid",
      text: "shipmentid",
      hidden: true,
    },

    {
      dataField: "shipping",
      text: "Shipping",

    },

  ];

  private icolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },
    {
      dataField: "invoiceId",
      text: "invoiceId",
      hidden: true,
    },

    {
      dataField: "productId",
      text: "productId",
      hidden: true,
    },

    {
      dataField: "invoice",
      text: "Invoice",
      classes: "table-activeselected",
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.invoice}
            {row.paymentDate != null &&


              <span className="badge badge-pill badge-primary color-green"> received </span>

            }
          </div>
        );
      },
    },

  ];

  private ncolumns = [
    {
      dataField: "rowId",
      text: "rowid",
      hidden: true,
    },
    {
      dataField: "jobId",
      text: "jobId",
      hidden: true,
    },
    {
      dataField: "orderId",
      text: "id",
      hidden: true,
    },



    {
      dataField: "ncr",
      text: "NCR",

    },

  ];
  componentDidMount() {

    this.getoverviewdata();


  }

  showncrslideout = () => {

    this.setState({ showncrslideout: true });
  };

  getoverviewdata = () => {

    let request: any = {};




    JobServices.GetOverviewdata(request)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null && resultinv.length > 0) {
          this.setState({ overviewdata: resultinv, customeroverviewdata: resultinv, partoverviewdata: resultinv, joboverviewdata: resultinv, invoverviewdata: resultinv }
            , () => {
              this.handleTemplateOnDefault(resultinv[0].orderId, true);
              //this.setState({selectedTemplateId : resultinv[0].orderId==undefined ? false : resultinv[0].orderId});
              //this.setState({selectedTemplateId : resultinv[0].orderId==undefined ? false : resultinv[0].orderId ,partselected:resultinv[0].productId==undefined ? false :resultinv[0].productId, jobselected: resultinv[0].jobId==undefined ? false  : resultinv[0].jobId, });
              //this.handleTemplateOnSelect(selectedTemplateId: resultinv[0].orderId,true);
            }

          );

          resolve();
        }
      })
      .catch((error) => {

        reject();
      });
  };


  handlepartOnSelect = (row, isSelect) => {

    let partoverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.partoverviewdata))
    );
    let joboverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.joboverviewdata))
    );
    let overviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.overviewdata))
    );
    let jobselected: any;
    let partselected: any;
    joboverviewdata.forEach(element => {
      if (element.productId == row.productId) {

        element.selected = isSelect;

        partselected = element.productId;
        jobselected = element.jobId;




      }
      else
        element.selected = false;
    });
    ;
    overviewdata.filter(o => o.orderId == row.orderId && o.productId == partselected);
    this.setState({ overviewdata: [], selectedTemplateId: row.orderId, partselected: partselected, jobselected: jobselected, isSpinner: false }, () => {

      this.setState({ overviewdata: overviewdata, partoverviewdata: partoverviewdata, joboverviewdata: joboverviewdata });
      //  this.getProcessDataforPopup(row.id);

    });

  };

  handlejobOnSelect = (row, isSelect) => {

    let partoverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.partoverviewdata))
    );
    let joboverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.joboverviewdata))
    );
    let overviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.overviewdata))
    );
    let jobselected: any;
    let partselected: any;
    let showJobSlideout = false;
    joboverviewdata.forEach(element => {
      if (element.productId == row.productId) {

        if (element.selected == true) {

          showJobSlideout = true;
        }


        element.selected = isSelect;

        partselected = element.productId;
        jobselected = element.jobId;




      }
      else
        element.selected = false;
    });

    overviewdata.filter(o => o.orderId == row.orderId && o.productId == partselected);
    this.setState({ overviewdata: [], selectedTemplateId: row.orderId, partselected: partselected, jobselected: jobselected, isSpinner: false }, () => {

      this.setState({ showJobSlideout: showJobSlideout, jobid: row.jobId, productid: row.productId, orderdetailid: row.orderdetailid, orderid: row.orderId, jobNo: row.jobNumber, overviewdata: overviewdata, partoverviewdata: partoverviewdata, joboverviewdata: joboverviewdata });
      //  this.getProcessDataforPopup(row.id);

    });

  };

  handleTemplateOnSelect = (row, isSelect) => {
    let {

      processselectedRows,

    } = this.state;

    let customeroverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customeroverviewdata))
    );

    let partoverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.partoverviewdata))
    );

    let invoverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.invoverviewdata))
    );



    partoverviewdata = [];
    let joboverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customeroverviewdata))
    );
    let showCustomerSlideout = false;

    let partselected: any;
    let jobselected: any;
    let invoiceselected: any;
    customeroverviewdata.forEach(element => {
      if (element.orderId == row.orderId) {


        if (element.selected == true) {

          showCustomerSlideout = true;
        }
        element.selected = isSelect;
        if (partselected == undefined) {
          partselected = element.productId;
        }

        if (jobselected == undefined) {
          jobselected = element.jobId;
        }
        //invoiceselected=element.invoiceId;
        


        processselectedRows = [...this.state.processselectedRows, row.invoiceId];

        let item: any = {};
        item.rowId = element.rowId;
        item.orderId = element.orderId;
        item.productId = element.productId;
        item.partNumber = element.partNumber;
        item.partName = element.partName;
        item.qtyOrdered = element.qtyOrdered;
        item.selected = isSelect;
        partoverviewdata.push(item);
      }
      else
        element.selected = false;
    });


    invoverviewdata.forEach(element => {
      element.selected = true;
    });

    var jobdata = joboverviewdata.filter(o => o.orderId == row.orderId);

    this.setState({ orderid: row.orderId, overviewdata: [], invoverviewdata: invoverviewdata, processselectedRows: processselectedRows.filter(o => o != undefined || o != null), customeroverviewdata: customeroverviewdata, selectedTemplateId: row.orderId, partselected: partselected, jobselected: jobselected, isSpinner: false }, () => {

      this.setState({ showCustomerSlideout: showCustomerSlideout, overviewdata: customeroverviewdata, partoverviewdata: partoverviewdata, joboverviewdata: jobdata });
      //  this.getProcessDataforPopup(row.id);
    });

  };


  handleTemplateOnDefault = (row, isSelect) => {
    let {

      processselectedRows,

    } = this.state;
    let customeroverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customeroverviewdata))
    );

    let partoverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.partoverviewdata))
    );
    let invoverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.invoverviewdata))
    );


    partoverviewdata = [];
    let joboverviewdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.customeroverviewdata))
    );

    let partselected: any;
    let jobselected: any;

    customeroverviewdata.forEach(element => {
      if (element.orderId == row) {

        element.selected = isSelect;
        if (partselected == undefined) {
          partselected = element.productId;
        }

        if (jobselected == undefined) {
          jobselected = element.jobId;
        }

        processselectedRows = [...this.state.processselectedRows, row.invoiceId];
        let item: any = {};
        item.rowId = element.rowId;
        item.orderId = element.orderId;
        item.productId = element.productId;
        item.partNumber = element.partNumber;
        item.partName = element.partName;
        item.qtyOrdered = element.qtyOrdered;
        item.selected = isSelect;
        partoverviewdata.push(item);
      }
      else
        element.selected = false;
    });


    // invoverviewdata.forEach(element => {
    //   element.selected = true;
    // });

    var jobdata = joboverviewdata.filter(o => o.orderId == row);

    this.setState({ invoverviewdata: invoverviewdata, processselectedRows: processselectedRows, customeroverviewdata: customeroverviewdata, selectedTemplateId: row, partselected: partselected, jobselected: jobselected, isSpinner: false }, () => {

      this.setState({ overviewdata: customeroverviewdata, partoverviewdata: partoverviewdata, joboverviewdata: jobdata });
      //  this.getProcessDataforPopup(row.id);
    });

  };

  handleVendorOnSelect = (row, isSelect) => {

    this.setState({ showSlideout: true, id: row.vendorOrderId, customerpo: row.vendorOrder, vendororderid: row.vendorOrderId, jobid: row.jobId, orderid: row.orderId });
  };


  handleShippingOnSelect = (row, isSelect) => {

    this.setState({ showShippingSlideout: true, shipmentid: row.shipmentid, jobIds: this.state.overviewdata.filter(x => x.selected === true).map(r => r.jobId), orderid: row.orderId });
  };
  handlepprocessOnSelect = (row, isSelect) => {

    if (row.trackerStatus == "NCR") {
      this.setState({ showNCRSlideout: row.trackerStatus = "NCR" ? true : false, jobid: row.jobId, orderid: row.orderId });

    }
  };

  handleInvoiceOnSelect = (row, isSelect) => {

    this.setState({
      showReceivableslideout: true, invoiceId: row.invoiceId, jobid: row.jobId, orderid: row.orderId,
      orderIds: this.state.overviewdata.filter(x => x.selected === true).map(r => r.orderId),

      products: this.state.overviewdata.filter(x => x.selected === true).map(r => r.productId),

    });

  };


  closeSlideOut = () => {
    this.setState({ showSlideout: false, showCustomerSlideout: false, showNCRSlideout: false, showShippingSlideout: false, isSpinner: false, });

  };

  closeJobSlideout = () => {
    this.setState({ showJobSlideout: false, isSpinner: false, });

  };
  closeInvoiceSlideOut = () => {
    this.setState({ showReceivableslideout: false, isSpinner: false, });

  };

  render() {

    const columns = [
      {
        dataField: "orderId",
        text: "id",
        hidden: true,
      },
      {
        dataField: "customerName",
        text: "Customer Order",
        hidden: true,
        searchable: this.state.iscustnm,
      },
      {
        dataField: "vendorName",
        text: "Customer Order",
        hidden: true,
        searchable: this.state.isvendornm,
      },
      {
        dataField: "orderDate",
        text: "Customer Order",
        hidden: true,
        searchable: this.state.isorderdate,
      },
      {
        dataField: "partNumber",
        text: "Customer Order",
        hidden: true,
        searchable: this.state.ispart,
      },
      {
        dataField: "partName",
        text: "Customer Order",
        hidden: true,
        searchable: this.state.ispartname,
      },
      {
        dataField: "invoice",
        text: "Customer Order",
        hidden: true,
        searchable: this.state.isinvoice,
      },
      {
        dataField: "jobNumber",
        text: "Job Number",
        hidden: true,
        searchable: this.state.isjob,
      },
  
      {
        dataField: "material",
        text: "Material",
        hidden: true,
        searchable: this.state.ismaterial,
      },
  
      {
        dataField: "vendorOrder",
        text: "Vendor Order",
        hidden: true,
        searchable: this.state.isvendorno,
      },
  
      {
        dataField: "shipping",
        text: "Shipping",
        hidden: true,
        searchable: this.state.isshipping,
      },
      {
        dataField: "ncr",
        text: "NCR",
        hidden: true,
        searchable: this.state.isncr,
      },
      {
        dataField: "customerOrder",
        text: "Cust. PO#",
  
        searchable: this.state.iscustpo,
      },
  
    ];
    const {
      showCustomerSlideout,
      showSlideout,
      showJobSlideout,
      showReceivableslideout,
      showNCRSlideout,
      showShippingSlideout,
    } = this.state;

    const popUpState = localStorage?.getItem("PopUpState") ? JSON.parse(localStorage?.getItem("PopUpState") || "") : {};




    return (
      <>



        <Container fluid className="body-sec">
          <div className="page-heading underline d-flex  ">

            Overview
            <div className="action-group d-flex flex-row ml-auto">
              {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
            </div>
          </div>


          <section className="mt-3">

            <section >

              {true &&




                <ToolkitProvider
                  keyField="orderId"
                  data={

                    this.state.customeroverviewdata.filter((ele, ind) => ind === this.state.customeroverviewdata.findIndex(elem => elem.orderId === ele.orderId))
                  }
                  columns={columns}

                  search
                >
                  {(props: {
                    searchProps: JSX.IntrinsicAttributes;
                    baseProps: JSX.IntrinsicAttributes;
                  }) => (
                    <>
                      <div className='row g-1'>
                        <section className="d-flex flex-wrap gap-3 pb-2">
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} autoComplete={false} />
                          </div>

                          <div
                          //className="input-group w-auto"
                          >
                            <Form.Label>
                              <SingleDropdownListWithoutSearch

                                itemList={this.state.selectOptions}
                               handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn}
                                controlID="1"
                                id={"ddlselected"}

                              /></Form.Label>
                          </div>

                        </section>

                      </div>
                      <div className='row g-1'>
                        <div className="col-1 widthspl125">
                          <div className="table-responsive"  >



                            <div className="section" style={{ display: "flex" }}>
                              <div className="overview-settings d-flex justify-content-start">
                                <div className="left-panel">
                                  <section >
                                    <div className="search-header">
                                     Order No.
                                    </div>
                                    <div className="left-body">
                                      <BootstrapTable

                                        {...props.baseProps}

                                        hover
                                        condensed

                                        selectRow={{
                                          mode: 'radio',
                                          clickToSelect: true,
                                          classes: "table-active1",
                                          // style: { backgroundColor: '#c8e6c9' },
                                          hideSelectColumn: true,
                                          onSelect: this.handleTemplateOnSelect,
                                          selected: [this.state.selectedTemplateId],
                                        }}
                                      />
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>


                          </div>



                        </div>
                        <div className="col-1 widthspl125">
                          <div className="table-responsive"  >

                            {true &&







                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">

                                    <section >
                                      <div className="search-header">
                                        Part No.
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="productId"
                                          data={
                                            // this.state.overviewdata
                                            this.state.partoverviewdata.filter((ele, ind) => ind === this.state.partoverviewdata.findIndex(elem => elem.partNumber === ele.partNumber) && ele.partNumber != "" && ele.orderId == this.state.selectedTemplateId)
                                          }
                                          columns={this.state.partcolumns}

                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'radio',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handlepartOnSelect,
                                            selected: [this.state.partselected],
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>




                            }

                          </div>



                        </div>
                        <div className="col-1 widthspl125">
                          <div className="table-responsive"  >

                            {true &&







                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">



                                    <section >
                                      <div className="search-header">
                                        Job No.
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="jobId"
                                          data={
                                            // this.state.overviewdata
                                            this.state.joboverviewdata.filter((ele, ind) => ind === this.state.joboverviewdata.findIndex(elem => elem.jobNumber === ele.jobNumber) && ele.jobNumber != "" && ele.orderId == this.state.selectedTemplateId)
                                          }
                                          columns={this.state.jobcolumns}

                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'radio',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handlejobOnSelect,
                                            selected: [this.state.jobselected],
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>




                            }

                          </div>



                        </div>

                        <div className="col-1 widthspl">
                          <div className="table-responsive"  >

                            {true &&







                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">



                                    <section >
                                      <div className="search-header">
                                        Material
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="jobId"
                                          data={
                                            // this.state.overviewdata
                                            //  this.state.overviewdata.filter((ele, ind) => ind === this.state.overviewdata.findIndex(elem => elem.material === ele.material &&  elem.orderId == this.state.selectedTemplateId) && ele.material != "" && ele.orderId == this.state.selectedTemplateId )
                                            this.state.overviewdata.filter((ele, ind) => ind === this.state.overviewdata.findIndex(elem => elem.material === ele.material && elem.jobNumber === ele.jobNumber && elem.orderId == this.state.selectedTemplateId) && ele.material != "" && ele.orderId == this.state.selectedTemplateId)
                                            // this.state.overviewdata.filter(ele => ele.material != "" && ele.orderId == this.state.selectedTemplateId)

                                          }
                                          columns={this.state.mcolumns}

                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'radio',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handlepartOnSelect,
                                            selected: [this.state.jobselected],
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>





                            }

                          </div>



                        </div>

                        <div className="col-1 widthspl200">
                          <div className="table-responsive"  >

                            {true &&






                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">



                                    <section >
                                      <div className="search-header">
                                        Process
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="jobId"
                                          data={
                                            // this.state.overviewdata
                                            this.state.overviewdata.filter((ele, ind) => ind === this.state.overviewdata.findIndex(elem => elem.processId === ele.processId && elem.jobdetailsid === ele.jobdetailsid && elem.orderId == this.state.selectedTemplateId && elem.productId == this.state.partselected) && ele.process != "" && ele.orderId == this.state.selectedTemplateId && ele.productId == this.state.partselected)
                                            // this.state.overviewdata.filter(ele=> ele.orderId == this.state.selectedTemplateId && ele.jobId==this.state.jobselected && ele.productId==this.state.partselected && ele.process != "")
                                          }
                                          columns={this.state.pcolumns}

                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'checkbox',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handlepprocessOnSelect,
                                            selected: [this.state.jobselected],
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>





                            }

                          </div>



                        </div>

                        <div className="col-1 widthspl">
                          <div className="table-responsive"  >

                            {true &&







                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">



                                    <section >
                                      <div className="search-header">
                                        Vendor Order
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="jobId"
                                          data={
                                            // this.state.overviewdata
                                            this.state.overviewdata.filter((ele, ind) => ind === this.state.overviewdata.findIndex(elem => elem.vendorOrder === ele.vendorOrder) && ele.vendorOrder != "" && ele.orderId == this.state.selectedTemplateId && ele.orderId == this.state.selectedTemplateId)
                                          }
                                          columns={this.state.vcolumns}
                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'radio',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handleVendorOnSelect,
                                            selected: [this.state.jobselected],
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>





                            }

                          </div>



                        </div>

                        <div className="col-1 widthspl">
                          <div className="table-responsive"  >

                            {true &&






                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">



                                    <section >
                                      <div className="search-header">
                                        Shipping
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="shipmentid"
                                          data={
                                            // this.state.overviewdata
                                            this.state.overviewdata.filter((ele, ind) => ind === this.state.overviewdata.findIndex(elem => elem.shipping === ele.shipping && elem.orderId == this.state.selectedTemplateId && elem.shipping != "") && ele.shipping != "" && ele.orderId == this.state.selectedTemplateId && ele.shipping != "")
                                          }
                                          columns={this.state.scolumns}

                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'radio',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handleShippingOnSelect,
                                            selected: this.state.overviewdata.filter(o => o.jobId == this.state.jobselected).map(r => r.shipmentid),
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>





                            }

                          </div>



                        </div>

                        <div className="col-1 widthspl180">
                          <div className="table-responsive"  >

                            {true &&






                              <div className="section" style={{ display: "flex" }}>
                                <div className="overview-settings d-flex justify-content-start">
                                  <div className="left-panel">



                                    <section >
                                      <div className="search-header">
                                        Invoice
                                      </div>
                                      <div className="left-body">
                                        <BootstrapTable

                                          keyField="invoiceId"
                                          data={
                                            // this.state.overviewdata
                                            this.state.invoverviewdata.filter((ele, ind) => ind === this.state.invoverviewdata.findIndex(elem => elem.invoice === ele.invoice) && ele.invoice != "" && ele.orderId == this.state.selectedTemplateId && ele.invoice != "")
                                          }
                                          columns={this.state.icolumns}

                                          hover
                                          condensed

                                          selectRow={{
                                            mode: 'checkbox',
                                            clickToSelect: true,
                                            classes: "table-active1",
                                            // style: { backgroundColor: '#c8e6c9' },
                                            hideSelectColumn: true,
                                            onSelect: this.handleInvoiceOnSelect,
                                            selected: this.state.invoverviewdata.filter(o => o.productId == this.state.partselected).map(r => r.invoiceId),
                                          }}
                                        />
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>





                            }

                          </div>



                        </div>



                      </div>
                    </>
                  )
                  }
                </ToolkitProvider>



              }
            </section>
          </section>
          {(showSlideout &&

            <Vendororderslideout
              closeSlideOut={this.closeSlideOut}
              orderid={this.state.orderid}
              receivedStatus={this.state.receivedStatus}
              CustomerPoNumber={this.state.customerpo}
              poNumber={this.state.poNumber}
              productid={this.state.productid}
              partname={this.state.partname}
              partNo={this.state.partNo}
              qtyOrdered={this.state.qtyOrdered}
              jobid={this.state.jobid}
              itemNo={this.state.itemNo}
              vendororderid={this.state.vendororderid}
              vendorordertype={this.state.vendorordertype}
              selectedCustomer={this.state.selectedCustomer}
              orderdate={this.state.orderdate}
              dueDate={this.state.dueDate}
              jobDesc={this.state.jobDesc}
              id={this.state.id}
            >


            </Vendororderslideout>


          )}

          {showJobSlideout && (

            <JobCreations
              closeSlideOut={this.closeJobSlideout}
              jobid={this.state.jobid}
              orderid={this.state.orderid}
              CustomerPoNumber={this.state.CustomerPoNumber}
              poNumber={this.state.poNumber}
              productid={this.state.productid}
              partname={this.state.partname}
              partNo={this.state.partNo}
              qtyOrdered={this.state.qtyOrdered}

              itemNo={this.state.itemNo}
              selectedCustomer={this.state.selectedCustomer}
              orderdate={this.state.orderdate}
              dueDate={this.state.dueDate}
              jobDesc={this.state.jobDesc}
              id={this.state.orderdetailid}

            >
            </JobCreations>
          )}

          {(showReceivableslideout &&
            <Receivableslideout
              closeSlideOut={this.closeInvoiceSlideOut}
              orderid={this.state.orderid}
              orderIds={this.state.orderIds}
              products={this.state.products}
              invoiceId={this.state.invoiceId}
            >


            </Receivableslideout>
          )}


          {showNCRSlideout && (

            <NCRSlideout
              closeSlideOut={this.closeSlideOut}
              jobid={this.state.jobid}
              id={this.state.id}
              jobno={this.state.jobno}

            >
            </NCRSlideout>
          )}

          {(showShippingSlideout &&
            <ShippingSlideout
              closeSlideOut={this.closeSlideOut}
              orderid={this.state.orderid}
              jobids={this.state.jobIds}
              shipmentId={this.state.shipmentid}
            >


            </ShippingSlideout>
          )}

          {(showCustomerSlideout &&
            <Customerorderslideout
              closeSlideOut={this.closeSlideOut}
              orderid={this.state.orderid}
            >


            </Customerorderslideout>
          )}

        </Container>


      </>
    );
  }
}
