import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import _ from "lodash";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { ProductService } from "../../Common/Services/ProductService";
import { Roles } from "../../Common/Services/RolePermissionSetting";

import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import { UserService } from "../../Common/Services/UserService";
import { User } from "../../Common/Services/User";
export class UserSlideout extends React.Component<any, any> {
  private parentScroll: any;
  private singleSerch: any;
  private trDate: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userid: this.props.userid,

      roledefault: "",
      Role: [],
      defaultemp: "Select Employee",
      EmployeeType: [],

      defaultstatus: "Select Status",
      Status: [],

      UserName: "",

      tenantID: 0,

      user_UniqueID: 0,
      email: "",
      address: this.props.address,

      formData: {
        UserName: "",
        Date_of_hire:
          new Date().getMonth() +
          1 +
          "/" +
          new Date().getDate() +
          "/" +
          new Date().getFullYear().toString().substring(2, 4),
        TenantID: 0,
        FirstName: "",
        LastName: "",
        User_UniqueID: this.props.templateid,
        Status: "Active",
        Role: "",
        EmployeeType: "",
        EmpCode: "",
        Email: "",
        Phone1: "",
        Address: "",
        City: "",
        State: "",
        Zip: "",
      },
    };
  }
  componentDidMount() {
    let requestlist = { ...this.state.requestlist };
    this.getroledata(requestlist);
    this.getEmployeetype();
    this.getStatus();
  }

  getUsers = () => {
    let requestlist = { ...this.state.requestlist };
    requestlist.userid = this.props.templateid;
    UserService.GetUserDataByUserid(requestlist)
      .then(async (result: any | null) => {
        if (result != null) {
          let formData = { ...this.state.formData };
          formData.User_UniqueID = result.user_UniqueID;
          formData.FirstName = result.firstName;
       
  
          formData.TenantID = result.tenantID;
          formData.LastName = result.lastName;
          formData.Email = result.email;
          formData.UserName = result.userName;
          formData.Status = result.status;
          formData.Address = result.address;
          formData.Role = result.role;
          formData.City = result.city;
          formData.State = result.state;
          formData.Zip = result.zip;
          formData.EmployeeType = result.employeeType;
          formData.Date_of_hire = result.date_of_hire;
          formData.Phone1 = result.phone1;
          formData.EmpCode = result.empCode;
          formData.EmpId=result.empId;
          let roles = this.state.Role.filter((x) => x.id == result.role);
          let defaultRole = "Select Role";
          if (roles != null && roles.length > 0) defaultRole = roles[0].name;
          this.setState({ formData: formData, roledefault: defaultRole });
        } else {
          this.state.formData.Status = "Active";
          this.state.formData.EmployeeType = "Select Employee Type";
          this.setState({
            formData: this.state.formData,
            roledefault: "Select Role",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  getroledata = (requestlist: any) => {
    let Role: any[];
    let Typelist: any = [];
    Roles.GetRoleData(requestlist)
      .then(async (result: any[] | null) => {
        console.log(result);
        if (result != null) {
          let formData = { ...this.state.formData };
          result.forEach((element) => {
            let item: any = {};
            item.id = element.roleID;
            item.name = element.roleName;
            Typelist.push(item);
          });

          this.setState(
            {
              Role: Role,
              formData: formData,
            },
            () => {
              this.setState({ role: [] }, () => {
                this.getUsers();
                this.setState({ Role: Role });
              });
              console.log(Role);
            }
          );

          this.setState({ Role: Typelist }, () => {});
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  getEmployeetype = () => {
    let Typelist: any = [];
    let item: any = {};
    item.id = "HourlyWages";
    item.name = "HourlyWages";
    Typelist.push(item);
    let item1: any = {};
    item1.id = "Salaried";
    item1.name = "Salaried";
    Typelist.push(item1);

    this.setState({ EmployeeType: Typelist }, () => {});
  };

  getStatus = () => {
    let Typelist: any = [];
    let item: any = {};
    item.id = "Active";
    item.name = "Active";
    Typelist.push(item);
    let item1: any = {};
    item1.id = "Inactive";
    item1.name = "Inactive";
    Typelist.push(item1);

    this.setState({ Status: Typelist }, () => {});
  };

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  handleChange = (event: any, FieldType: string) => {
    let isStateChanged = true;
    let start = 0;
    let end = 0;

    let formData = { ...this.state.formData };

    if (FieldType === "FirstName") {
      formData.FirstName =
        event.target.value.trim().length > 0 ? event.target.value : "";

      // this.handleFirstNameChange;
    } else if (FieldType === "LastName") {
      formData.LastName =
        event.target.value.trim().length > 0 ? event.target.value : "";
      // this.handleLastNameChange;
    } else if (FieldType === "UserName") {
      /// this.generateUsername;
      formData.UserName =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "Date_of_hire") {
      //   formData.OrderDate = event.target.value.trim().length > 0 ? event.target.value : "";
      let today = new Date(event);
      let dateNew =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear().toString().substring(2, 4);
      let todaynew = new Date(dateNew);
      let dateNewt =
        todaynew.getMonth() +
        1 +
        "/" +
        todaynew.getDate() +
        "/" +
        todaynew.getFullYear().toString().substring(0, 4);
      formData.Date_of_hire = dateNewt;
      this.setState({ formData, isStateChanged });
    } else if (FieldType === "Email") {
      formData.Email =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "Status") {
      formData.Status = event.target.checked === true ? "Active" : "Inactive";
    } else if (FieldType === "Phone1") {
      formData.Phone1 =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "Address") {
      formData.Address =
        event.target.value.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "Role") {
      formData.Role =
        event.target.id.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "EmployeeType") {
      formData.EmployeeType =
        event.target.id.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "City") {
      formData.City =
        event.target.id.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "State") {
      formData.State =
        event.target.id.trim().length > 0 ? event.target.value : "";
    } else if (FieldType === "Zip") {
      formData.Zip =
        event.target.id.trim().length > 0 ? event.target.value : "";
    }
    else if (FieldType === "EmpCode") {
      formData.EmpCode =
        event.target.id.trim().length > 0 ? event.target.value : "";
    }
    formData.UserName = formData.LastName + "." + formData.FirstName;

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {});
  };

  SaveUser = () => {
    let formData = { ...this.state.formData };
    let request: any = {};
    let isFormValid = true;
    // debugger;
    // if(formData.Email==null && formData.Email=='')
    // {

    //   isFormValid = false;
    //   toast.error("Email should not blank.", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "OrderSlideout",
    //   });
    //   this.setState({ isSaveSpinner: false, isDisabled: false });
    //   return;
    // }

    if(isFormValid==true)
    {
    request = Object.assign(
      {},
      JSON.parse(JSON.stringify(this.state.formData))
    );
    this.setState({ isSaveSpinner: true });
    UserService.SaveUserData(request)
      .then(async (result: any | null) => {
        if (result !== null) {
if(result.empCode=="Duplicate")
{
  
    //this.closeSlideOut();
    toast.success("Emp Code already exists for another user", {
      position: toast.POSITION.BOTTOM_RIGHT,
      containerId: "OrderSlideout",
    });

  
}
else 
{

          this.closeSlideOut();
          toast.success("Record saved successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        }
       
        this.setState({ isSaveSpinner: false });
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSaveSpinner: false });
        reject();
      });
    }
  };

  handleSelectedItem = (control: any, id: any) => {
    let isStateChanged = true;
    if (control == "Role") {
      // this.setState({role : id});
      this.state.formData.Role = id;

      let roles = this.state.Role.filter((x) => x.id == id);
      let defaultRole = "Select Role";
      if (roles != null && roles.length > 0) defaultRole = roles[0].name;

      this.setState({ roledefault: defaultRole });
    } else if (control == "EmployeeType") {
      // this.setState({defaultemp : id});
      this.state.formData.EmployeeType = id;
    } else if (control == "Status") {
      //this.setState({defaultstatus : id});
      this.state.formData.Status = id;
    }
    this.setState({ isStateChanged: true });
  };

  render() {
    const {} = this.state;

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div
          className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid
              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
            >
                <div className="scroll">
              <div className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4">
                <div className="divheader">
                  <h5 className="my-title">
                    {/* {this.state.templateid == 0 || this.state.templateid == undefined  && <span>New User</span>} */}
                    {!this.state.formData.UserName && <span>New User</span>}
                    {this.state.formData.UserName && (
                      <span>{ this.state.formData.FirstName==undefined ? ""  : this.state.formData.FirstName.charAt(0).toUpperCase() + this.state.formData.FirstName.slice(1) } { this.state.formData.LastName==undefined ? "" : this.state.formData.LastName.charAt(0).toUpperCase() + this.state.formData.LastName.slice(1)  }</span>
                    )}
                  </h5>

                  <div className="header-popout ml-auto">
                    <div className="container text-center">
                      <div className="row align-items-start">
                        <div className="col"></div>

                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary cross"
                            onClick={this.closeSlideOut}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
                <div className="col-12 col-md-12 pb-3">
                  <div className="card h-100">
                    <div
                      className="card-header"
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      Personal Details
                    </div>
                    <div className="card-body">
                      <section className="pb-3">
                        <div className="row g-3">
                          <div className="col-12 col-md-3">
                            <Form.Group
                                controlId="customer"
                            > 
                            <div className="parent my-data">
                              <Form.Label className="font-semibold"> First Name</Form.Label>
                              <div className="single-select">
                                <Form.Control
                                  type="text"
                                  placeholder="First Name"
                                  value={this.state.formData.FirstName}
                                  onChange={(event: any) => {
                                    this.handleChange(event, "FirstName");
                                  }}
                                ></Form.Control>
                              </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-12 col-md-3">
                            <Form.Group
                             
                              controlId="customercode"
                            >
                          <div className="parent my-data">
                          <Form.Label className="font-semibold"> Last Name</Form.Label>
                              <div className="single-select">
                                <Form.Control
                                  type="text"
                                  placeholder="Last Name"
                                  value={this.state.formData.LastName}
                                  onChange={(event: any) => {
                                    this.handleChange(event, "LastName");
                                  }}
                                  // onKeyUp={this.handleLastNameChange}
                                ></Form.Control>
                              </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-12 col-md-3 pl-2" >
                            <Form.Group
                               
                              controlId="customercode"
                            >
                              <div className="parent my-data">
                              <Form.Label className="font-semibold"> User Name </Form.Label>
                              <div className="single-select">
                                <Form.Control
                                  readOnly
                                  type="text"
                                  placeholder="User Name"
                                  value={this.state.formData.UserName}
                                  // onChange={(event:any)=>{this.handleChange(event,"UserName"); }}
                                  //onBlur= {this.generateUsername}
                                ></Form.Control>
                              </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-12 col-md-2">

                          <div
                    className="form-check form-switch custom-switch"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                      style={{ fontSize: "18px", marginRight: "45%" ,fontWeight:'500'}}
                    >
                      Status
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      style={{ fontSize: "18px", width:'2.9em' }}
                      checked={this.state.formData.Status === "Active"}
                      onChange={(event: any) =>
                        this.handleChange(event, "Status")
                      }
                    />
                  </div>
</div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-12 pb-3">
                  <div className="card h-100">
                    <div
                      className="card-header"
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      Employment Details
                    </div>
                    <div className="card-body">
                      <section className="pb-3">
                        <div className="row g-3">
                          <div className="col-12 col-md-3">
                            <Form.Group
                              
                              controlId="email"
                            >
                            <div className="parent my-data">
                            <Form.Label className="font-semibold"> Role</Form.Label>
                              <div className="date-picker calender-picker"  style={{width:"80%"}}>
                                {this.state.roledefault != "" && (
                                  <SingleDropdownListWithoutSearch
                                    tabIndex={this.state.tabEnable}
                                    itemList={this.state.Role}
                                    defaultItem={this.state.formData.Role}
                                    defaultText={"Search Role"}
                                    defaultName={this.state.roledefault}
                                    controlID="1"
                                    id={"Role"}
                                    handleSelectedItem={this.handleSelectedItem.bind(
                                      this,
                                      "Role"
                                    )}
                                    value={this.state.formData.Role}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "Role")
                                    }
                                  />
                                )}
                              </div>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-12 col-md-3">
                            <Form.Group
                             
                              controlId="phone"
                            >
                            <div className="parent my-data">
                            <Form.Label className="font-semibold"> Employee Type </Form.Label>
                              <div className="single-select" style={{width:"80%"}}>
                                {this.state.formData.EmployeeType != "" && (
                                  <SingleDropdownListWithoutSearch
                                    tabIndex={this.state.tabEnable}
                                    itemList={this.state.EmployeeType}
                                    defaultItem={
                                      this.state.formData.EmployeeType
                                    }
                                    defaultText={"Search EmployeeType"}
                                    defaultName={
                                      this.state.formData.EmployeeType
                                    }
                                    controlID="1"
                                    id={"EmployeeType"}
                                    handleSelectedItem={this.handleSelectedItem.bind(
                                      this,
                                      "EmployeeType"
                                    )}
                                    value={this.state.formData.EmployeeType}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "EmployeeType")
                                    }
                                  />
                                )}
                              </div>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-12 col-md-3">
                            <Form.Group
                               
                              controlId="states"
                            >
                           <div className="parent my-data">
                           <Form.Label className="font-semibold"> Date of Hire </Form.Label>
                              <div className="single-select">
                                <DatePicker
                                  ref={this.trDate}
                                  id="txtOrderDate"
                                  tabIndex={this.state.tabEnable}
                                  className="form-control px-0 w-full text-start px-3"
                                  placeholderText={"MM/DD/YY"}
                                  dateFormat="MM/dd/yy"
                                  selected={
                                    this.state.formData.Date_of_hire === null ||
                                    this.state.formData.Date_of_hire ==
                                      undefined
                                      ? new Date()
                                      : this.state.formData.Date_of_hire === ""
                                      ? new Date()
                                      : new Date(
                                          this.state.formData.Date_of_hire
                                        )
                                  }
                                  onChange={(event: any) =>
                                    this.handleChange(event, "Date_of_hire")
                                  }
                                  // onKeyDown={(e: any) =>
                                  //   this.onTAB("txtOrderDate-1", 0, e)
                                  // }
                                  // onClick={(e: any) =>
                                  //   this.onTAB("txtOrderDate-1", 0, e)
                                  // }
                                  autoComplete="off"
                                  // disabled={isBedgeProcessing && true}
                                />
                              </div>
                              </div>
                            </Form.Group>
                          </div>

                          <div className="col-12 col-md-3">
                          <Form.Group
                              className="rm-bottom-margin"
                              controlId="customer"
                            >
                             <div className="parent my-data">
                             <Form.Label className="font-semibold">Employee Code</Form.Label>
                              <div className="single-select">
                                <Form.Control
                                  type="text"
                                  placeholder="Employee Code"
                                  value={this.state.formData.EmpCode}
                                  onChange={(event: any) => {
                                    this.handleChange(event, "EmpCode");
                                  }}
                                ></Form.Control>
                              </div>
                              </div>
                            </Form.Group>
                          </div>

                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                <div className="row g-3 pb-3">
                  <div className="col-12 col-md-6">
                    <div className="card h-max-100">
                      <div
                        className="card-header"
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        Contact Details
                      </div>
                      <div className="card-body">
                        <section>
                          <div className="row g-3">
                            <div className="col-md-12">
                              <Form.Group
                                
                                controlId="OtherCharge"
                              >
                               <div className="parent my-data">
                               <Form.Label className="font-semibold"> Phone</Form.Label>
                                <div className="single-select">
                                  <Form.Control
                                    type="text"
                                    placeholder="Phone"
                                    value={this.state.formData.Phone1}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "Phone1")
                                    }
                                  ></Form.Control>
                                </div>
                                </div>
                              </Form.Group>
                            </div>

                            <div className="col-md-12">
                              <Form.Group
                               
                                controlId="apartment"
                              >
                                <div className="parent my-data">
                                <Form.Label className="font-semibold"> Email</Form.Label>
                                <div className="date-picker calender-picker">
                                  <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    value={this.state.formData.Email}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "Email")
                                    }
                                  ></Form.Control>
                                </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 pb-3">
                    <div className="card h-max-100">
                      <div
                        className="card-header"
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        Address
                      </div>
                      <div className="card-body">
                        <section>
                          <div className="row g-3 pb-3">
                            <div className="col-md-12">
                              <Form.Group
                                className="rm-bottom-margin"
                                controlId="Country"
                              >
                               <div className="parent my-data">
                               <Form.Label className="font-semibold"> Address</Form.Label>
                                <div className="single-select">
                                  <Form.Control
                                    type="text"
                                    placeholder="Address"
                                    value={this.state.formData.Address}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "Address")
                                    }
                                  ></Form.Control>
                                </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <Form.Group
                                className="rm-bottom-margin"
                                controlId="City"
                              >
                                <div className="parent my-data">
                                <Form.Label className="font-semibold"> City</Form.Label>
                                <div className="single-select">
                                  <Form.Control
                                    type="text"
                                    placeholder="City"
                                    value={this.state.formData.City}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "City")
                                    }
                                  ></Form.Control>
                                </div>
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group
                                className="rm-bottom-margin"
                                controlId="State"
                              >
                               <div className="parent my-data">
                               <Form.Label className="font-semibold">State</Form.Label>
                                <div className="single-select">
                                  <Form.Control
                                    type="text"
                                    placeholder="State"
                                    value={this.state.formData.State}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "State")
                                    }
                                  ></Form.Control>
                                </div>
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group
                                className="rm-bottom-margin"
                                controlId="Country"
                              >
                             <div className="parent my-data">
                             <Form.Label className="font-semibold"> Zip</Form.Label>
                                <div className="single-select">
                                  <Form.Control
                                    type="text"
                                    placeholder="Zip"
                                    value={this.state.formData.Zip}
                                    onChange={(event: any) =>
                                      this.handleChange(event, "Zip")
                                    }
                                  ></Form.Control>
                                </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
               
              
              </div>
              {this.state.isStateChanged && (
                  <div
                    className={"footer-section viewer-panel"}
                    style={{
                      minWidth: "75%",
                      width: "992px",
                      maxWidth: "100%",
                    }}
                  >
                    <div>
                      <div className="d-flex align-items-center justify-content-end height-72">
                        <div className="d-flex align-items-center">
                          <Button
                            variant="primary"
                            type="button"
                            className="space btn btn-primary btn-discard"
                            onClick={() => this.closeSlideOut()}
                          >
                            Discard
                          </Button>
                          <Button
                            id="btnSave"
                            variant="primary"
                            type="button"
                            onClick={() => this.SaveUser()}
                            // disabled={this.state.formData.templatename === "" ? true : this.state.isDisabled}
                            //onKeyDown={(e: any) => this.onTAB('btnSave-1', 0, e)}
                          >
                            {"Save"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </Container>
          </div>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}
        </div>
      </section>
    );
  }
}
