import React from 'react';
import { toast, ToastContainer } from "react-toastify";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AutosizeInput from "react-input-autosize";
import moment from "moment";
import {
  Container, Form, Button, Dropdown,
  Spinner,
  OverlayTrigger, Tooltip

} from "react-bootstrap";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SingleSearchDropdownList } from "../Common/Components/SingleSearchDropdownList";
import { SingleSearchDropdownListBootrap } from "../Common/Components/SingleSearchDropdownListBootrap";
import { Utils } from "../Common/Utilis";
import { ConfirmationModal } from "../Common/Components/ConfirmationModal";
import { SingleDropdownListWithoutSearch } from "../Common/Components/SingleDropdownListWithoutSearch";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { CommentBox } from "../Common/Components/CommentBox";
import { PartTemplateService } from "../Common/Services/PartTemplateService";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { User } from "../Common/Services/User";
import { faArrowUp, faListSquares } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JobServices } from "../Common/Services/JobServices";
import { Vendororderslideout } from './Vendororderslideout';
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
import { MdDesignServices } from "react-icons/md";
import { FaLaptopHouse } from 'react-icons/fa';
import { confirmAlert } from "react-confirm-alert";

const { SearchBar } = Search;
export class JobCreations extends React.Component<any, any> {
  private parentScroll: any;
  private scrollvdRef: any;
  private ChildComment: any;
  private child: any;
  private input: any;
  
  constructor(props: any) {
    super(props);
    console.log("props",props);
    this.parentScroll = React.createRef();

    this.scrollvdRef = React.createRef();
    this.GetItemlist = this.GetItemlist.bind(this);
    this.getassigntodata = this.getassigntodata.bind(this);
    this.getjobdata = this.getjobdata.bind(this);
    //this.GetTemplateData =this.GetTemplateData.bind(this);
    this.ChildComment = React.createRef();
    this.state = {
      //tname: "",
      ischanged: 0,
      isdataloaded:0,
      isLastJob: false,
      runningInTracker: false,
      processVendorPOCreated: false,
      materialVendorPOCreated: false,
      trackerProcessIds: [],
      trackerStatus: '',
      reworkCount: 0,
      ispopupshow: false,
      showProcessPopup:false,
      saveTemplate: false,
      jobTrackerId: 0,
      templatename: '',
      vendororderid: 0,
      vendorPoNo:'',
      Received:'',
      vendorordertype: 'Material',
      jobprocessid: 1,
      jobassignedid: 0,
      assignedto: "",
      jobprocess: "",
      orderid: this.props.orderid,
      CustomerPoNumber: this.props.CustomerPoNumber,
      id: this.props.id,
      productid: this.props.productid,
      partname: this.props.partname,
      partNo: this.props.partNo,
      qtyOrdered: this.props.qtyOrdered,
      itemNo: this.props.itemNo,
      poNumber: this.props.poNumber,
      dueDate: this.props.dueDate,
      jobDesc: this.props.jobDesc,
      selectedCustomer: this.props.selectedCustomer,
      orderdate: this.props.orderdate,
      jobid:     this.props.jobid ==undefined?0: this.props.jobid,
      jobNo:"",
      manualTracking:true,
      templatetype: [],
      processdataList: [],
      assigneddatatoList: [],
      assignedtojob: [],
      itemListdatam: [],
      inventorydata: [],
      defaultitemname: [],
      jobMaster: [],
      itemdetailsdata: [
        {
          defaultitemname: [],
          itemNo: "1",
          id: 0,
          itemname: "MS-" + this.props.partNo,
          glcode: "",
          description: "",
          itemid: 0,
          stockqty: 0,
          qty: 0,
          orderqty: 0,
          unitPrice: Utils.currencyFormat(0),
          unit: "EA",
        },

      ],
      itemdetailsdatanew: [
        {
          defaultitemname: [],
          itemNo: "1",
          id: 0,
          glcode: "",
          itemname: "",
          description: "",
          itemid: 0,
          stockqty: 0,
          qty: 0,
          orderqty: 0,
          unitPrice: Utils.currencyFormat(0),
          unit: "EA",
        },

      ],
      itemColumns: this.itemColumns,
      columns: this.columns,
      pcolumns: this.pcolumns,
      partcolumns: this.partcolumns,
      jobcolumns:this.jobcolumns,
      templateColumns: this.templateColumns,
      searcResult: [],
      templatedefault: "Custom",
      templatelistitem: [],

      orderData: [
        {
          defaultPartname: [],
          id: this.props.id,
          orderID: this.props.orderid,
          itemNo: this.props.itemNo,
          partname: this.props.partname,
          partNo: this.props.partNo,
          dueDate: this.props.dueDate,
          jobDesc: this.props.jobDesc,
          qtyOrdered: this.props.qtyOrdered,
          tenantid: 0,
          productid: this.props.productid,
        },

      ],
      formData: {
        templateid: 0,
        tname: "",
        templatename: '',
        jobdetails: [],
        itemdata: [],
        jobassignedId: 0,
        processid: 0,
        orderid: this.props.id,
        jobNo: "JO#"+this.props.poNumber + "-" + this.props.itemNo + this.props.rNo,
        partid: this.props.productid,
        jobid: this.props.jobid,

      },
      jobdetailsdata: [
        {


          processList:this.props.processlist==undefined? [] : this.props.processlist,
          processid: 0,
          processName: "",


         srno: 1, type: 'Work', estimatehour: '',

          id: 0,
          jobid: 0,

    

          tdescription: "",

          tenantid: 0

        }

      ],
      processData:[],
      ppartData:[],
      selectedTemplateId:0,
      selectedTemplateName:'',
      selectedJobNo:'',
      templateType:'Template',

      newRow: [
        {

          processList:this.props.processlist==undefined? [] : this.props.processlist,
          processid: this.props.processlist==undefined? 0 : this.props.processlist[0].processid,
          processName: this.props.processlist==undefined? "" : this.props.processlist[0].name,

     
         srno: 1, type: 'Work', estimatehour: '',

          id: 0,
          jobid: 0,


          tdescription: "",

          tenantid: 0

        }

      ],
    };
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
 
  private iconFormatter = (column, colIndex) => {
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Select Process
      </Tooltip>
    );
    return (
        <div>Process 
          <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
          <button  onClick={(e)=>{ this.setState({ showProcessPopup: true });}} className='btnheight' disabled={this.state.runningInTracker?true:false}  >
            
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
  <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg> */}
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>
            </button>
            </OverlayTrigger>
            </div> 
    );
};

  private templateColumns = [

    {
      dataField: 'srno',
      text: 'Step No.',
      headerStyle: { width: '50px' },
      formatter: (cell: any, row: any, rowIndex: any) => {

        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      },
    },
    {
      dataField: "isSelected",
      text: "",
      classes: "expanding-bar",
      headerStyle: { width: '10px' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center txt-bold">

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="toolp">Click to move up this step</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                size="xs"
                onClick={(e) => this.handleRowOrdering(e, row, 'up')}
              />
            </OverlayTrigger>
          </div>
        );
      },
    },

    {
      dataField: "isSelected",
      text: "",
      classes: "expanding-bar",
      headerStyle: { width: '10px' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center txt-bold">

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="toolp">Click to move down this step</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faArrowDown}
                size="xs"
                onClick={(e) => this.handleRowOrdering(e, row, 'down')}
              />
            </OverlayTrigger>
          </div>
        );
      },
    },

    {
      dataField: 'type',
      text: 'Type',
      headerStyle: { width: '150px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txttype" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Type" value={row.type}
              onChange={(e) => this.updateState(e, rowIndex, "type", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },
    },
    {
      dataField: 'processName',
      text: 'Process',
      headerStyle: { width: '200px' },
      //headerFormatter: this.iconFormatter,
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtprocess" + rowIndex + "txt";
        let disable = this.state.trackerProcessIds != null && this.state.trackerProcessIds.filter((x) => x == row.id).length > 0 ? row.manualTracking==true? false :  true : false;
        // this.state.trackerProcessIds != null && this.state.trackerProcessIds.filter((x)=>x == row.processid).length > 0;
        return (
          <div style={{ opacity: disable ? 0.5 : 1, pointerEvents: disable ? 'none' : 'auto' }}>
            {/* <Form.Control tabIndex={this.state.tabEnable}
                        id={id}
                        type="text" placeholder="Enter Process" value={row.process}
                       // onChange={(e) => this.updateState(e, rowIndex, "partNo", id)}
                        autoComplete='off'
                        disabled={disable}
                        title=""
                        maxLength={500}
                    /> */}


            <SingleSearchDropdownListBootrap
              tabIndex={this.state.tabEnable}
              itemList={row.processList}
              handleSelectedItem={this.handleSelectedItem.bind(row, 2 + '_' + rowIndex)}
              defaultItem={row.processName}
              defaultText={"Search Process"}
              defaultName={row.processName}

              controlID="1"
              id={"ddlprocess-" + rowIndex + "ddl"}
            //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}

            />
          </div>
        );



      },

    },
    {
      dataField: 'jDescription',
      text: 'Description',
      headerStyle: { width: '600px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtdescription" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Description" value={row.jDescription}
              onChange={(e) => this.updateState(e, rowIndex, "description", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },

    },

    {
      dataField: 'Attachment',
      text: 'Delete',
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];

        if (this.state.jobdetailsdata.length === 1 || rowIndex == 0 || (this.state.trackerProcessIds != null && this.state.trackerProcessIds.filter((x) => x == row.id).length > 0)) {
          return (
            <div></div>
          );
        }


        else {
          return (
            <Dropdown className="more-action confirmation-modal"  tabIndex={this.state.tabDisable}>
              <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    },

  ];


  private itemColumns = [
    //{
    //   dataField: "itemNo",
    //   text: "#",
    // },

    {
      dataField: "itemname",
      text: "Item Name",
      headerClasses: "coa",

      headerStyle: { width: "500px" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

      formatter: (cell: any, row: any, rowIndex: any) => {
        let btnId = "itemname" + rowIndex + "btn";
        let id = "itemname" + rowIndex + "ddl";
        const defaultitemname = row.selectitem;
        let disable = this.state.vendorPoNo!=null && this.state.vendorPoNo!="" ? true : false;
        return (
          // <div className="disabled-area my-class" onClick={(e)=>this.onclickevent(e)}>
          <div
          //  onClick={() => $('#tbltemplate td.reset-expansion-style').css('overflow', 'inherit')}
          >
            <div className="more-action single-select-image dropdown coa-select">

              {<Typeahead
disabled={disable}
                id={"itemname" + rowIndex + "ddl"}
                inputProps={{
                  className: "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl",
                  title: defaultitemname,
                  onClick: () => this.hoverOutEllipsis()
                }}
                options={
                  //             this.state.inventorydata.filter(
                  //   (x) => x.itemname !== ""
                  // )
                  this.state.inventorydata.filter((ele, ind) => ind === this.state.inventorydata.findIndex(elem => elem.itemname === ele.itemname && ele.itemname !== ""))

                }

                placeholder={"Enter item Name"}

                // labelKey="label"

                renderMenu={(results: any, menuProps) => (
                  <Menu style={{ zIndex: "-1" }}  {...menuProps}>
                    {results.map((result: any, index: any) => (
                      <>
                        {this.state.inventorydata.length > 0}
                        {result.label !== "" && (
                          <MenuItem
                            option={result}
                            position={index}
                            onWheel={() => this.hoverOutEllipsis()}
                          >
                            <EllipsisWithTooltip placement="bottom">
                              {result.label}

                            </EllipsisWithTooltip>
                          </MenuItem>
                        )}

                        {result.itemname === "" && (
                          <MenuItem option={result} position={index}></MenuItem>
                        )}
                      </>
                    ))}
                  </Menu>
                )}

                onChange={this.handleSelectedItemInv.bind(
                  this,
                  1 + "_" + rowIndex + "_" + defaultitemname
                )}
                selected={row.defaultitemname}
                allowNew
                onBlur={this.handleOutsideClick.bind(this, rowIndex)}
                onKeyDown={this.onTAB.bind(this, "itemname-" + rowIndex.toString() + "ddl", rowIndex)}

              />}

              <button id={btnId} style={{ display: "none", width: "100%" }}
                onClick={() => { this.handleCOAFocus(btnId, id, "rbt-input-main form-control rbt-input itemname" + rowIndex + "ddl", rowIndex); }}
                onFocus={() => { this.handleCOAFocus(btnId, id, "rbt-input-main form-control rbt-input itemname" + rowIndex + "ddl", rowIndex); }} >
                {row.partname === "" && (
                  <EllipsisWithTooltip placement="bottom">{"Enter item name"}</EllipsisWithTooltip>
                )}
                {row.partname !== "" && (
                  <EllipsisWithTooltip placement="bottom">{defaultitemname}</EllipsisWithTooltip>
                )}
              </button>
            </div>
          </div>
        );
      },
    },

    ,
    {
      dataField: "glcode",
      text: "MS Code",
      headerStyle: { width: "150px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtglcode" + rowIndex + "txt";
        let disable = this.state.vendorPoNo!=null && this.state.vendorPoNo!="" ? true : false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder="Enter MS Code"
              value={row.glcode}
              onChange={(e) => this.updateState(e, rowIndex, "glcode", id)}
              autoComplete="off"
              onKeyDown={(e: any) => this.onTAB("glcode", rowIndex, e)}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },



    {
      dataField: "unit",
      text: "Unit",
      headerStyle: { width: "70px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtunit" + rowIndex + "txt";
        let disable = this.state.vendorPoNo!=null && this.state.vendorPoNo!="" ? true : false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              //placeholder="Enter Qty"
              value={row.unit}
              onChange={(e) => this.updateState(e, rowIndex, "unit", id)}
              autoComplete="off"

              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },


    {
      dataField: "stockqty",
      text: "Stock Qty",
      headerStyle: { width: "70px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtstockqty" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            {row.stockqty}
          </div>
        );
      },
    },

    {
      dataField: "qty",
      text: "Req Qty",
      headerStyle: { width: "70px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtreqqty" + rowIndex + "txt";
        let disable = this.state.vendorPoNo!=null && this.state.vendorPoNo!="" ? true : false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.qty}
              onChange={(e) => this.updateState(e, rowIndex, "reqqty", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },


    {
      dataField: "orderqty",
      text: "Order Qty",
      headerStyle: { width: "70px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtqty" + rowIndex + "txt";
        let disable = this.state.vendorPoNo!=null && this.state.vendorPoNo!="" ? true : false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              //   placeholder="Enter Qty"
              value={row.orderqty}
              onChange={(e) => this.updateState(e, rowIndex, "orderqty", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },
    {
      dataField: "unitPrice",
      text: "Unit Price",
      headerStyle: { width: "70px" },
      headerClasses: 'amount',
      classes: 'amount',
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtunitPrice" + rowIndex + "txt";
        let disable = this.state.vendorPoNo!=null && this.state.vendorPoNo!="" ? true : false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              //  placeholder="Enter unit Price"
              value={row.unitPrice}
              onChange={(e) => this.updateState(e, rowIndex, "unitPrice", id)}
              onBlur={(e: any) => this.amountBlur(e, rowIndex, "unitPrice")}
              onFocus={(e: any) => this.onAmountFocus(e, rowIndex, id, "unitPrice")}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },
    {
      dataField: 'Attachment',
      text: '',
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let itemDetailConfirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        if (this.state.itemdetailsdata.length === 1  || true) {
          return (
            <div></div>
          );
        }
        else {
          return (
            <Dropdown className="more-action confirmation-modal"  tabIndex={this.state.tabDisable}>
              <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={itemDetailConfirmModalContent}
                  ishandleDeletePosition={this.handleItemDetailDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    },

  ];

  private columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
   
    {
      dataField: "name",
      text: "Template Name",
      searchable:true,
      
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
         <div>
            {row.name}
            </div>
        );
      },
    },
  ];
  
  private pcolumns = [
   
   
    {
      dataField: "processName",
      text: "Steps",
      headerStyle: { width: "100px" },
      
    },
    {
      dataField: "jDescription",
      text: "Description",
      headerStyle: { width: "500px" },
    },
  ];

  private partcolumns = [
    {
      dataField: "jobid",
      text: "id",
      hidden: true,
      headerStyle: { width: "100px" },
       
    },
   
    {
      dataField: "partno",
      text: "Part No",
      hidden: false,
      headerStyle: { width: "100px" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
         <div>
            {row.partno}
            </div>
        );
      },
    },
    {
      dataField: "partName",
      text: "Part Name",
      headerStyle: { width: "500px" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
         <div>
            {row.partName}
            </div>
        );
      },
    },
  ];

  private jobcolumns = [
    {
      dataField: "jobid",
      text: "id",
      hidden: true,
      headerStyle: { width: "100px" },
      
    },
    {
      dataField: "jobNo",
      text: "Job #",
      hidden: false,
      headerStyle: { width: "100px" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
         <div>
            {row.jobNo}
            </div>
        );
      },
    },
   
    {
      dataField: "partno",
      text: "Part No",
      hidden: false,
      headerStyle: { width: "100px" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
         <div>
            {row.partno}
            </div>
        );
      },
    },
    {
      dataField: "partName",
      text: "Part Name",
      headerStyle: { width: "500px" },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          
         <div>
            {row.partName}
            </div>
        );
      },
    },
  ];

  handleTemplateOnSelect = (row, isSelect) => {  
    let templatelistitem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.templatelistitem))
    );

    templatelistitem.forEach(element => {
        if(element.id == row.id)
        {
          element.selected = isSelect;
        }
        else         
          element.selected = false;
    });

  
    this.setState({ searcResult: templatelistitem,selectedTemplateId:row.id, selectedTemplateName: row.name,isSpinner:false,templateType:'Template' },()=>{
   

     this.getProcessDataforPopup(row.id);
    });

  };

  handlePartOnSelect = (row, isSelect) => {  
    let partData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.partData))
    );

    partData.forEach(element => {
        if(element.jobid == row.jobid)
        {
          element.selected = isSelect;
        }
        else         
          element.selected = false;
    });

    // let selectTemplateId = 0, selectTemplateName = '';
    // if(this.state.templatelistitem.length > 0 )
    // {
    //     selectTemplateId = this.state.templatelistitem[0].id;
    //     selectTemplateName = this.state.templatelistitem[0].name;
    // }

    this.setState({ partData: partData,selectedTemplateId:row.jobid, selectedTemplateName: row.partno,isPopupSpinner:true,templateType:'Part',selectedJobNo: row.partno + ' ' + row.partName},()=>{
   
     this.getProcessDatabyPartJobForPopup(row.jobid);

    });

  };


  
  handlejobOnSelect = (row, isSelect) => {  
    let partData = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.partData))
    );

    partData.forEach(element => {
        if(element.jobid == row.jobid)
        {
          element.selected = isSelect;
        }
        else         
          element.selected = false;
    });

    // let selectTemplateId = 0, selectTemplateName = '';
    // if(this.state.templatelistitem.length > 0 )
    // {
    //     selectTemplateId = this.state.templatelistitem[0].id;
    //     selectTemplateName = this.state.templatelistitem[0].name;
    // }

    this.setState({ partData: partData,selectedTemplateId:row.jobid, selectedTemplateName: row.jobNo,isPopupSpinner:true,templateType:'Part',selectedJobNo: row.jobNo},()=>{
   
     this.getProcessDatabyPartJobForPopup(row.jobid);

    });

  };
  handleCOAFocus = (btnID, txtID, ddlID, rowIndex) => {
    
    let itemdetailsdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.itemdetailsdata))
    );


    this.setState({ itemdetailsdata: [] }, () => this.setState({ itemdetailsdata }, () => {
      
      $("input[class^='" + ddlID + "']").focus();
    }));
    // $("#tblSplit").find("button[id^='" + btnID + "']").css("display", 'none');
    // $("#tblSplit").find("div[id^='" + txtID + "']").css("display", 'block');

  };

  handleRowOrdering = (e: any, row: any, type: any) => {

    let jobdetailsdata = this.state.jobdetailsdata;
    
    let nextOrderNo = type == 'down' ? row.processOrder + 1 : row.processOrder - 1;
    let currentRow = jobdetailsdata.filter((x) => x.processOrder == row.processOrder);
    let nextRow = jobdetailsdata.filter((x) => x.processOrder == nextOrderNo);

    if (nextRow != null && nextRow.length > 0) {

      nextRow[0].processOrder = row.processOrder;
      currentRow[0].processOrder = nextOrderNo;


      jobdetailsdata = jobdetailsdata.sort((a, b) => a.processOrder - b.processOrder);

      this.setState({ jobdetailsdata: jobdetailsdata }, () => {
        this.setState({ jobdetailsdata: jobdetailsdata });
      });

      this.ReorderProcess();
    }

  };

  componentDidMount() {
    this.getjobdata();


   
  }

  Getjoblist = (requestlist: any) => {

    let saveRequest: any = {};
    saveRequest.orderid = this.state.formData.orderid;
    saveRequest.partid = this.state.formData.partid;
    saveRequest.jobid = this.state.formData.jobid;

    let request: any = {};
    let itemdetailsdata: any = [];

    let jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
    debugger;
    JobServices.GetJobData(saveRequest)
      .then(async (resultinv: any | null) => {
        debugger;
        if (resultinv !== null && resultinv.jobmaster.length > 0) {
          
          let jobNoList = resultinv.jobmaster.map(obj => {
            return {
              id: obj.jobid,
              name: obj.jobNo,
              reworkCount: obj.reworkCount
            };
          });

          this.setState({
            isLastJob: resultinv.isLastJob,
            runningInTracker: resultinv.runningInTracker,
            trackerStatus: resultinv.jobmaster[0].trackerStatus == null ? '' : resultinv.jobmaster[0].trackerStatus,
            reworkCount: resultinv.jobmaster[0].reworkCount,
            partname: resultinv.jobmaster[0].partName,
            manualTracking: resultinv.jobmaster[0].manualTracking == null ? false:resultinv.jobmaster[0].manualTracking,
            partNo: resultinv.jobmaster[0].partNo,
            jobNo:resultinv.jobmaster[0].jobNo,
            qtyOrdered: resultinv.jobmaster[0].qtyOrdered,
            orderdate: resultinv.jobmaster[0].orderDate,
            selectedCustomer: resultinv.jobmaster[0].customername,
            jobid: resultinv.jobmaster[0].jobid, jobTrackerId: resultinv.jobtrackerid,
            vendororderid: resultinv.vendorOrderId, 
            vendorPoNo:resultinv.vendorPoNo, 
            Received:resultinv.received, 
            assignedto: resultinv.jobmaster[0].assignedTo,
            jobassignedid: resultinv.jobmaster[0].jobassignedId,
            jobprocess: resultinv.jobmaster[0].process, 
            jobprocessid: resultinv.jobmaster==null ||  resultinv.jobmaster.length > 0? resultinv.defaultprocessId : resultinv.jobmaster[0].processid,
            processVendorPOCreated: resultinv.jobmaster[0].processVendorPOCreated,
            trackerProcessIds: resultinv.trackerProcessIds,
            materialVendorPOCreated: resultinv.jobmaster[0].materialVendorPOCreated,
            isdataloaded: 1, jobMaster: jobNoList
          });


          if(resultinv.material.length==0)
          {
            debugger;
            resultinv.material=  this.state.itemdetailsdata;

          }
       
          resultinv.material.forEach(element => {

            let defaultitemname: any = [];
            let objPartname: any = {};

            objPartname.itemname = element.itemname;
            objPartname.label = element.itemname;
            objPartname.id = element.itemid;



            objPartname.tenantid = 0;


            defaultitemname.push(objPartname);
            element.defaultPartname = defaultitemname;

            let matr: any = {};

            matr.id = element.id,
              matr.itemname = element.itemname,
              matr.glcode = element.glcode,
              matr.itemid = element.itemid,
              matr.stockqty = element.stockqty,
              matr.qty = element.reqqty,
              matr.orderqty = element.orderqty,
              matr.unitPrice = Utils.currencyFormat(element.unitprice),
              matr.unit = element.unit,
              matr.defaultitemname = element.defaultPartname,
              itemdetailsdata.push(matr);
          });
       
          let jobdetailsdata: any = [];
          let newRow = this.state.newRow;
          let orderArray = resultinv.jobdetails;

let isloaded=orderArray.length ;
          
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {
              

              let el: any = {};
              el.id = element.id;
              el.processList = this.state.processdataList;
              el.srno = idx + 1;
              el.processName = element.processname;
              el.processid = element.processid;
              el.jDescription = element.jdescription;
              el.id = element.id;
              el.type = element.type;
              el.processOrder = idx + 1; //element.processOrder;

              jobdetailsdata.push(el);
              //          let requestlist = { ...this.state.requestlist };
              //            this.GetTempateType(requestlist);
            });
            newRow.forEach(element => {
              element.processList = this.state.processdataList;
              // element.processName = filterRow[0].name;
              // element.processid = orderArray[idx].processid;

            });
          }
          else {
            if(isloaded==0)
            {
            var prid= this.state.templatelistitem.filter(x => x.name === "Custom");
            this.getTemplateData(prid[0].id);
            }
            else
            {
           
            this.getTemplateData(this.state.jobprocessid);
            }
          
          }


          this.setState({
            itemdetailsdata: itemdetailsdata,
            jobdetailsdata: jobdetailsdata,
          },
            () => {
              this.setState({ jobdetailsdata: [], }, () => {
                this.setState({
                  jobdetailsdata: jobdetailsdata,

                });

              });
             
            });

          let processid = resultinv.jobmaster[0].processid;





          this.setState({ itemdetailsdata: [], jobdetailsdata: [], }, () => {
            this.setState({ itemdetailsdata: itemdetailsdata, jobdetailsdata: jobdetailsdata });
            //this.getjobdata(processid);


          });


        };
        
        let assignedTo = this.state.assignedto;
        let jobprocess = this.state.jobprocess;
        let jobprocessid=this.state.jobprocessid;
        // if(this.state.assignedto === '')
        //    assignedTo ='Unassigned';
        if (this.state.jobprocess === '')
        {
          jobprocess = 'Custom';
          var prid= this.state.templatelistitem.filter(x => x.name === "Custom");
          jobprocessid=prid[0].id;
          //itemdetailsdata: this.state.itemdetailsdata;

          if(resultinv.material.length==0)
            {
              debugger;
              resultinv.material=  this.state.itemdetailsdata;
  
            }
         
            resultinv.material.forEach(element => {
  
              let defaultitemname: any = [];
              let objPartname: any = {};
  
              objPartname.itemname = element.itemname;
              objPartname.label = element.itemname;
              objPartname.id = element.itemid;
  
  
  
              objPartname.tenantid = 0;
  
  
              defaultitemname.push(objPartname);
              element.defaultPartname = defaultitemname;
  
              let matr: any = {};
  
              matr.id = element.id,
                matr.itemname = element.itemname,
                matr.glcode = element.glcode,
                matr.itemid = element.itemid,
                matr.stockqty = element.stockqty,
                matr.qty = element.reqqty,
                matr.orderqty = element.orderqty,
                matr.unitPrice = Utils.currencyFormat(element.unitprice),
                matr.unit = element.unit,
                matr.defaultitemname = element.defaultPartname,
                itemdetailsdata.push(matr);
            });
        }

        this.setState({jobprocessid:jobprocessid, isdataloaded: 1, jobprocess: jobprocess, assignedto: assignedTo,
          itemdetailsdata: itemdetailsdata,
          
         });
        resolve();

      })


      .catch((error) => {

        reject();
      });
  };

  ReorderProcess = () => {

    let request = { ...this.state.requestlist };
    let jobdetailsdata = this.state.jobdetailsdata;
    jobdetailsdata.forEach((element, idx) => {
      //jobdetailsdata.forEach(element => {
      let item: any = {};
      item.srno = idx + 1;
      item.id = element.id;
      item.jobid = this.state.jobid;
      item.processid = element.processid;
      item.processname = element.processName;
      item.type = element.type;
      item.jdescription = element.tdescription;
      item.processOrder = element.processOrder;
      item.assignedid = element.assignedid == null ? this.state.jobassignedid : element.assignedid;
      item.tenantid = 0;
      this.state.formData.jobdetails.push(item);
    });


    // request = Object.assign([], JSON.parse(JSON.stringify(this.state.formData.jobdetails)));

    // JobServices.ReorderProcess(request)
    // .then(async (result: any | null) => {

    //   if (result !== null) {
    //   console.log('sss');
    //   }

    //   resolve();

    // })
    // .catch((error) => {
    //   reject();
    // });

  };

  GetJobDataByIdOnly = () => {

    let request: any = {};
    request.jobid = this.state.formData.jobid;

    // request: any = {};
    let itemdetailsdata: any = [];

    let jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));

    JobServices.GetJobDataByIdOnly(request)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null && resultinv.jobmaster.length > 0) {
          /* let jobNoList = resultinv.jobmaster.map(obj => {
             return {
                 id: obj.jobid,
                 name: obj.jobNo,
                 reworkCount: obj.reworkCount
             };
         }); */
          this.setState({
            trackerStatus: resultinv.jobmaster[0].trackerStatus == null ? '' : resultinv.jobmaster[0].trackerStatus,
            reworkCount: resultinv.jobmaster[0].reworkCount,
            partname: resultinv.jobmaster[0].partName,
            partNo: resultinv.jobmaster[0].partNo,
            qtyOrdered: resultinv.jobmaster[0].qtyOrdered,
            manualTracking: resultinv.jobmaster[0].manualTracking == null ? false:resultinv.jobmaster[0].manualTracking,

            orderdate: resultinv.jobmaster[0].orderDate,
            selectedCustomer: resultinv.jobmaster[0].customername,
            jobid: resultinv.jobmaster[0].jobid, jobTrackerId: resultinv.jobtrackerid,
            vendororderid: resultinv.vendorOrderId, assignedto: resultinv.jobmaster[0].assignedTo,
            jobassignedid: resultinv.jobmaster[0].jobassignedId,
            jobprocess: resultinv.jobmaster[0].process, jobprocessid: resultinv.jobmaster[0].processid,
            processVendorPOCreated: resultinv.jobmaster[0].processVendorPOCreated,
            trackerProcessIds: resultinv.trackerProcessIds,
            materialVendorPOCreated: resultinv.jobmaster[0].materialVendorPOCreated,
            isdataloaded: 1
          });

          resultinv.material.forEach(element => {

            let defaultitemname: any = [];
            let objPartname: any = {};

            objPartname.itemname = element.itemname;
            objPartname.label = element.itemname;
            objPartname.id = element.itemid;



            objPartname.tenantid = 0;


            defaultitemname.push(objPartname);
            element.defaultPartname = defaultitemname;

            let matr: any = {};

            matr.id = element.id,
              matr.itemname = element.itemname,
              matr.glcode = element.glcode,
              matr.itemid = element.itemid,
              matr.stockqty = element.stockqty,
              matr.qty = element.reqqty,
              matr.orderqty = element.orderqty,
              matr.unitPrice = element.unitprice,
              matr.unit = element.unit,
              matr.defaultitemname = element.defaultPartname,
              itemdetailsdata.push(matr);
          });

          let jobdetailsdata: any = [];
          let newRow = this.state.newRow;
          let orderArray = resultinv.jobdetails;
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {
              

              let el: any = {};
              el.id = element.id;
              el.processList = this.state.processdataList;
              el.srno = idx + 1;
              el.processName = element.processname;
              el.processid = element.processid;
              el.tdescription = element.jdescription;
              el.jDescription = element.jdescription;
              el.id = element.id;
              el.type = element.type;

              jobdetailsdata.push(el);
              //          let requestlist = { ...this.state.requestlist };
              //            this.GetTempateType(requestlist);
            });
            newRow.forEach(element => {
              element.processList = this.state.processdataList;
              // element.processName = filterRow[0].name;
              // element.processid = orderArray[idx].processid;

            });
          }


          this.setState({
            itemdetailsdata: itemdetailsdata,
            jobdetailsdata: jobdetailsdata,
          },
            () => {
              this.setState({ jobdetailsdata: [], }, () => {
                this.setState({
                  jobdetailsdata: jobdetailsdata,

                });

              });
             
            });

          let processid = resultinv.jobmaster[0].processid;





          this.setState({ itemdetailsdata: [], jobdetailsdata: [], }, () => {
            this.setState({ itemdetailsdata: itemdetailsdata, jobdetailsdata: jobdetailsdata });
            //this.getjobdata(processid);


          });


        };
        let assignedTo = this.state.assignedto;
        let jobprocess = this.state.jobprocess;
        let jobprocessid=this.state.jobprocessid;
        if (this.state.assignedto === '')
          assignedTo = 'Unassigned';
        if (this.state.jobprocess === '')
        {
          jobprocess = 'Custom';
          var prid= this.state.templatelistitem.filter(x => x.name === "Custom");
          jobprocessid=prid[0].id;
        }
        this.setState({ jobprocessid:jobprocessid, isdataloaded: 1, jobprocess: jobprocess, assignedto: assignedTo, isSpinner: false });
        resolve();

      })
      .catch((error) => {

        reject();
      });
  };

  GetItemlist = (requestlist: any) => {

    let inventorydata: any = [];

    let TypeInventory: any = [];
    let TypeUserlist: any = [];

    inventorydata = Object.assign([], JSON.parse(JSON.stringify(this.state.inventorydata)));

    JobServices.GetInventoryData(requestlist)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {
          resultinv.forEach(element => {

            let value = element.product_name + (element.inventory_description != null && element.inventory_description.length > 0 ? " ( " + element.inventory_description + " )" : "");
            let item: any = {};
            item.id = element.product_id;
            item.itemname = value;
            item.label = value;
            item.selectitem = element.product_name;
            item.glcode = element.glcode;
            item.stockqty = element.stockqty,
              // + "-" + element.category_name + "-" + element.producttype_name + " (" + element.sizedesc + " )";
              item.description = element.inventory_description;
            item.qty = element.quantity_in_stock;
            item.unitPrice = 0;
            item.unit = element.unit;
            TypeInventory.push(item);

          });

          inventorydata = TypeInventory;




          let itemdetailsdata: any = [];
          let newRow: any = {};
          let iteminventorydata: any = [];
          iteminventorydata = Object.assign([], JSON.parse(JSON.stringify(this.state.itemListdatam)));

          itemdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.itemdetailsdata)));



          this.setState({ itemdetailsdata: [] }, () => {
            this.setState({ itemdetailsdata: itemdetailsdata });

          });





          this.setState({ inventorydata: [], itemdetailsdata: [] }, () => {
            this.setState({ inventorydata: inventorydata, itemdetailsdata: itemdetailsdata, });

          });


        }
        resolve();

      })
      .catch((error) => {

        reject();
      });

  };

  getProcessTemplate = (requestlist: any) => {

    let Typelistprocessm: any = [];

    PartTemplateService.GetPartTemplatelist(requestlist)
      .then(async (result: any | null) => {

        if (result !== null) {
          result.forEach(element => {
            let item: any = {};
            item.id = element.id;
            item.name = element.tname;
            Typelistprocessm.push(item);
          });

   
          this.setState({
            templatelistitem: Typelistprocessm

          },
            () => {
               if (this.state.templatelistitem.length > 0)
               {
              var prid= this.state.templatelistitem.filter(x => x.name === "Custom");
                this.getTemplateData(prid[0].id);
              this.Getjoblist(requestlist);
               }

            });
        }

        resolve();

      })
      .catch((error) => {

        reject();
      });


  };

  getassigntodata = (requestlist: any) => {

    let TypeUserlist: any = [];
    let assignedto = "";
    User.getAllUsers()
      .then(async (result: any | null) => {

        if (result !== null) {

          result.forEach(element => {
            let item: any = {};
            item.id = element.user_UniqueID;
            item.name = element.userName;

            if (element.role == "1") {
              if (this.state.assignedto == "" || assignedto == "") {
                assignedto = element.userName;
              }
            };
            TypeUserlist.push(item);
          });



          this.setState({ assigneddatatoList: TypeUserlist, assignedto: assignedto });


        }
        resolve();

      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });

      this.getProcessTemplate(requestlist);

   

  };

  getjobdata = (id: any = []) => {
    let processdataList: any = [];
    let requestlist = { ...this.state.requestlist };


    PartTemplateService.GetProcessList(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {

          result.forEach(element => {

            let item: any = {};
            item.srno = element.srno;
            item.id = element.id;
            item.name = element.processName;
            item.description = element.pDescription;
            item.isFixed = element.isFixed;
            processdataList.push(item);
            //Typelistprocess.push(item);
          });
          this.setState({
            processdataList: processdataList,

          },
            () => {


              let requestlist = { ...this.state.requestlist };
              this.getPartDataforPopup("","");
             
              this.GetItemlist(requestlist);
              this.getassigntodata(requestlist);
              // this.setState({ processdataList: [], }, () => {
              //   this.setState({
                
              //     processdataList: processdataList,


              //   });

              // });

            });
         



        }
        resolve();
      });
    // .catch((error) => {

    //   reject();
    // });


  };

  getProcessDataforPopup = (id: any = []) => {
    
    let jobdetailsdata: any = [];
    let request: any = {};
    request.id = id;
    PartTemplateService.GetTemplatedata(request)
      .then(async (result: any | null) => {
        if (result !== null) {       
          let orderArray: any = [];
          orderArray = result[0].tDetails;
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {
              let filterRow = this.state.processdataList.filter(x => x.id === orderArray[idx].processid);
             // element.srno = idx + 1;
              element.processList = this.state.processdataList;
              element.processName = filterRow[0].name;
              element.processid = orderArray[idx].processid;
              element.jDescription = element.tdescription;
              element.processOrder = idx + 1;
              jobdetailsdata.push(element);
            });
        
          }
        

       
              this.setState({ processData: [], }, () => {
                this.setState({
                  processData: jobdetailsdata,

                });

              });
        }
      
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  getProcessDatabyPartJobForPopup = (id: any = []) => {
    
    let jobdetailsdata: any = [];
    let newRow = this.state.newRow;
    let request: any = {};
    request.jobId = id;
    PartTemplateService.GetProcessDataByJobId(request)
      .then(async (result: any | null) => {
        if (result !== null) {
       
          let orderArray: any = [];
          orderArray = result;
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {
              let filterRow = this.state.processdataList.filter(x => x.id === orderArray[idx].processId);
              element.srno = idx + 1;
              element.processList = this.state.processdataList;
              element.processName = filterRow[0].name;
              element.processid = orderArray[idx].processId;
              element.tdescription = filterRow[0].description;

              element.jDescription = filterRow[0].description;

              element.processOrder = idx + 1;
              jobdetailsdata.push(element);
            });
          }
         // this.setState({ processData: jobdetailsdata,isSpinner:false});

         
         this.setState({ processData: [], }, () => {
          this.setState({
            processData: jobdetailsdata,
            isPopupSpinner:false,
          });

        });
        }
      
        resolve();
      })
      .catch((error) => {
        this.setState({ isPopupSpinner: false });
        reject();
      });
  };

   getTemplateData = (id: any = []) => {
   
    let jobdetailsdata: any = []; 
    let newRow = this.state.newRow;
    // jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
    let saveRequest: any = {};
    saveRequest.id = id;
    let formData = { ...this.state.formData };
    saveRequest.tenantid = 0;
    PartTemplateService.GetTemplatedata(saveRequest)
      .then(async (result: any | null) => {
        if (result !== null) {
          let defaultper = result[0].tname;
          //formData.templatename = result[0].templatename;
          formData.tname = defaultper;
          let orderArray: any = [];
          orderArray = result[0].tDetails;
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {
              let filterRow = this.state.processdataList.filter(x => x.id === orderArray[idx].processid);

              element.processList = this.state.processdataList;
              // element.processName = filterRow[0].name;
              // element.processid = orderArray[idx].processid;
              // element.tdescription = filterRow[0].description;
              // element.jDescription = filterRow[0].description;
              // element.processOrder = idx + 1;

              element.processName = "Shipping";
              element.processid = 0;
              element.tdescription = "Ready for shipping";
              element.jDescription = "Ready for shipping";
              element.processOrder = 0;
              jobdetailsdata.push(element);
              //          let requestlist = { ...this.state.requestlist };
              //            this.GetTempateType(requestlist);
            });
            newRow.forEach(element => {
              element.processList = this.state.processdataList;
              // element.processName = filterRow[0].name;
              // element.processid = orderArray[idx].processid;

            });
          }

        
          this.setState({
            jobdetailsdata: jobdetailsdata,
            formData: formData,
          },
            () => {
              this.setState({ jobdetailsdata: [], }, () => {
                this.setState({
                  jobdetailsdata: jobdetailsdata,

                });

              });
             
            });



        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  };

  getPartDataforPopup = (searchby: any,type:any ) => {
    
    let partData: any = [];
    let request: any = {};
    request.searchby = searchby;
    request.type=type;
    PartTemplateService.GetPartdata(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          
          if (result.length > 0) {
            result.forEach((element, idx) => {
              let item:any = {};
              item.srno = idx + 1;
              item.partName = element.partName;
              item.jobid = element.jobid;
              item.tenantId = element.tenantid;
              item.jobNo = element.jobNo;
              partData.push(element);
            });
          }
          this.setState({ partData: partData });
        }
      
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  onTAB = (control, rowIndex1, event) => {

    let controlID = control.split("-")[0];
    let rowIndex = Number(rowIndex1);

    let itemdetailsdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.itemdetailsdata))
    );
    let invdata = this.state.inventorydata.filter((x) => x.glcode.toLowerCase() === event.target.value.toLowerCase());

    if (event.key.toLowerCase() === "enter") {
      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
      else if (controlID === "glcode") {
        let id = "txtunit" + rowIndex + "txt";
        let defaultitemname: any = [];
        itemdetailsdata[rowIndex].glcode = event.target.value;
        if (invdata.length > 0) {
          itemdetailsdata[rowIndex].itemname = itemdetailsdata[rowIndex].itemname == "" ? invdata[0].selectitem : itemdetailsdata[rowIndex].itemname;
          itemdetailsdata[rowIndex].itemid = invdata[0].id;
          itemdetailsdata[rowIndex].unitPrice = invdata[0].unitPrice;
          itemdetailsdata[rowIndex].unit = invdata[0].unit;

          defaultitemname = invdata;
          itemdetailsdata[rowIndex].defaultitemname = defaultitemname;
        }
        this.setState({ itemdetailsdata, defaultitemname }, () => {

          $("#tbltemplate")
            .find("input[id^='" + id + "']")
            .focus();
        });
      }
    }

    if (event.key.toLowerCase() === "tab") {
      
      //       let controlID = control.split("-")[0];
      //       let rowIndex = Number(rowIndex1);
      //       let itemdetailsdata = Object.assign(
      //         [],
      //         JSON.parse(JSON.stringify(this.state.itemdetailsdata))
      //       );
      //       let defaultitemname: any = [];
      //       let inventorydata = Object.assign(
      //         [],
      //         JSON.parse(JSON.stringify(this.state.inventorydata))
      //       );

      //       itemdetailsdata[rowIndex].itemname =itemdetailsdata[rowIndex].itemname =="" ? "" :itemdetailsdata[rowIndex].itemname ;
      //       itemdetailsdata[rowIndex].id = 0;
      //       itemdetailsdata[rowIndex].glcode =event.target.value;
      //       itemdetailsdata[rowIndex].stockqty = 0;
      //       itemdetailsdata[rowIndex].unit = "";
      //       itemdetailsdata[rowIndex].unitPrice = 0;

      //       itemdetailsdata[rowIndex].description = "";

      //       let objPartname: any = {};
      //       objPartname.itemname = event.target.value;
      //       objPartname.label = event.target.value;
      //       objPartname.id = 0;
      //       objPartname.glcode = "";
      //       objPartname.stockqty = 0;
      //       objPartname.unit = "";

      //       objPartname.tenantid = 0;

      //       objPartname.unitPrice = 0;

      //       objPartname.description = "";
      //       defaultitemname.push(objPartname);
      //       inventorydata.push(objPartname);


      //       itemdetailsdata[rowIndex].defaultitemname = defaultitemname;

      //       let ChecktxtID = "itemname" + rowIndex + "ddl";
      // 
      //       if (controlID === ChecktxtID) {
      //         let txtID = "txtglcode" + rowIndex + "txt";

      //         $("#tbltemplate")
      //           .find("input[id^='" + txtID + "']")
      //           .focus();
      //       }

      if (controlID === "txtduedate") {
        let txtID = "txtdescription" + rowIndex + "txt";

        $("#tbltemplate")
          .find("input[id^='" + txtID + "']")
          .focus();
      }

      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
      else if (controlID === "glcode") {

        // let id = "txtunit" + rowIndex + "txt";
        // let defaultitemname: any = [];
        // itemdetailsdata[rowIndex].glcode = event.target.value;
        // if (invdata.length > 0) {
        //   itemdetailsdata[rowIndex].itemname = invdata[0].selectitem;
        //   itemdetailsdata[rowIndex].itemid = invdata[0].id;
        //   itemdetailsdata[rowIndex].unitPrice = invdata[0].unitPrice;
        //   itemdetailsdata[rowIndex].unit = invdata[0].unit;

        //   defaultitemname = invdata;
        //   itemdetailsdata[rowIndex].defaultitemname = defaultitemname;
        // }
        // this.setState({ itemdetailsdata, defaultitemname }, () => {

        // });
      }
    }

    if (event.key.toLowerCase() === "backspace") {

      let controlID = control.split("-")[0];

      if (controlID === "itemname") {
        let itemdetailsdata = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.itemdetailsdata))
        );
        let defaultitemname: any = [];
        let inventorydata = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.inventorydata))
        );

        itemdetailsdata[rowIndex].itemname = "";
        //   itemdetailsdata[rowIndex].itemname;
        itemdetailsdata[rowIndex].id = 0;
        itemdetailsdata[rowIndex].glcode = event.target.value;
        itemdetailsdata[rowIndex].stockqty = 0;
        itemdetailsdata[rowIndex].unit = "";
        itemdetailsdata[rowIndex].unitPrice = 0;

        itemdetailsdata[rowIndex].description = "";

        let objPartname: any = {};
        objPartname.itemname = event.target.value;
        objPartname.label = event.target.value;
        objPartname.id = 0;
        objPartname.glcode = "";
        objPartname.stockqty = 0;
        objPartname.unit = "";

        objPartname.tenantid = 0;

        objPartname.unitPrice = 0;

        objPartname.description = "";
        defaultitemname.push(objPartname);
        // inventorydata.push(objPartname);


        itemdetailsdata[rowIndex].defaultitemname = defaultitemname;

        this.setState(
          { itemdetailsdata, defaultitemname, inventorydata },
          () => {




            let txtID = "rbt-input-main form-control rbt-input rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl";
            $("input[class^='" + txtID + "']").focus();

          }
        );
        // this.setState({ itemdetailsdata, defaultitemname, inventorydata }, () => {
        //   
        //   let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
        //   $("input[class^='" + txtID + "']").focus();
        //   // let txtID =
        //   //   "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl";
        //   // $("#tbltemplate")
        //   //   .find("input[class^='" + txtID + "']")
        //   //   .focus();
        // });

      }
    }
  };


  handleOutsideClick = (rowIndex: any, e: any) => {
    
    let btnId = "itemname" + rowIndex + "btn";
    let id = "itemname" + rowIndex + "ddl";
    let relatedTargetID = e.relatedTarget?.id;
    let itemdetailsdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.itemdetailsdata))
    );
    let defaultitemname: any = [];
    let inventorydata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.inventorydata))
    );

    itemdetailsdata[rowIndex].itemname = e.target.value;
    itemdetailsdata[rowIndex].id = 0;
    itemdetailsdata[rowIndex].glcode = itemdetailsdata[rowIndex].glcode;
    itemdetailsdata[rowIndex].stockqty = 0;
    itemdetailsdata[rowIndex].unit = "";
    itemdetailsdata[rowIndex].unitPrice = 0;

    itemdetailsdata[rowIndex].description = "";

    if (e.target.value != "") {
      let objPartname: any = {};
      objPartname.itemname = e.target.value;
      objPartname.label = e.target.value;
      objPartname.id = 0;
      objPartname.glcode = "";
      objPartname.stockqty = 0;
      objPartname.unit = "";

      objPartname.tenantid = 0;

      objPartname.unitPrice = 0;

      objPartname.description = "";
      defaultitemname.push(objPartname);
      inventorydata.push(objPartname);




      itemdetailsdata[rowIndex].defaultitemname = defaultitemname;

      this.setState({ itemdetailsdata, defaultitemname, inventorydata }, () => {


      });
    }
    else {
      let itemdetailsdata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.itemdetailsdata))
      );
      this.setState({ itemdetailsdata: [] }, () =>
        this.setState({ itemdetailsdata }, () => {
        })
      );
    }
    
    $("#tbltemplate")
      .find("button[id^='" + btnId + "']")
      .css("display", "none");
    $("#tbltemplate")
      .find("div[id^='" + id + "']")
      .css("display", "none");
    setTimeout(() => {
      if (relatedTargetID === undefined) {
        // setTimeout(() => {

        //   let txtID1 = "txtduedate" + rowIndex + "txt";
        //       $("#tbltemplate")
        //         .find("input[id^='" + txtID1 + "']")
        //         .focus();
        // }, 50);

      } else if (
        relatedTargetID ===
        "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl"
      ) {
        
        // let txtID1 =
        //   "rbt-input-main form-control rbt-input form-control form-control itemname" +rowIndex + "ddl";
        // $("#tbltemplate")
        //   .find("input[class^='" + txtID1 + "']")
        //   .focus();
        let txtID = "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl";
        $("input[class^='" + txtID + "']").focus();
      } else if (relatedTargetID === "txtglcode" + rowIndex + "txt") {
        let txtID1 = "txtglcode" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txtunit" + rowIndex + "txt") {
        let txtID1 = "txtunit" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txtstockqty" + rowIndex + "txt") {
        let txtID1 = "txtstockqty" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txtreqqty" + rowIndex + "txt") {
        let txtID1 = "txtreqqty" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txtqty" + rowIndex + "txt") {
        let txtID1 = "txtqty" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txtunitprice" + rowIndex + "txt") {
        let txtID1 = "txtunitprice" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txtdiscount" + rowIndex + "txt") {
        let txtID1 = "txtdiscount" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      } else if (relatedTargetID === "txttotal" + rowIndex + "txt") {
        let txtID1 = "txttotal" + rowIndex + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID1 + "']")
          .focus();
      }

    }, 50);



  };



  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);

  };

  closeSlideOut2 = () => {

    this.setState({ showVSlideout: false, isSpinner: false, });
    this.getjobdata();
  };

  scrollToBottom = () => {

    /* this.parentScroll.current.scrollTo({
 
       //top: this.parentScroll.current.clientHeight,
       top: this.parentScroll.current.scrollHeight,
       behavior: "smooth",
     });*/
  };

  hoverOutEllipsis = () => {
    $(".tooltip").removeClass("show");
  };

  closeSlideOut1 = () => {

    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  reopenSlideOutWithNextJob = () => {
    this.props.reopenSlideOutWithNextJob();
  };


  ishandleDeleteRow(row: any, mode: any) {
    if (mode) {
      
      let jobdetails = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));

      this.setState({ jobdetails: [] }, () => {
        

        const jobdetailsNewc = jobdetails.filter(item => item.srno !== row.srno).map((item, index) => {
          
          item.srno = index + 1;
          return item;
        });

        this.setState({
          jobdetailsdata: jobdetailsNewc,
          ischanged: 1,isStateChanged:true,

        },
          () => {


          });
      });
    }
  }

  handleItemDetailDeleteRow(row: any, mode: any) {
    if (mode) {

      let itemDetail = Object.assign([], JSON.parse(JSON.stringify(this.state.itemdetailsdata)));

      this.setState({ itemDetail: [] }, () => {


        const itemDetailNewc = itemDetail.filter(item => item.id !== row.id).map((item, index) => {
          //item.templateno = index + 1;
          return item;
        });

        this.setState({
          itemdetailsdata: itemDetailNewc,isStateChanged:true,
        },
          () => {


          });
      });
    }
  }
  handleSelectedItem = (control: any, id: any) => {

    let controlID = Number(control.split('_')[0]);

    if (controlID === 2) {

      let rowIndex = Number(control.split('_')[1]);
      let jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
      let filterRow = this.state.processdataList.filter(x => x.id === id);
      jobdetailsdata[rowIndex].type = filterRow[0].isFixed == 1 ? "Vendor" : "Work";

      jobdetailsdata[rowIndex].processName = filterRow[0].name;
      jobdetailsdata[rowIndex].processid = filterRow[0].id;
      jobdetailsdata[rowIndex].tdescription = filterRow[0].description;
      jobdetailsdata[rowIndex].jDescription = filterRow[0].description;
      //jobdetailsdata[rowIndex].type = filterRow[0].type;
      this.setState({ jobdetailsdata: [] }, () => {
        this.setState({ jobdetailsdata, ischanged: 1,isStateChanged:true }, () => {
          let txtID = 'ddlprocess-' + (rowIndex) + "ddl";
          $("#tbltemplate").find("button[id^='" + txtID + "']").focus();
        });
      });

    }
    else if (controlID === 3) {

    }
    else if (controlID === 5) {

      let rowIndex = Number(control.split('_')[1]);
      let itemdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.itemdetailsdata)));
      let filterRow = this.state.itemListdatam.filter(x => x.id === id);


      itemdetailsdata[rowIndex].itemname = filterRow[0].name;
      itemdetailsdata[rowIndex].description = filterRow[0].desc;
      itemdetailsdata[rowIndex].qty = filterRow[0].qty;
      itemdetailsdata[rowIndex].sizedesc = filterRow[0].sizedesc;


      //itemdetailsdata[rowIndex].assignedid = filterRow[0].id;

      this.setState({ itemdetailsdata: [] }, () => {
        this.setState({ itemdetailsdata: itemdetailsdata,isStateChanged:true });

      });
    };
  };

  handleSelectedItemInv = (control: any, id: any) => {
    if (this != undefined) {
      let isStateChanged = true;
      
      let formData = { ...this.state.formData };
      let controlID = Number(control.split("_")[0]);
      let rowIndex = Number(control.split("_")[1]);

      let itemdetailsdata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.itemdetailsdata))
      );
      let defaultitemname: any = [];
      let inventorydata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.inventorydata))
      );
      if (controlID === 1) {

        if (id.length > 0) {
          if (id[0].id > 0) {
            itemdetailsdata[rowIndex].itemname = id[0].selectitem;
            itemdetailsdata[rowIndex].id = id[0].id;
            itemdetailsdata[rowIndex].unitPrice = id[0].unitPrice;
            itemdetailsdata[rowIndex].unit = id[0].unit;
            itemdetailsdata[rowIndex].glcode = id[0].description;
            itemdetailsdata[rowIndex].stockqty = id[0].stockqty;

            itemdetailsdata[rowIndex].unit = id[0].unit;

            itemdetailsdata[rowIndex].description = id[0].description;

            let objPartname: any = {};
            objPartname.itemname = id[0].selectitem;
            objPartname.label = id[0].selectitem;
            objPartname.id = id[0].id;
            objPartname.glcode = id[0].glcode;
            objPartname.stockqty = id[0].stockqty;
            objPartname.unitPrice = id[0].unitPrice;
            objPartname.unit = id[0].unit;
            objPartname.tenantid = 0;

            objPartname.description = id[0].description;
            defaultitemname.push(objPartname);

            itemdetailsdata[rowIndex].defaultitemname = defaultitemname;
            this.setState(
              { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
              () => {



                // 
                // let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
                // $("input[class^='" + txtID + "']").focus();

              }
            );
            // this.setState(
            //   { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
            //   () => {

            //     let txtID = "txtglcode" + rowIndex + "txt";
            //     $("#tbltemplate")
            //       .find("input[id^='" + txtID + "']")
            //       .focus();
            //   }
            // );
          } else {
            itemdetailsdata[rowIndex].itemname = id[0].label;
            itemdetailsdata[rowIndex].id = 0;
            itemdetailsdata[rowIndex].glcode = "";
            itemdetailsdata[rowIndex].stockqty = 0;
            itemdetailsdata[rowIndex].unit = "";
            itemdetailsdata[rowIndex].unitPrice = 0;

            itemdetailsdata[rowIndex].description = "";

            let objPartname: any = {};
            objPartname.itemname = id[0].label;
            objPartname.label = id[0].label;
            objPartname.id = 0;
            objPartname.glcode = "";
            objPartname.unit = "";
            objPartname.stockqty = 0;

            objPartname.tenantid = 0;

            objPartname.unitPrice = 0;

            objPartname.description = "";
            defaultitemname.push(objPartname);
            inventorydata.push(objPartname);


            itemdetailsdata[rowIndex].defaultitemname = id;
            this.setState(
              { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
              () => {



                // 
                // let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
                // $("input[class^='" + txtID + "']").focus();

              }
            );
            // this.setState(
            //   { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
            //   () => {

            //     let txtID = "txtglcode" + rowIndex + "txt";
            //     $("#tbltemplate")
            //       .find("input[id^='" + txtID + "']")
            //       .focus();
            //   }
            // );
          }
        } else {

          itemdetailsdata[rowIndex].itemname = "";
          itemdetailsdata[rowIndex].id = 0;
          itemdetailsdata[rowIndex].glcode = "";
          itemdetailsdata[rowIndex].stockqty = 0;
          itemdetailsdata[rowIndex].unit = "";
          itemdetailsdata[rowIndex].unitPrice = 0;

          itemdetailsdata[rowIndex].description = "";

          let objPartname: any = {};
          objPartname.itemname = "";
          objPartname.label = "";
          objPartname.id = 0;
          objPartname.glcode = "";
          objPartname.unit = "";
          objPartname.stockqty = 0;
          objPartname.tenantid = 0;

          objPartname.unitPrice = 0;

          objPartname.description = "";
          defaultitemname.push(objPartname);
          //inventorydata.push(objPartname);

          //element.defaultPartname = defaultPartname;
          itemdetailsdata[rowIndex].defaultitemname = id;
          this.setState(
            { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
            () => {



              // 
              // let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
              // $("input[class^='" + txtID + "']").focus();

            }
          );

        }


      }
      
      this.setState(
        { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
        () => {



          
          let txtID = "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl";
          $("input[class^='" + txtID + "']").focus();

        }
      );
    }
  };

  handleSelectedItem1 = (control: any, id: any) => {

    if (control == 2) {

      let assignTo = this.state.assigneddatatoList.filter((x) => x.id == id);
      let assignToName = assignTo.length > 0 ? assignTo[0].name : '';
      this.setState({ jobassignedid: id, assignedto: assignToName });
    }
    else if (control == 'JobNoList') {
      this.setState({ isSpinner: true });
      this.state.formData.jobid = id;
      this.GetJobDataByIdOnly();
    }
    else {

      let processes = this.state.templatelistitem.filter((x) => x.id == id);
      let processName = processes.length > 0 ? processes[0].name : '';
      this.setState({ jobprocessid: id, jobprocess: processName });
      this.getTemplateData(id);
      // this.getjobdata(id);    
    }
  };
  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;

      let itemdetailsdata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.itemdetailsdata))
      );

      let jobdetailsdata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.jobdetailsdata))
      );
      if (type === "glcode") {
        e.preventDefault();
        let defaultitemname: any = [];

        itemdetailsdata[index].glcode = e.target.value;

        this.setState({ itemdetailsdata, defaultitemname, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "description") {
        
        e.preventDefault();

        jobdetailsdata[index].jDescription = e.target.value;

        this.setState({ jobdetailsdata, isStateChanged }, () => {
          $("#tbltemplate2")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate2").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }

        });
      }
      else if (type === "unitPrice") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;
        itemdetailsdata[index].unitPrice = e.target.value;

        this.setState({ itemdetailsdata, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "orderqty") {
        e.preventDefault();
        let newVal = e.target.value.replace(/[^0-9\s]/g, ''); 
         e.target.value = newVal;
        itemdetailsdata[index].orderqty = e.target.value;

        this.setState({ itemdetailsdata, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      else if (type === "reqqty") {
        e.preventDefault();
        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;

        itemdetailsdata[index].qty = e.target.value;

        if (itemdetailsdata[index].stockqty != "" && itemdetailsdata[index].stockqty != undefined) {
          var orderqty = e.target.value - itemdetailsdata[index].stockqty;
          if (orderqty > 0) {
            itemdetailsdata[index].orderqty = orderqty;
          }
        }
        else {
          itemdetailsdata[index].orderqty = newVal;

        }
        this.setState({ itemdetailsdata, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      else if (type === "unit") {
        e.preventDefault();

        itemdetailsdata[index].unit = e.target.value;

        this.setState({ itemdetailsdata, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }





    }
  };

  onAmountFocus = (e, index, txtID,type: any) => {
    
    let itemdetailsdata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.itemdetailsdata))
    );

    //let newval = Utils.removecurrencyFormat(e.target.value);
    let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
    if (type === "unitPrice") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      itemdetailsdata[index].unitPrice = e.target.value;

      this.setState({ itemdetailsdata }, () => {
        $("#tbltemplate")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tbltemplate").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }

    if (type === "discount") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      itemdetailsdata[index].discount =  e.target.value;

      this.setState({ itemdetailsdata }, () => {
        $("#tbltemplate")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tbltemplate").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }
    
   
    // this.setState({ orderData }, () => {
    //     $("#tbltemplate").find("input[id^='" + txtID + "']").focus();
    // });
};

amountBlur = (e, rowIndex, type: any) => {
    
  let itemdetailsdata = Object.assign(
    [],
    JSON.parse(JSON.stringify(this.state.itemdetailsdata))
  );

  if (e.target.value.includes(".")) {
    const timeArray = e.target.value?.split(".");
    if (timeArray[1].length <= 0) {
      if (type === "unitPrice") {
        itemdetailsdata[rowIndex].unitPrice = Utils.currencyFormat(
          timeArray[0]
        ); //Number(timeArray[0]).toFixed(2);
      }
      if (type === "discount") {
        itemdetailsdata[rowIndex].discount = Utils.currencyFormat(
          timeArray[0]
        ); //Number(timeArray[0]).toFixed(2);
      }
      if (type === "qtyOrdered") {
        itemdetailsdata[rowIndex].qtyOrdered = Utils.currencyFormatWithOutdoller(
          timeArray[0]
        ); //Number(timeArray[0]).toFixed(2);
      }
    } else {
      if (type === "unitPrice") {
        itemdetailsdata[rowIndex].unitPrice = Utils.currencyFormat(
          e.target.value
        );
      }
      if (type === "discount") {
        itemdetailsdata[rowIndex].discount = Utils.currencyFormat(
          e.target.value
        );
      }
      if (type === "qtyOrdered") {
        itemdetailsdata[rowIndex].qtyOrdered = Utils.currencyFormatWithOutdoller(
          e.target.value
        );
      }
    }

    this.setState({ itemdetailsdata });
  } else {
    if (type === "unitPrice") {
      itemdetailsdata[rowIndex].unitPrice =
        e.target.value === ""
          ? ""
          : !isNaN(e.target.value)
            ? Utils.currencyFormat(e.target.value)
            : e.target.value;
    }
    if (type === "discount") {
      itemdetailsdata[rowIndex].discount =
        e.target.value === ""
          ? ""
          : !isNaN(e.target.value)
            ? Utils.currencyFormatWithOutdoller(e.target.value)
            : e.target.value;
    }
    this.setState({ itemdetailsdata });
  }
};
  handleNewRow = () => {

    let jobdetailsdata: any = [];
    let newRow: any = {};
    let isStateChanged = true;
    
    jobdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));
    let maxValueOfRow = jobdetailsdata.length ? Math.max(...jobdetailsdata.map(o => o.srno)) : 0;
    let len = jobdetailsdata.length;
    let maxRno = maxValueOfRow;
    let maxSrno = jobdetailsdata[len - 1]?.srno?jobdetailsdata[len - 1].srno:maxRno;
    let lastprocessid = jobdetailsdata[len - 1]?.srno+1 ? jobdetailsdata[len - 1].srno+1 : maxRno+1;
    newRow[0].srno = Number(maxSrno) + 1;
    newRow[0].processOrder = newRow[0].srno;
    let filterRow = this.state.processdataList.filter(x => x.srno === lastprocessid);

    if (filterRow.length != 0) {



      //  newRow[0].processList = this.state.processdataList;

      newRow[0].tdescription = filterRow[0].description;
      newRow[0].jDescription = filterRow[0].description;
      newRow[0].processName = filterRow[0].name;
      newRow[0].processid = filterRow[0].id;
    }
    else
    {
      let filterRow = this.state.processdataList.filter(x => x.srno ===1);
      if (filterRow.length != 0) {


        
              //  newRow[0].processList = this.state.processdataList;
        
              newRow[0].tdescription = filterRow[0].description;
              newRow[0].jDescription = filterRow[0].description;
              newRow[0].processName = filterRow[0].name;
              newRow[0].processid = filterRow[0].id;
            }
    }
    
    jobdetailsdata.push(newRow[0]);
    this.setState({ jobdetailsdata: [] }, () => {
      this.setState({ jobdetailsdata: jobdetailsdata, isStateChanged, ischanged: 1 });

    });




  };

  handleNewRowitems = () => {
    let itemdetailsdata: any = [];
    let newRow: any = {};
    let isStateChanged = true;
    let iteminventorydata: any = [];
    iteminventorydata = Object.assign([], JSON.parse(JSON.stringify(this.state.itemListdatam)));

    itemdetailsdata = Object.assign([], JSON.parse(JSON.stringify(this.state.itemdetailsdata)));
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.itemdetailsdatanew)));

    let maxValueOfRow = Math.max(...itemdetailsdata.map(o => o.itemNo), 0);
    let len = itemdetailsdata.length;

    let maxSrno = itemdetailsdata[len - 1].itemNo;
    newRow.itemNo = Number(maxSrno) + 1;
    newRow.itemListdata = iteminventorydata;

    itemdetailsdata.push(newRow);
    this.setState({ itemdetailsdata: [] }, () => {
      this.setState({ itemdetailsdata: itemdetailsdata, isStateChanged });

    });




  };
  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = this.state.formData.transcationDate;
    updatedItem.vendorName = this.state.formData.vendorName;
    updatedItem.amountTotal = this.state.totalAmount;
    return updatedItem;
  };
  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }
  CommentModalClose = () => {
    let uniqueno = this.state.formData.uniqueno;
    this.handleDiscard(uniqueno);
  };

  Getpreviousjob=(e:any,type :any)=> {

if(e.target.value.length>2)
  {
    let start = e.target?.selectionStart;
    let end = e.target?.selectionEnd;
e.preventDefault();

this.getPartDataforPopup(e.target.value,type);
  }
  else
  {
if(e.target.value=="")
  {
    this.setState({ partData: [],processData :[] });

  }

  }

};

  handleTemplateButton = (type: any) => {
    if (type == 'yes')
      this.state.formData.templatename = this.state.templatename;
    else
      this.state.formData.templatename = '';

    this.savejob(0);
    this.setState({ ispopupshow: false });
  };

  handleChange = (e: any, control: any) => {
    //e.preventDefault();
    this.setState({ templatename: e.target.value });

  };
  handleCloseModel = () => {
    this.setState({ ispopupshow: false });
  };
  showSaveProcessTemplatePopup = (type) => {

    //if( this.state.jobdetailsdata.length > 1 )
    // {
    //   this.setState({ ispopupshow: true });
    // }
    // else 
    if (this.state.ischanged == 1) {
      this.setState({ ispopupshow: true });
      
      
    }
    else {
      this.savejob(type);
    }

  };


  Print = () => {

    let saveRequest: any = {};
    saveRequest.orderid = this.state.formData.orderid;
    saveRequest.partid = this.state.formData.partid;
    saveRequest.jobid = this.state.formData.jobid;

    JobServices.DownloadJobFile(saveRequest)
   
      .then(async (result: any | null) => {
        //this.HidecloseSlideOut();
        this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
        resolve();
      })
      .catch((error) => {
        reject();
      this.setState({ showAfterSaveModelPopup: false, isSpinner:false, });
      });
  };


  deleteJob = () => {
    this.setState({ isSpinner: true });
    let request = { ...this.state.requestlist };
    request.jobId = this.state.jobid;
    JobServices.DeleteJob(request)
      .then(async (result: any | null) => {
        if (result !== null) {

          this.closeSlideOut1();

          toast.success("Job has been deleted.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });



          this.setState({ isSpinner: false });

        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  };

  showvendorpo=()=>{

this.setState({showVSlideout:true});
  };

 

  savejob = (type) => {

    debugger;
    let request: any = {};

    let itemdetailsdata = this.state.itemdetailsdata;
    let jobdetailsdata = this.state.jobdetailsdata;

    if (jobdetailsdata.filter((x) => x.processName == '' || x.processName.length == 0).length > 0) {
      this.setState({ isSpinner: false });
      // alert("Please enter the Process.");
      toast.success("Please enter the Process.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      return;
    }
    this.state.formData.itemdata = [];
    //itemdetailsdata.forEach(element => {
    let item1: any = {};
    item1.id = 0;
    let element = itemdetailsdata[0];
    item1.itemid = element.itemid;

    item1.itemname = (element.defaultitemname.length == 0 || element.defaultitemname[0].itemname === undefined) ? element.itemname : element.defaultitemname[0].itemname;
    //element.itemname;
    item1.glcode = element.glcode;
    item1.unit = element.unit;
    item1.stockqty = element.stockqty;
    item1.reqqty = element.qty;
    item1.orderqty = element.orderqty;
    item1.unitprice =Utils.removeInvaildCharFromAmount(element.unitPrice);
    this.state.formData.itemdata.push(item1);

    //});
    // if (this.state.formData.itemdata.filter((x) => x.itemname == "" || x.itemname == undefined).length != 0) {

    //   toast.error("Please select item.", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     containerId: "OrderSlideout",
    //   });
    //   return;
    // }


if(this.state.formData.itemdata.filter((x) => x.itemname != "").length != 0)
{
    if (this.state.formData.itemdata.filter((x) => x.unitprice == '' ).length != 0) {

      toast.error("Please enter valid Unit Price.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      return;
    }


debugger;
    if (this.state.formData.itemdata.filter((x) =>x.orderqty ==='').length != 0) {

      toast.error("Please enter valid order Qty.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      return;
    }

  }
  else
{

  this.state.formData.itemdata=[];
}


    this.state.formData.jobdetails = [];
    jobdetailsdata.forEach(element => {
      let item: any = {};
      item.id = element.id;
      item.jobid = this.state.jobid;
      item.processid = element.processid;
      item.processname = element.processName;
      item.type = element.type;
      item.jdescription = element.jDescription;
      item.assignedid = element.assignedid == null ? this.state.jobassignedid : element.assignedid;
      item.tenantid = 0;
      this.state.formData.jobdetails.push(item);
    });
    

    if(this.state.formData.jobid==null)
    {

      this.state.formData.jobid=0;
    }
    this.state.formData.jobassignedId = this.state.jobassignedid;
    this.state.formData.processid = this.state.jobprocessid;
    this.state.formData.ManualTracking= this.state.manualTracking;
    


    let selectedTemplate = this.state.templatelistitem.filter(x => x.id === this.state.jobprocessid);
    let tnameselect = selectedTemplate != null && selectedTemplate.length > 0 ? this.state.templatelistitem.filter(x => x.id === this.state.jobprocessid)[0].name : '';

    this.state.formData.tname = tnameselect;
    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
    this.setState({ isSpinner: true,ispopupshow:false });
    JobServices.SaveJob(request)
      .then(async (result: any | null) => {
        debugger;
        if (result !== null) {

         if (result=="Alreadytemplate")
         {
          toast.success("Already template name exists. Please change template name" , {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
          this.setState({ isSpinner: false,templatename:"" });
          reject();
         }
         else
         {
          if (this.state.jobid === 0) {

            toast.success(result.jobNo + " Job has been created.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          }
          else {

            toast.success("Job has been updated.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });

          }
          this.state.formData.jobid=result.jobid;
          this.setState({ isSpinner: false,ispopupshow:false,isStateChanged:false,ischanged:0,
            selectedTemplateId:0,
            selectedTemplateName:"",
            selectedJobNo:"",
            templatena:"",
            templatename:"",
            jobid:result.jobid,
           },()=>{
           
            if (type == 1) {
              this.closeSlideOut();
              //this.props.closeSlideOut(this.state?.shouldReloadMainPage);
            }
            else {
              if (this.state.jobid === 0) {
              let requestlist = { ...this.state.requestlist };
  
              this.getjobdata();
              this.GetItemlist(requestlist);
              this.getassigntodata(requestlist);
              }
            }
           }
          
          
          );
          //this.reopenSlideOutWithNextJob();
       
        }
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  };

  recreateJob = (eventKey: any) => {
    if (eventKey == "DeleteJob") {
      // this.deleteJob();
      let masg = "";

      masg = "Are you sure you want to delete this Job?";

      confirmAlert({
        title: "Delete Job",

        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteJob(),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    else if (eventKey=="Print")
    {

      this.Print();
    }
    else {
      this.props.reopenSlideOutWithReworkJob();
    }
  };

  applyTemplate = () => {
    /*if(this.state.templateType=='Template')
      this.setState({ jobprocessid: this.state.selectedTemplateId, jobprocess: this.state.selectedTemplateName ,showProcessPopup: false, jobdetailsdata: this.state.processData });

    else */
      this.setState({jobprocessid: this.state.selectedTemplateId, jobprocess: this.state.selectedTemplateName ,showProcessPopup: false, jobdetailsdata: this.state.processData,isStateChanged:true});
      
     
  };


  render() {
    const {
showSlideout,
      showVSlideout,

    } = this.state;
    const rowClasses = 'custom-row-class';
     const options = {
    
    
  
    sizePerPage: 8,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  
  
  // A numeric array is also available. the purpose of above example is custom the text
    };

// alert(this.state.jobid);
// alert(this.state.jobTrackerId);
// alert(this.state.processVendorPOCreated);
//alert(this.state.materialVendorPOCreated);
    const cardContainerStyles = "p-1 max-w-sm mx-auto bg-white rounded-lg border border-zinc-200 shadow-md dark:bg-zinc-800 dark:border-zinc-700 rounded";
    const titleStyles = "font-semibold text-sm text-zinc-900 dark:text-white";
    const subTitleStyles = "text-zinc-500 dark:text-zinc-400";
    const subTitleStyles2 = "text-zinc-300 dark:text-zinc-200";
   
    return (

      <section>
        <div
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />


          <Container
            fluid

            className="body-sec viewer-panel back-drop p-0 "
            style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

          >


            <div
              className="back-drop1  viewer-panel1 opacity "
              onClick={() => this.closeSlideOut()}
            ></div>
            <Container

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}



            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center border-bottom round-4"



              >
                <div className="divheader">
                  <h5 className="my-title">

                    {this.state.jobid == 0 && this.state.poNumber!=undefined && <span>New Job No: {"JO#"+this.state.poNumber}-{this.state.itemNo}{this.props.rNo}  </span>

                    }
                    {this.state.jobid != 0 && (
                      <span>Job No :  {this.state.jobNo}</span>
                    )}
                  </h5>
                  {(this.state.trackerStatus.toLowerCase() === 'rework job' && (this.state.reworkCount == 0 || this.state.reworkCount == null) && this.props.reopenSlideOutWithReworkJob !== undefined) &&
                    (<><div style={{ paddingLeft: '50px', display: 'flex' }}>

                      {/* NCR Raised with Rework */}
                      <span className="badge badge-pill badge-primary color-red"> NCR Raised </span>


                    </div>
                    </>)}

                  {this.state.jobMaster.length > 1 && <SingleDropdownListWithoutSearch
                    tabIndex={this.state.tabEnable}
                    itemList={this.state.jobMaster}
                    handleSelectedItem={this.handleSelectedItem1.bind(this, 'JobNoList')}
                    defaultItem={this.state.jobid}
                    defaultText={"Select job"}
                    defaultName={this.state.formData.jobNo}
                    controlID="1"
                    id={"ddlJob"}
                    key="id"
                  //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                  // disabled={disable}
                  />}

                  {this.state.runningInTracker ?
                    // <Button style={{position:"fixed",right:"100px"}}>Running</Button>:""
                    <span className="badge badge-pill badge-primary color-green"> Running </span>
                    : ""

                  }

                  <div className="header-popout ml-auto">

                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">

  { this.state.jobid !=0 &&  
                            
    <div className="action">
                   
                     <Dropdown
                       className="more-action"
                       
                       onSelect={(event: any) => { this.recreateJob(event); }}
                     >
                       <Dropdown.Toggle
                         className="btn-outline-primary btn btn-primary more"
                         id="dropdown-more"
                       >
                         <svg
                           width="20"
                           height="20"
                           viewBox="0 0 20 20"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                         >
                           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                         </svg>
                       </Dropdown.Toggle>
                       <Dropdown.Menu>
                    
                       <Dropdown.Item
                             // className={(    this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                             eventKey={"Print"}
                             key={1}
                           >
                             {"Print"}
                           </Dropdown.Item>
{(this.state.jobid !=0 &&
((!this.state.materialVendorPOCreated && !this.state.processVendorPOCreated) || this.state.runningInTracker==null)
&&
<Dropdown.Item
// className={(    this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
eventKey={"DeleteJob"}
key={1}
>
{"Delete Job"}
</Dropdown.Item>
)
}

                         
                        
                         {/* {(this.state.trackerStatus.toLowerCase() === 'rework job' && (this.state.reworkCount == 0 || this.state.reworkCount == null) && this.props.reopenSlideOutWithReworkJob !== undefined) &&
                           <Dropdown.Item
                             // className={(    this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                             eventKey={">Re-create Job"}
                             key={1}
                           >
                             {"Re-create Job"}
                           </Dropdown.Item>
                         } */}
                       </Dropdown.Menu>
                     </Dropdown>

                  
                  
                 </div>
               
                     
               }
    </div>
    <div className="col">
    <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut1}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
                      
    </div>
   
  </div>
  </div>

                  
                 
                  </div>
                </div>
                </div>
                

              <div
                className="scroll"
              >
                <Container fluid className="p-10">




                      <div className="row">


                        <div className="col-sm-6">
                          <div className="card h-100">
                            <div className="card-header">
                              Order Details
                            </div>
                            <div className="card-body">
                              <h5 className="card-title"> {this.state.CustomerPoNumber}</h5>
                              <p className="card-text text-xs"> {this.state.jobid !=0 ? this.state.selectedCustomer : this.props.selectedCustomer}
                                 <br></br>Order Date - {this.state.jobid !=0 ?  
                                this.state.orderdate == undefined ? '' :moment(this.state.orderdate).format("MM/DD/YYYY") 
                                : this.props.orderdate == undefined ? '': moment(this.props.orderdate).format("MM/DD/YYYY")}
                              
                               <div className="w-auto" >
                               <div className="row g-3 pt-2">
                               <div className="col-sm-2">
                               <Form.Label className="font-semibold pt-1"> Owner  </Form.Label>
                             

</div>
<div className="col-sm-4">
<Form.Label>
                              {this.state.assignedto !== '' && <SingleDropdownListWithoutSearch
                                tabIndex={this.state.tabEnable}
                                itemList={this.state.assigneddatatoList}
                                handleSelectedItem={this.handleSelectedItem1.bind(this, 2)}
                                defaultItem={this.state.jobassignedid}
                                defaultText={"Select Employee"}
                                defaultName={this.state.assignedto}
                                controlID="1"
                                key="assignedto"
                                id={"ddlassignddl"}
                              //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
                              // disabled={disable}
                              />}
</Form.Label>


                               </div>
                               </div>

                           
                           
                          </div>
                               
                              </p>
                            

                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card h-100">
                            <div className="card-header">
                              Part Details
                            </div>
                            <div className="card-body">
                              <h5 className="card-title">Part Name : {this.state.orderData[0].partname == undefined ? this.state.partname : this.state.orderData[0].partname}</h5>
                              <p className="card-text text-xs">Part No : {this.state.orderData[0].partNo == undefined ? this.state.partNo : this.state.orderData[0].partNo} <br></br>Order QTY - {this.state.qtyOrdered}

                                <br>
                                </br>
                                Due Date - {moment(this.state.orderData[0].dueDate).format("MM/DD/YYYY")}
                               
                             
                              
                                <div className="w-auto pt-1" >
                                <span style={{ color: 'white'}}>.</span> 
                                </div>
                              </p>


                            </div>
                          </div>
                        </div>



                      </div>
                 
                
                  <section className="pt-3 pb-3">
 
          
                    <div
                      id="order"
                      className="table-responsive1 pt-2"
                    >
 <div className="card h-100">
                            <div className="card-header">
                            <Form.Label className="font-semibold">
                      Material Details  {(this.state.vendorPoNo!=null && this.state.vendorPoNo!="")  && (  <a   onClick={() => this.showvendorpo()} className="orderLinks" >
                        <span className="badge badge-pill badge-primary color-green"> Vendor  {this.state.vendorPoNo}  </span>
                        </a>
                      )}

                      {(this.state.vendorPoNo!=null && this.state.vendorPoNo!="")  && ( <span className={this.state.Received=="" || this.state.Received==null ? "badge badge-pill badge-primary color-red" :  this.state.Received=="Partial" ? "badge badge-pill badge-primary color-orange": "badge badge-pill badge-primary color-green"}>  {this.state.Received=="" || this.state.Received==null ? "receiving  pending" : this.state.Received  }</span>) }

                      </Form.Label>
                            </div>
                            <div className="card-body">

                    
                      <div
                        //   className="transactions-list inpute"
                        //  className="table table-borderless align-middle  transactions-list"
                        className="table table-borderless align-middle transactions-list"
                      >
                        { this.state.itemColumns.length > 0 && (
                          <div className="split-assign-coa">
                            <BootstrapTable
                              id="tbltemplate"
                              keyField="templateid"
                              data={this.state.itemdetailsdata}
                              columns={this.state.itemColumns}
                            />
                          </div>
                        )}
                      </div>

                      <div className="d-flex action-btn align-items-center">

                        {false &&
                          <Button
                            type="button"
                            className="btn-ghost mr-auto"
                            onClick={this.handleNewRowitems}
                          >

                            Add Item
                          </Button>}
                      </div>
                      </div>
                      </div>
                      <section className='mt-4' >
                      <div className="card h-100">
                            <div className="card-header">
                            Job Router 
                     
                            </div>
                            <div className="card-body">

                            <div className="row">
                       
                       <div className="col-8">
                       <div className="section" style={{ display: "flex" }}>
                         
                       {this.state.runningInTracker!=true && (
                   <div className="notification-banners orange-bg">
                       <div>
                         <span className="bold">
                           Select steps from template  or previous job steps
                         </span>
                       </div>
                      
                       <div> 
       <OverlayTrigger
   placement="top"
   delay={{ show: 250, hide: 400 }}
   //overlay={renderTooltip}
   overlay={<Tooltip id="addContract">Select Process</Tooltip>}
 >
       <button  onClick={(e)=>{ this.setState({ showProcessPopup: true });}} className='btnheight' disabled={this.state.runningInTracker?true:false}  >
         
       {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
<path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"/>
<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
</svg> */}
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
<path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
<path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>
         </button>
         </OverlayTrigger>
         </div> 

                       {/* <a   onClick={(e)=>{ this.setState({ showProcessPopup: true });}}  className="pt-2 px-2 orderLinks" > Select process  </a> */}

                      
                     
                     </div>
                       )}


                        </div>

                  

                       </div>
                       <div className="col-4">
                       <div style={{float: 'right', opacity : (this.state.runningInTracker ? 0.3 :1),pointerEvents: (this.state.runningInTracker ? 'none':'auto')}}>
<Form.Label className="font-semibold" style={{marginRight:'20px'}}>
Tracking
                   </Form.Label>

{ true &&
<BootstrapSwitchButton
checked={!this.state.manualTracking}
offstyle="info"
onstyle="success" 
onlabel='On'
offlabel='Off'
onChange={(checked: boolean) => {
   this.setState({ manualTracking: !checked,isStateChanged:true });
}}
height={25}
width={70}
/>}

</div>
                       </div>
                       </div>
                      <div
                       //style={{opacity:( this.state.trackerProcessIds != null ? 0.5 : 1),pointerEvents: (this.state.trackerProcessIds != null  ?'none':'auto')}}

                      >


                     

                        <div className="row g-3">

                  <div className="col-12 col-md-1 pt-2" >
                       
                    
                      </div>  <div className="col-2 col-md-1" >
                      <div className="w-auto d-none" >
                              <Form.Label>
                                {this.state.jobprocess !== '' &&

                                  <SingleDropdownListWithoutSearch

                                    tabIndex={this.state.tabEnable}
                                    itemList={this.state.templatelistitem}
                                    handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}

key="id"
                                    defaultItem={this.state.jobprocessid}
                                    defaultText={"Search Template or Create New Template"}
                                    defaultName={this.state.jobprocess}
                                    defaultSelected={this.state.jobprocessid}
                                    controlID="1"
                                    id={"ddltempate"}
                                    // onTAB={this.onTAB.bind(this, "ddltempate-" + rowIndex + "ddl", rowIndex)}
                                    disabled={this.state.processVendorPOCreated}
                                  />
                                }
                              </Form.Label>

                            </div>
                            </div>
                            </div>
                          <div
                            className="table table-borderless align-middle transactions-list"
                          // className="transactions-list inpute"
                          //className="table table-borderless align-middle  transactions-list"
                          >
                            {true && this.state.templateColumns.length > 0 && (

                              <BootstrapTable
                                id="tbltemplate2"
                                keyField="templateid"
                                data={this.state.jobdetailsdata}
                                columns={this.state.templateColumns}
                              />

                            )}
                          </div>
                       

                        <div className="d-flex align-items-center justify-content-end">
                          <div className="d-flex action-btn align-items-center">
                            {/* {!this.state.processVendorPOCreated &&  */}
                            <Button
                              type="button"
                              className="btn-ghost mr-auto"
                              onClick={this.handleNewRow}
                            >
                              <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                            </Button>

                            {/* } */}


                          </div>
                        </div>
                        {/* <div className="d-flex action-btn align-items-center pb-3">
                   {!this.state.processVendorPOCreated && 
                    
                    <Button
                      type="button"
                      className="btn-ghost mr-auto"
                      onClick={this.handleNewRow}
                    >
                      Add Row
                    </Button>
                    
                    }
                  </div> */}
                      </div>
                      </div>
                      </div>
</section>
                      {(this.state.jobid != 0 &&
                        <div className="footer-show height-72">
                          <CommentBox
                            ref={this.ChildComment}
                            hotelid={this.state.hotelValue}
                            entryType={"JobOrder"}
                            uniqueNo={this.state.jobid}
                            precommentlist={this.state.htmlcomment}
                            parentScrollMethod={this.scrollToBottom}
                            updatedCommentItem={this.updatedCommentItem}
                            commentModalClose={this.CommentModalClose}

                          />
                        </div>

                      )}

                    </div>
                
                  </section>

                  <div className={"footer-section-new viewer-panel"}
            style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
          >
            <div>
              <div className="d-flex gap-3 align-items-center justify-content-end height-72">
              {this.state.isStateChanged && <div className="mr-auto message">
                  <span style={{color: '#065a8c'}}>You have unsaved changes</span>
                </div>}
                <div className="d-flex gap-3 align-items-center">
                  {
                    (
                      <>
                        <Button
                          variant="primary"
                          type="button"
                          className="space btn btn-primary btn-discard"
                          onClick={() =>
                            this.handleDiscard(this.state.orderUniqueNo)
                          }
                        >
                          Discard
                        </Button>

                        {(!this.state.processVendorPOCreated || !this.state.materialVendorPOCreated)
                         
                          && (this.state.reworkCount == 0 || this.state.reworkCount == null)
                          && <Button
                            id="btnSave"
                            variant="primary"
                            type="button"
                            onClick={() => this.showSaveProcessTemplatePopup(0)}
                            disabled={
                              this.state.formData.CustomerID === 0
                                ? true
                                : this.state.isDisabled
                            }
                          // onKeyDown={(e: any) =>
                          //   this.onTAB("btnSave-1", 0, e)
                          // }
                          >
                            Save
                          </Button>}
                        {(!this.state.processVendorPOCreated || !this.state.materialVendorPOCreated)
                         
                          && (this.state.reworkCount == 0 || this.state.reworkCount == null)

                          && <Button
                            id="btnSave"
                            variant="primary"
                            type="button"
                            onClick={() => this.showSaveProcessTemplatePopup(1)}
                            disabled={
                              this.state.formData.CustomerID === 0
                                ? true
                                : this.state.isDisabled
                            }

                          >
                            Save & Close
                          </Button>}

                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
                  <br /><br /><br /><br /><br />
               
                </Container>

              </div>
              {(showVSlideout &&
                <Vendororderslideout
                  closeSlideOut={this.closeSlideOut2}
                  orderid={this.state.orderid}
                  CustomerPoNumber={this.state.CustomerPoNumber}
                  poNumber={this.state.poNumber}
                  productid={this.state.productid}
                  partname={this.state.partname}
                  partNo={this.state.partNo}
                  qtyOrdered={this.state.qtyOrdered}
                  jobid={this.state.jobid}
                  itemNo={this.state.itemNo}
                  vendororderid={this.state.vendororderid}
                  vendorordertype={this.state.vendorordertype}
                  selectedCustomer={this.state.selectedCustomer}
                  orderdate={this.state.orderdate}
                  dueDate={this.state.dueDate}
                  jobDesc={this.state.jobDesc}
                  id={this.state.id}
                >
                </Vendororderslideout>
              )}

              {this.state.ispopupshow && (
                 <div
               //className="d-flex align-items-center pos-fxd2 index-1000"
               >
                 <div className="react-confirm-alert-overlay confirm-modal undefined">
                  <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                        <div>
                          <div className="d-flex">
                            <h1 style={{ paddingTop: "6px!important" }}> Save As Template</h1>
                            <div className="header-popout ml-auto">
                              <button
                                type="button"
                                className="btn wht-bg cross"
                                onClick={this.handleCloseModel}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        Would you like to save current process as a template. If yes, please specify template name below.



                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label className="font-semibold">

                          </Form.Label>



                          <Form.Control
                            tabIndex={this.state.tabEnable}
                            id="txtTemplateName"
                            type="text"
                            placeholder="Enter Template Name"
                            value={this.state.templatename}
                            onChange={(e: any) => this.handleChange(e, "templatename")}
                            //autoComplete="off"
                            //disabled={disable}
                            title=""
                          />
                        </Form.Group>
                        <br></br>
                        <div className="react-confirm-alert-button-group">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => this.handleTemplateButton('no')}
                          >
                            No
                          </button>

                          <div className="upload-link">
                            <button type="button" className="btn"
                              onClick={() => this.handleTemplateButton('yes')}
                              disabled={this.state.templatename.trim() === ''}
                            >
                              Yes
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}


{this.state.showProcessPopup && (
               <div
               //className="d-flex align-items-center pos-fxd2 index-1000"
               >
                 <div className="react-confirm-alert-overlay confirm-modal undefined">
                    <div className="react-confirm-alert">
                      <div className="react-confirm-alert-body jobprocess-popup-width">
                        <div>
                          <div className="d-flex">
                         
                            <div className="header-popout ml-auto">
                              <button
                                type="button"
                                className="btn wht-bg cross"
                                onClick={(e)=>{this.setState({ showProcessPopup: false });}}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                    fill="#84888C"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                    


                        <div className="container my-3" >
                <div className="row">
                  <div className="col-4">
                  <div style={{height:'400px', overflowY:'scroll'}}>
                  
                  
                  <div className="process" >
                  <div className="left-panel" >
            
                    
                    <Tab.Container   transition={false} defaultActiveKey="first" >
    
    <section className='p-2'>
<Nav   className="nav nav-underline border-bottom">
<Nav.Item className="nav-item">
  <Nav.Link eventKey="first">Template 
  
  </Nav.Link>
</Nav.Item>
<Nav.Item className="nav-item">
  <Nav.Link eventKey="second">Part 
  
          </Nav.Link>
</Nav.Item>
<Nav.Item className="nav-item">
  <Nav.Link eventKey="third">Job 
  
          </Nav.Link>
</Nav.Item>
</Nav>
</section>

<Tab.Content>
<Tab.Pane eventKey="first">

{true && 
                   
                   
                   
                   
                   <ToolkitProvider
                   keyField="id"
                   data={this.state.templatelistitem}
                             columns={this.state.columns}
                    //className="table table-striped table-borderless align-middle"
                   search
                 >
                   {(props: {
                           searchProps: JSX.IntrinsicAttributes;
                           baseProps: JSX.IntrinsicAttributes;
                         }) => (
       
       
                       <div >
                         <section className="d-flex flex-wrap">
                         <div className="search-header w-auto px-2 pb-2">
                               <SearchBar
                                 {...props.searchProps}
                                 placeholder={"Search"}
                               />
                             </div>
       
                           <div 
                           //className="input-group w-auto"
                           >
                             
                           </div>
       
                         </section>
                         <section >
                         
                             <BootstrapTable
                           
                               {...props.baseProps}
                              // rowEvents={this.rowEvents}
                               //pagination={paginationFactory(options)}
                               hover
                               condensed
                             //  rowClasses={ rowClasses }
                                //rowEvents={this.rowEvents}
                                selectRow = {{
                                 mode: 'radio',
                                 clickToSelect: true,
                                 classes: "table-active",
                                 // style: { backgroundColor: '#c8e6c9' },
                                 hideSelectColumn: true,  
                                 onSelect: this.handleTemplateOnSelect,
                                 selected: [this.state.selectedTemplateId],
                               }}
                             />
                           
                         </section>
                       </div>
       
                     )
                   }
                 </ToolkitProvider>
                 
                 
                           
                           }

</Tab.Pane>
<Tab.Pane eventKey="second">

{true && 
                   
                    
                   
                   
                   <ToolkitProvider
                   keyField="jobid"
                                             data={
                          //  this.state.partData
                          this.state.partData.filter((ele, ind) => ind === this.state.partData.findIndex(elem => elem.partno === ele.partno && elem.partname === ele.partname && ele.partname !== ""))
                          }
                           columns={this.state.partcolumns}
                //   search
                 >
                   {(props: {
                           searchProps: JSX.IntrinsicAttributes;
                           baseProps: JSX.IntrinsicAttributes;
                         }) => (
       
       
                       <div >
                         <section className="d-flex flex-wrap">
                         <div className="search-header w-auto px-2 pb-2">
                          <Form.Control
              tabIndex={this.state.tabEnable}
              id="search"
              type="text"
              placeholder="Search"
              // value={row.glcode}
                onChange={(e) => this.Getpreviousjob(e,"part")}
              // autoComplete="off"
              // onKeyDown={(e: any) => this.onTAB("glcode", rowIndex, e)}
              // disabled={disable}
              title=""
            />
                             </div>
       
                           <div 
                           //className="input-group w-auto"
                           >
                             
                           </div>
       
                         </section>
                         <section >
                         
                             <BootstrapTable
                           
                               {...props.baseProps}
                              // rowEvents={this.rowEvents}
                               //pagination={paginationFactory(options)}
                               hover
                               condensed
                             //  rowClasses={ rowClasses }
                                //rowEvents={this.rowEvents}
                                selectRow = {{
                                 mode: 'radio',
                                 clickToSelect: true,
                                 classes: "table-active",
                                 // style: { backgroundColor: '#c8e6c9' },
                                 hideSelectColumn: true,  
                                 onSelect: this.handlePartOnSelect,
                                 selected: [this.state.selectedTemplateId],
                               }}
                             />
                           
                         </section>
                       </div>
       
                     )
                   }
                 </ToolkitProvider>
                      
                   
                   
                
       
//                        <div >
//                          <section className="d-flex flex-wrap">
//                          <div className="search-header w-auto px-2 pb-2">
//                                {/* <SearchBar
//                                  {...props.searchProps}
//                                  placeholder={"Part No / Job No"}
//                                /> */}

// <Form.Control
//               tabIndex={this.state.tabEnable}
//               id="search"
//               type="text"
//               placeholder="Search"
//               // value={row.glcode}
//                 onChange={(e) => this.Getpreviousjob(e,"part")}
//               // autoComplete="off"
//               // onKeyDown={(e: any) => this.onTAB("glcode", rowIndex, e)}
//               // disabled={disable}
//               title=""
//             />

//                              </div>
       
//                            <div 
//                            //className="input-group w-auto"
//                            >
                             
//                            </div>
       
//                          </section>
//                          <section >
//                          <div className='process'>
//                              <BootstrapTable
//                            id="tblpart"
//                              keyField="jobid"
//                            data={
//                           //  this.state.partData
//                           this.state.partData.filter((ele, ind) => ind === this.state.partData.findIndex(elem => elem.partno === ele.partno && elem.partname === ele.partname && ele.partname !== ""))
//                           }
//                            columns={this.state.partcolumns}
                            
//                                hover
//                                condensed
//                                 selectRow = {{
//                                  mode: 'radio',
//                                  clickToSelect: true,
//                                  classes: "active-row",
//                                  // style: { backgroundColor: '#c8e6c9' },
//                                  hideSelectColumn: true,  
//                                  onSelect: this.handlePartOnSelect,
//                                  selected: [this.state.selectedTemplateId],
//                                }}
//                               //   selectRow = {{
//                               //    mode: 'radio',
//                               //    clickToSelect: true,
//                               //    classes: "table-active",
//                               //    hideSelectColumn: true,  
//                               //    onSelect: this.handlePartOnSelect,
//                               //    selected: [this.state.selectedTemplateId],
//                               //  }}
//                              />
//                              </div>
                             
//                          </section>
//                        </div>
       
                   
               
       
                           }

</Tab.Pane>
<Tab.Pane eventKey="third">

{true && 
                   
                   
                   
                   
                
       
                       <div >
                         <section className="d-flex flex-wrap">
                         <div className="search-header w-auto px-2 pb-2">
                               {/* <SearchBar
                                 {...props.searchProps}
                                 placeholder={"Part No / Job No"}
                               /> */}

<Form.Control
              tabIndex={this.state.tabEnable}
              id="searchjob"
              type="text"
              placeholder="Search"
              // value={row.glcode}
                onChange={(e) => this.Getpreviousjob(e,"job")}
              // autoComplete="off"
              // onKeyDown={(e: any) => this.onTAB("glcode", rowIndex, e)}
              // disabled={disable}
              title=""
            />

                             </div>
       
                           <div 
                           //className="input-group w-auto"
                           >
                             
                           </div>
       
                         </section>
                         <section >
                          
                             <BootstrapTable
                           id="tbljob"
                             keyField="jobid"
                           data={
                            
                           this.state.partData
                          
                          
                          }
                           columns={this.state.jobcolumns}
                            
                               hover
                               condensed
                                
                                //rowEvents={this.rowEvents}
                                selectRow = {{
                                 mode: 'radio',
                                 clickToSelect: true,
                                 classes: "table-active",
                                 hideSelectColumn: true,  
                                 onSelect: this.handlejobOnSelect,
                                 selected: [this.state.selectedTemplateId],
                            
                                }}
                             />
                          
                         </section>
                       </div>
       
                   
               
       
                           }

</Tab.Pane>
</Tab.Content>

</Tab.Container>
                   
                    
                    </div>
                    </div> 
                    </div>
                  </div>
                  <div className="col-8" >
                  <div style={{height:'400px', overflowY:'scroll'}}>

                 
                  
                  <div   className="" style={{ opacity : (this.state.isPopupSpinner ? 0.3 :1), pointerEvents: (this.state.isPopupSpinner ? 'none':'auto')}}>
                  {(this.state.isPopupSpinner) && (
                  <div style={{position:'fixed',zIndex:'9999',left:'240px',top:'300px'}}>
                   <Spinner animation="border" variant="success" />
                  </div>
            
          )}

                 { true &&
                 <>
                 {/* <div className="d-flex align-items-center p-2">
             <h1>  {this.state.templateType == 'Part' ? this.state.selectedJobNo : this.state.selectedTemplateName}</h1>
              
               </div> */}
                  <div className="d-flex align-items-center">
                 <BootstrapTable
                      keyField="id"
                      data={this.state.processData}
                      columns={this.state.pcolumns}
                    />
                    </div>
                    </>
                    }
                          
                    </div>
                  
                    </div>

                  
                   
                   </div>
          

                 
                </div>
              </div>
                       
<div className="react-confirm-alert-button-group">
            
                  {
                    (
                      <>
                      
                        <Button
                          variant="primary"
                          type="button"
                          className="space btn btn-primary btn-discard"
                          onClick={(e) => {this.applyTemplate();}}
                          
                        >
                          Apply
                        </Button>

                        <Button
                          variant="primary"
                          type="button"
                          className="space btn btn-primary btn-discard"
                          onClick={(e)=>{this.setState({ showProcessPopup: false });}}
                        >
                          Discard
                        </Button>
                      </>
                    )
                  }
                 
                
           
          </div>
                      </div>
                    </div>
            
                  </div>
                </div>
              )}

    

            </Container>
            {(this.state.isSpinner || this.state.isSaveSpinner) && (
              <div className="loader-spinner d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="success" />
              </div>
            )}


          </Container>

      



        </div>

      </section>
    );
  }
}
