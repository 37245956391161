import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { faArrowUp, faListSquares } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { PartTemplateService } from "../../Common/Services/PartTemplateService";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import _ from "lodash";
import { ConfirmationModal } from "../../Common/Components/ConfirmationModal";
import createDOMPurify from "dompurify";
import { Utils } from "../../Common/Utilis";
import moment from "moment";
import { ProductService } from "../../Common/Services/ProductService";
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
export class PartTemplateSlideout extends React.Component<any, any> {
  private parentScroll: any;
  private singleSerch: any;

  constructor(props: any) {
    super(props);
    this.handleNewRow = this.handleNewRow.bind(this);
    this.GetProcesslist = this.GetProcesslist.bind(this);
    this.GetTemplateData = this.GetTemplateData.bind(this);
    this.state = {
      templateid: this.props.templateid,

      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],

      templatename: "",
      processdataList: [],
      templatelistitem: [],
      templateColumns: this.templateColumns,
      formData: {
        id: this.props.templateid,
        tname: "",
        TDetails: [],





      },
      templateData: [
        {
          processList: [],
          processName: "", processid: 0, srno: 1, type: 'Work', tDescription: '', estimatehour: 0,
        }

      ],

      newRow: [
        {
          processList: [],
          processName: "", processid: 0, srno: 1, type: 'Work', tDescription: '', estimatehour: 0,
        }

      ],
    };


  }
  componentDidMount() {
    let requestlist = { ...this.state.requestlist };
    this.GetProcesslist(requestlist);
  }

  handleRowOrdering = (e: any, row: any, type: any) => {
    
    let templateData = this.state.templateData;
     
            
            let nextOrderNo = type == 'down' ? row.srno + 1 : row.srno - 1;
            let currentRow = templateData.filter((x) => x.srno == row.srno);
            let nextRow = templateData.filter((x) => x.srno == nextOrderNo);
        
            if (nextRow != null && nextRow.length > 0) {
        
              nextRow[0].srno = row.srno;
              currentRow[0].srno = nextOrderNo;
        
        
              templateData = templateData.sort((a, b) => a.srno - b.srno);
        
              this.setState({ templateData: [] }, () => {
                this.setState({ templateData: templateData,isStateChanged:true });
              });
        
              this.ReorderProcess();
            }
        
    };
  ReorderProcess = () => {
            //debugger;
            let request = { ...this.state.requestlist };
            let templateData = this.state.templateData;
            let  postdata:any = [];
            templateData.forEach((element, idx) => {
              let item: any = {};
    
              item.id = element.id ==null ?0 :element.id ;
              item.srno =idx + 1;
              postdata.push(item);
             
              
            });

            // PartTemplateService.UpdateTMasters(postdata).then(
            //   async (result: any | null) => {
                
            //       let requestlist = { ...this.state.requestlist };
            //       //his.getprocessMaster(requestlist);
                
            //       //toast.error("S updated",
            //       //  { position: toast.POSITION.BOTTOM_RIGHT, containerId: "userManagementHierarchy" });
                  
            //       //  this.setState({ isSaveOpen: false ,loading: false });
            //   }
            // );
        
        
        
          
          };

  private templateColumns = [

    {
      dataField: 'srno',
      text: 'Step No.',

    },
    {
      dataField: "isSelected",
      text: "",
      classes: "expanding-bar",
      headerStyle: { width: '10px' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center txt-bold">

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="toolp">Click to move up this step</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                size="xs"
                onClick={(e) => this.handleRowOrdering(e, row, 'up')}
              />
            </OverlayTrigger>
          </div>
        );
      },
    },

    {
      dataField: "isSelected",
      text: "",
      classes: "expanding-bar",
      headerStyle: { width: '10px' },
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center txt-bold">

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="toolp">Click to move down this step</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faArrowDown}
                size="xs"
                onClick={(e) => this.handleRowOrdering(e, row, 'down')}
              />
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      headerStyle: { width: '150px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txttype" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Type" value={row.type}
              onChange={(e) => this.updateState(e, rowIndex, "type", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },
    },
    {
      dataField: 'processName',
      text: 'Process',
      headerStyle: { width: '200px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtprocess" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            {/* <Form.Control tabIndex={this.state.tabEnable}
                    id={id}
                    type="text" placeholder="Enter Process" value={row.process}
                   // onChange={(e) => this.updateState(e, rowIndex, "partNo", id)}
                    autoComplete='off'
                    disabled={disable}
                    title=""
                    maxLength={500}
                /> */}


            <SingleSearchDropdownList
              tabIndex={this.state.tabEnable}
              itemList={row.processList}
              handleSelectedItem={this.handleSelectedItem.bind(row, 1 + '_' + rowIndex)}
              defaultItem={row.processName}
              defaultText={"Search Process"}
              defaultName={row.processName}
              controlID="1"
              id={"ddlprocess-" + rowIndex + "ddl"}
            //onTAB={this.onTAB.bind(this, "ddlprocess-" + rowIndex + "ddl", rowIndex)}
            // disabled={disable}
            />
          </div>
        );



      },

    },
    {
      dataField: 'tdescription',
      text: 'Description',
      headerStyle: { width: '400px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtdescription" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Description" value={row.tdescription}
              onChange={(e) => this.updateState(e, rowIndex, "description", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },

    },
    {
      dataField: 'estimatehour',
      text: 'Estimate Hours',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtestimate" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control tabIndex={this.state.tabEnable}
              id={id}
              type="text" placeholder="Enter Estimate Hours" value={row.estimatehour}
              onChange={(e) => this.updateState(e, rowIndex, "estimate", id)}
              autoComplete='off'
              disabled={disable}
              title=""
              maxLength={500}
            />
          </div>

        );



      },

    },

    {
      dataField: 'Attachment',
      text: '',
      hidden:false,
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let confirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        if (this.state.templateData.length === 1) {
          return (
            <div></div>
          );
        }


        else {
          return (
            <Dropdown className="more-action confirmation-modal"  tabIndex={this.state.tabDisable}>
              <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ConfirmationModal
                  confirmModalContent={confirmModalContent}
                  ishandleDeletePosition={this.ishandleDeleteRow.bind(
                    this,
                    row
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      },
    }
  ];

  handleSelectedItem = (control: any, id: any) => {
    let isStateChanged = true;
    let formData = { ...this.state.formData };
    let controlID = Number(control.split('_')[0]);
    let rowIndex = Number(control.split('_')[1]);
    let templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    if (controlID === 1) {
      let filterRow = this.state.processdataList.filter(x => x.id === id);
      //templateData[rowIndex].processList = this.state.processdataList.filter(x => x.id === filterRow[0].id);

      templateData[rowIndex].processName = filterRow[0].name;
      templateData[rowIndex].processid = filterRow[0].id;
      templateData[rowIndex].tDescription = filterRow[0].pDescription;

      this.setState({ templateData: [] }, () => {
        this.setState({ templateData, isStateChanged }, () => {
          
          let txtID = "txtdescription" + rowIndex + "txt";;
          $("#tbltemplate").find("input[id^='" + txtID + "']").focus();
        });
      });

    }

  };
  updateState = (e: any, index: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;
      let templateData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.templateData))
      );


      if (type === "type") {
        e.preventDefault();

        templateData[index].type = e.target.value;

        this.setState({ templateData, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      if (type === "description") {
        e.preventDefault();

        templateData[index].tdescription = e.target.value;

        this.setState({ templateData, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }

      if (type === "estimate") {
        e.preventDefault();
        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;
        ///const rx_live = /^[-]?\d*(?:[.]\d*)?$/;
        templateData[index].estimatehour = e.target.value;

        this.setState({ templateData, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }



    }
  };
 



  GetTemplateData = () => {
    let saveRequest: any = {};
    saveRequest.id = this.state.templateid;
    saveRequest.tenantid = 0;

    let templateData: any = [];
    // templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    PartTemplateService.GetTemplatedata(saveRequest)
      .then(async (result: any | null) => {
        if (result !== null) {
          
          let formData = { ...this.state.formData };
          formData.tname = result[0].tname;
          let orderArray: any = [];
          orderArray = result[0].tDetails;
          if (orderArray.length > 0) {
            orderArray.forEach((element, idx) => {
              
              let filterRow = this.state.processdataList.filter(x => x.id === orderArray[idx].processid);

              element.processList = this.state.processdataList;
              element.processName = filterRow.length > 0 ? filterRow[0].name : '';
              element.processid = orderArray[idx].processid;
              element.templateno = idx + 1;
              templateData.push(element);
            });
          }
          else {
            templateData = Object.assign(
              [],
              JSON.parse(JSON.stringify(this.state.templateData))
            );
          };

          this.setState({
            tempateData: templateData,
            formData: formData,
          },
            () => {
              this.setState({ tempateData: [] }, () => {
                this.setState({ templateData: templateData });
              });
              console.log(templateData);
            });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  };

  ishandleDeleteRow(row: any, mode: any) {
    if (mode) {

      let templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));

      this.setState({ templateData: [] }, () => {


        const tempateDataNewc = templateData.filter(item => item.templateno !== row.templateno).map((item, index) => {
          item.templateno = index + 1;
          return item;
        });

        this.setState({
          templateData: tempateDataNewc
          , isStateChanged: 1
        },
          () => {


          });
      });
    }
  }

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);

  };

  handleNewRow = () => {
    let templateData: any = [];
    let newRow: any = {};
    let isStateChanged = true;
    templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.newRow)));

    let maxValueOfRow = Math.max(...templateData.map(o => o.srno), 0);
    let len = templateData.length;
    let maxRno = maxValueOfRow;
    let maxSrno = templateData[len - 1].srno;
    newRow[0].srno = Number(maxSrno) + 1;
    newRow[0].templateno = len + 1;
    let filterRow = this.state.processdataList.filter(x => x.srno === Number(maxSrno) + 1);

    if (filterRow.length != 0) {
      newRow[0].tDescription = filterRow[0].pDescription;

      newRow[0].estimatehour = 0;
      newRow[0].processName = filterRow[0].name;
      newRow[0].processid = filterRow[0].id;
     
    }
    templateData.push(newRow[0]);
    this.setState({ tempateData: [] }, () => {
      this.setState({ templateData: templateData, isStateChanged });

    });




  };

  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

  GetProcesslist = (requestlist: any) => {


    let TypeUserlist: any = [];
    let templateData: any = [];
    templateData = Object.assign([], JSON.parse(JSON.stringify(this.state.templateData)));
    let newRow = this.state.newRow;
    PartTemplateService.GetProcessList(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          result.forEach(element => {
            let item: any = {};
            item.id = element.id;
            item.name = element.processName;
            TypeUserlist.push(item);
          });

          templateData.forEach(element => {
            element.processList = TypeUserlist;

          });
          newRow.forEach(element => {
            element.processList = TypeUserlist;

          });
          //  newRow.processList=result;


          this.setState({ templateData: [], processdataList: [] },
            () => {
              this.setState({
                templateData: templateData,
                newRow: newRow,
                processdataList: TypeUserlist,
              }
                ,
                () => {
                  let formData = { ...this.state.formData };

                  this.GetTemplateData();

                  console.log(templateData);
                }

              );
            });
        }
        resolve();
        // console.log(this.state.processList);
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });

  };



  HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


  savetemplate = () => {

    let formData = { ...this.state.formData };
    this.setState({ isSaveSpinner: true });

    let isFormValid = true;
    let request: any = {};


    let templateData = this.state.templateData;

     templateData.forEach((element, idx)  => {
      element.srno =idx + 1;
     });

    this.state.formData.TDetails = templateData;
    //this.state.templatelistitem;



    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));

    if(templateData.filter((x)=>x.processName == ''|| x.processName.length == 0).length > 0)
    {
      this.setState({ isSaveSpinner: false });
      alert("Please enter the Process.");
      return;
    }


    if (this.state.formData.tname === "") {
      toast.error("Please enter template name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });
      this.setState({ isSaveSpinner: false });
      return false;
    }


    isFormValid = true;


    if (isFormValid) {
      this.setState({ saveRequest: request, ispopupshow: false });

      PartTemplateService.SaveTemplateData(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            
            this.setState({ isSaveSpinner: false });


            toast.success(result.result, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
          }
          if (result.message == "Success") {
            this.HidecloseSlideOut();
          }


          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSaveSpinner: false });
          reject();
        });
    }
  };

  handleChange = (event: any, FieldType: string) => {
    let isStateChanged = true;
    let start = 0;
    let end = 0;

    let formData = { ...this.state.formData };

    if (FieldType === "templatename") {
      formData.tname =
        event.target.value.trim().length > 0 ? event.target.value : "";
    }

    this.setState({ formData, isStateChanged, isSpinner: false }, () => {

    });
  };


  render() {
    const {

      showjobrModal,

    } = this.state;


    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.isOpenViewer && (
              <div
                className="back-drop1  viewer-panel1 opacity "
                onClick={() => this.closeSlideOut()}
              ></div>
            )}

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center  border-bottom round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">
                  
                  {this.state.templateid == 0 && <span>New Part Template</span>}
              {this.state.templateid != 0 && (
                <span>
                  Template Name : {this.state.formData.tname}
                </span>
              )}

                  </h5>


                  <div className="header-popout ml-auto">




                  <div className="container text-center">
  <div className="row align-items-start">
    <div className="col">
      </div>

      <div className="col">
      <button
                          type="button"
                          className="btn btn-primary cross"
                          onClick={this.closeSlideOut}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </button>
      </div>
      </div>
      </div>
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customer">
                      <Form.Label className="font-semibold">Template Name</Form.Label>
                      <div
                        tabIndex={this.state.tabEnable}
                        className="type-head"
                      >
                        <Form.Control
                          type="text"
                          id="txtTemplate"
                          value={this.state.formData.tname}
                          onChange={(event: any) =>
                            this.handleChange(event, "templatename")
                          }
                          autoComplete="off"
                          title=""
                        />
                      </div>
                      </Form.Group>

                    </div>
               
                    
                  </div>
                </section>

                <section className="pb-3">

                  <div
                    className="table table-borderless align-middle transactions-list"

                  >
                      {this.state.templateColumns.length > 0 && (
               
                    <BootstrapTable
                      id="tbltemplate"
                      keyField="templateid"
                      data={this.state.templateData}
                      columns={this.state.templateColumns}
                    />
               
                )}
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                      <div className="d-flex action-btn align-items-center">
                        <Button
                          type="button"
                          className="btn-ghost mr-auto"
                          onClick={this.handleNewRow}
                        >
                          <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Row</span>
                        </Button>

                       
                        
                             
                      </div>
                    </div>

                    <div className="filter-sec height-172">

<br></br>
<br></br>

</div>
                </section>

              
              </div>
              <div className={"footer-section viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
<div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">

                      {this.state.isStateChanged && (
                        <>
                          <Button variant="primary" type="button" className="space btn btn-primary btn-discard" onClick={() => this.handleDiscard(this.state.orderUniqueNo)}>
                            Discard
                          </Button>
                          <Button id="btnSave" variant="primary" type="button"
                            onClick={() => this.savetemplate()}
                          // disabled={this.state.formData.tname === "" ? true : this.state.isDisabled}
                          //onKeyDown={(e: any) => this.onTAB('btnSave-1', 0, e)}

                          >{"Save"}</Button>

                        </>
                      )}
                      </div>
</div>
                </div>
            </Container>
          </div>


          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}






        </div>

      </section>
    );
  }
}
