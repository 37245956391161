import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import { Container, Dropdown, Accordion, Card,Spinner, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../../Common/Services/VendorService";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { Customerorderslideout } from '../Customerorderslideout';
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import {InvoiceEntryslideout}  from './InvoiceEntryslideout';
const { SearchBar } = Search;

const DOMPurify = createDOMPurify(window);

export class InvoiceApproval extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.bindfilter = this.bindfilter.bind(this);
    this.state = {
      footershow: false,
      selectedRows: [],
      invoiceTotal: 0,
      isstatus: true,
      isorderdate: true,
      iscustomername: true,
      iscustomercode: true,
      iscustomerpo: true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      invoiceList: [],
      invoiceId: 0,
      orderIds: [],
      selectedcolumn: "All",
      selectOptions: [
        {

          id: 'All', name: 'All'
        },
        {

          id: 'Vendor PO', name: 'Vendor PO'
        },
        { id: 'Vendor Code', name: 'Vendor Code' },
        { id: 'Vendor Name', name: 'Vendor Name' },
        { id: 'Order Date', name: 'Order Date' },
        { id: 'Status', name: 'Status' },

      ],
      formData:{
        InvoiceIds:[],
      }
    };


  }


  componentDidMount() {
    this.getInvoiceList();
  }




  getInvoiceList = () => {
    let requestlist = { ...this.state.requestlist };
    InvoiceService.GetVendorInvoiceList(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
      // console.log(result);
      this.setState({ invoiceList: result }, () => {
        this.bindfilter();
      });
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true, orderid: 0, isSpinner: false, });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };

  approveInvoice = (row: any) => {

      
      let invoices = this.state.invoiceList;
      invoices.forEach(element => {
         element.selected = (element.id == row.id);
      });
      this.approveInvoices();
  };

  approveInvoices = () => {
    this.setState({ isSaveSpinner: true });
    let isFormValid = false;
    let InvoiceIds = this.state.invoiceList.filter(x => x.selected === true).map(r => r.id);
  
    
    let request: any = {};
    this.state.formData.InvoiceIds = InvoiceIds;  
  
    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
    isFormValid = true;
  
    if (isFormValid) {
      InvoiceService.ApproveVendorInvoice(request)
        .then(async (result: any | null) => {
          if (result !== null) {
             this.setState({ isSaveSpinner: false });
             toast.success('Approved information Saved.', {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "OrderSlideout",
            });
            this.getInvoiceList();
            this.setState({ footershow: false });

          }
  
          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner: false, });
    this.getInvoiceList();
  };


  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      
      if (e.target.tagName !== 'BUTTON') 
      {
          this.setState({
            showSlideout: true,
             orderid: row.orderId,
             invoiceId: row.id,
            isSpinner: false,
          });
      }
    },
  };

  bindfilter = () => {
    let selectOptions = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectOptions))
    );

    this.setState({ selectOptions: selectOptions });

  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

    let chkisstatus = id == "Status" ? true : false;
    let chkisorderdate = id == "Order Date" ? true : false;
    let chkiscustomername = id == "Customer Name" ? true : false;
    let chkiscustomercode = id == "Customer Code" ? true : false;
    let chkiscustomerpo = id == "Customer PO" ? true : false;


    if (id == "All") {
      chkisstatus = true;
      chkisorderdate = true;
      chkiscustomername = true;
      chkiscustomercode = true;
      chkiscustomerpo = true;

    }


    this.setState({
      searcResult: [], selectedcolumn: id, isstatus: chkisstatus, isorderdate: chkisorderdate,
      iscustomername: chkiscustomername, iscustomercode: chkiscustomercode, iscustomerpo: chkiscustomerpo
    },
      () => {
        this.setState({ searcResult: searcResult1 });

      }


    );

  };

  
  handleOnSelect = (row, isSelect) => {
    let { invoiceTotal, selectedRows, selectedcustomerid, selectedRowscustomerid } = this.state;

    let invoiceList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.invoiceList))
    );

    let listItem = invoiceList.filter(
      (x) => x.id === row.id
    );

      if (isSelect) {
        invoiceTotal += row.totalAmount;
        //selectedcustomerid = row.customerID;
      }
      else {
        invoiceTotal -= row.totalAmount;
        //selectedcustomerid = 0;
      }


      if (isSelect) {
        selectedRows = [...this.state.selectedRows, row.id];
       // selectedRowscustomerid = [...this.state.selectedRowscustomerid, rows[0].customerID];


      } else {
        selectedRows = this.state.selectedRows.filter(x => x !== row.id);
        //selectedRowscustomerid = this.state.selectedRowscustomerid.filter(x => x !== rows[0].customerID);
      }
    
  
      listItem[0].selected = isSelect;
      let selectedCount = invoiceList.filter(x=>x.selected == true).length;

      
  
    this.setState({ invoiceList: invoiceList, footershow: selectedCount > 0 ? true :false, invoiceTotal: invoiceTotal, selectedRows: selectedRows });


  };

  handleOnSelectAll = (isSelect, rows) => {

    const ids = rows.map(r => r.id);
    let { invoiceTotal, selectedRows, selectedcustomerid, selectedRowscustomerid } = this.state;
    let invoiceList = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.invoiceList))
    );

    let listItems = invoiceList.filter(
      (x) => ids.includes(x.id)
    );




      if (isSelect) {
        invoiceTotal += rows[0].totalAmount;
        selectedcustomerid = rows[0].customerID;
      }
      else {
        invoiceTotal -= rows[0].totalAmount;
        selectedcustomerid = 0;
      }


      if (isSelect) {
        selectedRows = [...this.state.selectedRows, rows[0].id];
      } else {
        selectedRows = this.state.selectedRows.filter(x => x !== rows[0].id);
      }
    
    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
          element.selected = isSelect;
      });
    };

    this.setState({ invoiceList: invoiceList, footershow: isSelect ? true :false, invoiceTotal: invoiceTotal, selectedRows: selectedRows, selectedRowscustomerid: selectedRowscustomerid });
  };


  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };


    const invoiceColumns = [
      {
        dataField: 'invoiceNo',
        text: 'Invoice No.',
        sort: true,
        headerStyle: { width: '100px' },
        searchable: this.state.iscustomerpo,
      },
      {
        dataField: 'invoiceDate',
        text: 'Invoice Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.invoiceDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'vendorName',
        text: 'Vendor Name',
        sort: true,
        searchable: this.state.iscustomername,
      },


      {
        dataField: 'dueDate',
        text: 'Due Date',
        sort: true,

        headerStyle: { width: '100px' },
        searchable: this.state.isorderdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.orderDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      
      {
        dataField: 'accountingPeriod',
        text: 'Accounting Period',
        sort: true,
        searchable: this.state.iscustomername,
        // headerStyle: { width: '300px' },
      },

      {
        dataField: 'totalAmount',
        text: 'Total Amount',
        headerClasses: 'amount',
        classes: 'text-end pe-4 divgreen',
        sort: true,
        searchable: false,
        headerStyle: { width: '100px' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {Utils.currencyFormat(row.totalAmount)}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: '',
        text: '',

        sort: false,
        searchable: false,
        headerStyle: { width: '100px' },

      },

      
      // {
      //   dataField: 'Approve',
      //   text: '',
      //   sort: true,
      //   searchable: false,
      //   //headerStyle: { width: '300px' },
      //   formatter: (cell: any, row: any, rowIndex: any) => {


      //     return (
      //       <EllipsisWithTooltip placement="bottom">
      //         {<Button className='btn btn-primary btn-sm py-0 btn-sml'  onClick={(x) => this.approveInvoice(row)}>Approve Invoice</Button>}
      //       </EllipsisWithTooltip>
      //     );
      //   },
        {
          dataField: '',
          text: 'Action',
  
  
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <div className="btn-group">
                <Dropdown
                  onClick={(e) => { e.stopPropagation(); }}
                  className="more-action"
                  >
                  <Dropdown.Toggle
                    className="btn-outline-primary btn btn-primary-white more"
                    id={"dropdown-more" + rowIndex}
  
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                      <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                      <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={"comments"} onClick={(x) => this.approveInvoice(row)}>Approve </Dropdown.Item>
                    
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
     

    ];

    const selectOptions = [{
      id: 0, column: 'Invoice No.	'
    },
    { id: 1, column: 'Vendor Code' },
    { id: 2, column: 'Vendor Name' },
    { id: 3, column: 'Invoice Date' },
    { id: 4, column: 'Due Date' },

    ];

    const {

      showSlideout,

    } = this.state;

 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

              Invoice Approval
              <div className="action-group d-flex flex-row ml-auto">
                {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
              </div>
            </div>

            <Tab.Container transition={false} defaultActiveKey="first" >

              <section className="pb-3">
                <Nav className="nav nav-underline border-bottom">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="first">Approval <span className="badge badge-light"> {this.state.invoiceList.length}</span>


                    </Nav.Link>
                  </Nav.Item>
                   
                </Nav>
              </section>

              <Tab.Content>
                <Tab.Pane eventKey="first">

                  <ToolkitProvider
                    keyField="id"
                
data={this.state.invoiceList}
columns={invoiceColumns}
                     
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                  {...props.baseProps}
                                    rowEvents={this.rowEvents}
                                    selectRow={{
                                      mode: 'checkbox', clickToSelect: false, hideSelectAll: true,
                                      selected: this.state.invoiceList.filter((x) => x.selected === true).map(r => r.id),
                                      onSelect: this.handleOnSelect,
                                      onSelectAll: this.handleOnSelectAll,
                                      hideSelectColumn: false
                                    }}
                                   pagination={paginationFactory(options)}

                                />
                                   {(this.state.footershow &&
        <div className="TabFooter-Pay"
        style={{ minWidth: "83.7%", width: "992px", maxWidth: "100%" }}
        >
          <div className="bulkSelectFooter">
            <span className="shift-selected-count">{this.state.selectedRows.length}
              {" "}Selected</span>
            <div className="FooterHorLine"></div>
            <span className="shift-selected-count">Invoice Total: {Utils.currencyFormatWithOutdoller(this.state.invoiceTotal)}</span>
            <div className="action-group d-flex flex-row mr-auto">
              <Button type="button"
              //className="btn-outline-primary btnHeight"
              //  onClick={(e) => { this.handleBtnEvent(e, "", 'bulk') }}
               onClick={(x) => this.approveInvoices()}
              >Approve Invoice</Button>
            </div>
          </div>
        </div>)}
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>


                </Tab.Pane>
               

              </Tab.Content>

            </Tab.Container>




            {(showSlideout &&
                <InvoiceEntryslideout
                  closeSlideOut={this.closeSlideOut}
                  orderIds={this.state.orderIds}
                  invoiceId={this.state.invoiceId}
                >


                </InvoiceEntryslideout>
              )}

          </Container>

          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

        </React.Fragment>
      </>
    );
  }
}