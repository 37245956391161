import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { Header } from "./Header";
import { Breadcrumbs, IBreadcrumbsProps } from "./Breadcrumbs";
import { protectedRoutes } from "../Routes";
import { User } from "../Services/User";
import { Spinner } from "react-bootstrap";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import IdleTimer from "react-idle-timer";
 
import { SessionTimeout } from "./SessionTimeout";
import moment from "moment";

export const ProtectedLayout: React.FC = (props: any) => {
    
  const idleTimer: any = useRef(null);
  const [showModal, setShowModal] = React.useState(false);
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
 
  
  React.useEffect(() => {
    if (idleTimer.current) {
      idleTimer.current.start();
      //console.log('timer started at' + new Date());
    }
 
  }, []);


 

  let deepLinkingURL = window.location.pathname;
  let deepLinkingParam = window.location.search;
  localStorage.setItem("deepURL", deepLinkingURL);
  localStorage.setItem("deepParam", deepLinkingParam);

  if (!User.isAuthenticated) {
     return <Redirect to="/login" />;
   // isTokenActive();
  }

 

  const logout = () => {
    User.removeToken()
      .then(async (result: any | null) => {
        if (result) {
          User.isAuthenticated = false;
          User.apiLoginResponse = null;
          localStorage.clear();
          sessionStorage.clear();
         
          
          window.location.replace("/login");
        }
        resolve();
      })
      .catch((err) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });

    localStorage.setItem("token", "");
    localStorage.setItem("useDefaultTheme", "");
  };

 
  const handleLogout = (isAutoLogOut =false) => {
    setShowModal(false);
       
    props.history.push("/login");
    User.RemoveAuthToken();

  };

  const handleClose = () => {
    
    if (showModal) {
      setShowModal(false);
      if (!document.hidden) {
        
      }

      if(idleTimer.current)
      {
        idleTimer.current.start();
        console.log('timer started at' + new Date());
      }
      
    }
  };

  const handleOnIdle = () => {
    if (idleTimer.current) {
      idleTimer.current.pause();
    }
    //console.log('User is idle' + new Date());
    setShowModal(true);
  };

 const handleOnActive = (event:any) => {
  //console.log('User did something' + new Date(), event);
    if (idleTimer.current) {
      idleTimer.current.reset();
    }
  };

  const  handleOnAction = (event: any) => {
    //console.log('User did something'+ new Date(), event);
    if (idleTimer.current) {
      idleTimer.current.reset();
    }
  };

 
  
  const getRefreshTokenTimeOut = (): number => {
    const expirationTime = localStorage?.getItem("expirationTime") || "";
    const duration: any = moment.duration(
      moment(expirationTime)?.diff(moment())
    );
    const seconds = duration._data.minutes * 60 + duration._data.seconds;
    return seconds || 0;
  };

  
  
  return (
    <React.Suspense
      fallback={
        <div className="pageLoader">
          <Spinner animation="border" />
        </div>
      }
    >
 <React.Fragment>
  <div className="layout">
        <Header />
        
        <main className="p-2 px-lg-4 d-flex flex-column h-100">
        
       
        <IdleTimer
          crossTab={{
            emitOnAllTabs: true,
          }}
          ref={idleTimer}
          element={document}
         
          debounce={250}
          //timeout={idleTime}
          timeout={1000 * 60 * 15} // 15 minutes
          onIdle={handleOnIdle}
          onActive={handleOnActive}
          onAction={handleOnAction}
          startManually={true}
        />
        





        <Switch>
          {protectedRoutes.map(({ path, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={(props: any) => {
                if (localStorage.getItem("token") != null) {
                  let isPermitted = true;
                  if (isPermitted) {
                    return (
                      <div >
                        <Component {...props} />
                      </div>
                    );
                  } else {
                    return <Redirect to="/unauthorize-access" />;
                  }
                }
              }}
            />
          ))}
          <Route
            render={() => {
              return (
                <div>
                  <Breadcrumbs crumbs={{} as IBreadcrumbsProps[]} />
                </div>

              );
            }}
          />
        </Switch>
       
        <SessionTimeout
              showModal={showModal}
              handleClose={handleClose}
              handleLogout={handleLogout}
            />
            
           
             </main>
            </div>
      </React.Fragment>

    </React.Suspense>
  );
};
