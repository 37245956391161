import * as React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem
} from "react-bootstrap";
import { Link } from "react-router-dom";

export interface IBreadcrumbsProps {
  path: string;
  name: string;
  Component: React.FunctionComponent;
}

export class Breadcrumbs extends React.Component<{
  crumbs: IBreadcrumbsProps[];
}> {
  render() {
    if (this.props.crumbs.length === undefined) {
      return <div className="wht-bg breadcrumb-mrgn"></div>;
    }
    return (
      <div className="wht-bg breadcrumb-mrgn">
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumb>
                {this.props.crumbs.map(({ name, path }, key) =>
                  key + 1 < this.props.crumbs.length ? (
                    <li className="breadcrumb-item" key={key}>
                      <Link to={path}>{name}</Link>
                    </li>
                  ) : (
                    <BreadcrumbItem key={key} active>
                      {name}
                    </BreadcrumbItem>
                  )
                )}
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
