import * as React from "react";
import { Container, Dropdown, Accordion, Card, Button ,Form,Spinner,} from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { BankService } from "../../Common/Services/BankService";
import { BankMasterSlidout } from "../../Modules/Masters/BankMasterSlidout";

import paginationFactory from 'react-bootstrap-table2-paginator';

import _ from "lodash";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
const { SearchBar } = Search;

  
export class BankMaster extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
         
          htmlItem: [],
          htmlErrorItem: [],
          htmlWarningItem: [],
          searcResult: [],
          bankId:0,
          selectedcolumn: "All",
          selectOptions: [
            { id: 'All', name: 'All'},
            { id: 'Bank Name', name: 'Bank Name' },
            { id: 'Account Type', name: 'Account Type' },
            { id: 'Phone Number', name: 'Phone Number' },
            { id: 'Email', name: 'Email' },

          ],
        };
        
     
      }
      componentDidMount() {
        let requestlist = { ...this.state.requestlist };
        this.getBankMaster(requestlist);
      }

      handleSelectedItem1 = (control: any, id: any) => {
        let searcResult1 = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.searcResult))
        );
    
        let chkisbankname = id == "Bank Name" ? true : false;
        let chkisaccounttype = id == "Account Type" ? true : false;
        let chkisPhoneNumber = id == "Phone Number" ? true : false;
        let chkisemail= id == "Email" ? true : false;
    
    
        if (id == "All") {
          chkisemail = true;
          chkisbankname = true;
          chkisaccounttype = true;
          chkisPhoneNumber = true;
    
        }
    
    
        this.setState({
          searcResult: [], selectedcolumn: id, isphone_number: chkisPhoneNumber, isemail: chkisemail,
          isbankname: chkisbankname, isaccounttype: chkisaccounttype
        },
          () => {
            this.setState({ searcResult: searcResult1 });    
          }
    
    
        );
    
      };


 
      getBankMaster = (requestlist: any) => {
 

        BankService.GetBanks(requestlist)
    .then(async (result: any[] | null) => {
        
      if (result != null) {
        
        this.setState({ searcResult: result }, () => {
           
        });
      }
      resolve();
    })
    .catch((err: any) => {
      toast.error(`Server Error, ${err}`);
      reject();
    });  
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      this.setState({ showSlideout: true ,bankId:row.id,isSpinner:false});  
    },
  };
  
  showNewSlideOut = () => {
    this.setState({ showSlideout: true ,bankId:0,isSpinner:false});  
  };

  closeSlideOut = () => {
    this.setState({ showSlideout: false, isSpinner:false, });
    let requestlist = { ...this.state.requestlist };
    this.getBankMaster(requestlist);
};


  render() {
    const {
      
      showSlideout,
      
  } = this.state;

  const columns = [
    {
      searchable: this.state.isbankname,
      dataField: 'bankName',
      text: 'Bank Name',
    },
   
    {
      
      searchable: this.state.isaccounttype,
      dataField: 'accountType',
      text: 'Account Type',
      sort: true,
    },
    { 
      searchable: this.state.isphone_number,
      dataField: 'phone',
      text: 'Phone Number',
    },
    
    
    { 
      searchable: this.state.isemail,
      dataField: 'email',
      text: 'Email',
    },
     
  ];

    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">

            Bank Master
              <div className="action-group d-flex flex-row ml-auto">
                <Button onClick={this.showNewSlideOut}>Add Bank</Button>
              </div>
            </div>
            <section className="mt-3">
            <ToolkitProvider
        keyField="id"
        data={this.state.searcResult}
        columns={columns}

         //className="table table-striped table-borderless align-middle"
        search
      >
        {
          props => (


            <div >
              <section className="d-flex flex-wrap gap-3 pb-2">
                <div className="position-relative">
                  <SearchBar {...props.searchProps} />
                </div>

                <div>
                  <Form.Label>
                    <SingleDropdownListWithoutSearch
                      
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                      defaultItem={this.state.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}

                    /></Form.Label>
                </div>

              </section>
              <section className="pb-3">
                <div className="table-responsive divbottom"  >
                  <div className="ml-auto transactions-list">
                  <BootstrapTable
                    {...props.baseProps}
                    rowEvents={this.rowEvents}
                    keyField="rowId"
                  

                  />
                  </div>
                </div>
            

              </section>
            </div>

          )
        }
 </ToolkitProvider>
 </section>
{(showSlideout &&

<BankMasterSlidout
closeSlideOut={this.closeSlideOut}
bankId={this.state.bankId}
>


</BankMasterSlidout>)}





          </Container>



        </React.Fragment>
      </>
    );
  }
}