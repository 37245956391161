import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 import { Utils } from "../Utilis";
export class DashboardServices {

public static GetCustomerDashboard = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Dashboard/GetCustomerDashboard`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetEmpDashboard = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Dashboard/GetEmpDashboard`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};
}