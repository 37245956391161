import Instense from "./Axios-config";
import { Utils } from "../Utilis";
export class JobOrderService {


  public static GetJobOrderList = async (request: any): Promise<any | null> => {  
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
      
    request.tenantid = tenantID;
    const headers = {
      Authorization: `Bearer ${Utils.GetUserToken()}` 
    };
    const url = `/Job/JobOrderList`;
    return Instense.get(url, { params: request,headers }).then((response) => {
     
      let result = response.data.result as any;
      
      return result;
    });
  };


public static GetJobDetails = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  request.tenantid = tenantID;
  const url = `/Job/JobDetails`;  //?tenantid=0&jobId=8
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static SaveJobData = async (request: any): Promise<any> => {

  const url = `/Job/AssignJobs/${request[0].jobid}`;
  return Instense.put(url, request).then((response) => {
    // debugger;
      let result = response.data.result as any[];
      return result;
  });
};




public static GetVendorOrderList = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

    
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Vendor/GetVendorOrderlist`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static GetOrderlistForReceiving = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

    
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Vendor/GetOrderlistForReceiving`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};

public static GetJobOrderByAssigned = async (request: any): Promise<any | null> => {  
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);

    
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Job/GetJobOrderByAssigned`;
  return Instense.get(url, { params: request,headers }).then((response) => {
   
    let result = response.data.result as any;
    
    return result;
  });
};
}


