import * as React from "react";
import {
  Container,
  Dropdown,
  Form,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import paginationFactory from "react-bootstrap-table2-paginator";
import _ from "lodash";
import createDOMPurify from "dompurify";
import moment from "moment";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

export class Notificationslideout extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeKey: "second",
    };
  }

  closeSlideOut = () => {
    this.props.closeSlideOut();
  };

  handleSelect = (key) => {
    this.setState({ activeKey: key });
  };

  render() {
    const { activeKey } = this.state;

    return (
      <section>
        <div className="d-flex">
          <div
            className="back-drop1 viewer-panel1 opacity z-1"
            onClick={() => this.closeSlideOut()}
          ></div>

          <Container
            fluid
            className="body-sec viewer-panel back-drop p-0"
            style={{
              minWidth: "45%",
              width: "80%",
              maxWidth: "600px",
              borderRadius: "10px",
              backgroundColor: "#F5FBFF",
              border: "2px solid grey",
              zIndex: 12
            }}
          >
            <div className="divheader">
              <h5 className="fs-4 my-title fw-bolder">Notifications</h5>
            </div>
            <div>
              <Tab.Container
                transition={false}
                activeKey={activeKey}
                onSelect={this.handleSelect}
              >
                <section className="p-3">
                  <Nav
                    className="nav border-top border-bottom"
                    style={{ borderBottomColor: "#007bff" }}
                  >
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="first"
                        className={`fs-5 fw-medium ${
                          activeKey === "first" ? "text-warning" : ""
                        }`}
                      >
                        Tasks
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="second"
                        className={`fs-5 fw-medium ${
                          activeKey === "second" ? "text-warning" : ""
                        }`}
                      >
                        Mentions
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </section>

                <Tab.Content>
                  <Tab.Pane eventKey="second">
                    <section className="p-2">
                      {/* <div className="notification-item d-flex align-items-center mb-3">
                        <svg
                          width="45"
                          height="45"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-3"
                        >
                          <path
                            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM12 14c-3.31 0-6 2.69-6 6v2h12v-2c0-3.31-2.69-6-6-6z"
                            fill="#808080"
                          />
                        </svg>
                        <span className="fs-6">
                          Chugh.Vaibhav mentioned you on the tracker for
                          JO#10898
                        </span>
                      </div>
                      <div className="notification-item d-flex align-items-center">
                        <svg
                          width="45"
                          height="45"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-3"
                        >
                          <path
                            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM12 14c-3.31 0-6 2.69-6 6v2h12v-2c0-3.31-2.69-6-6-6z"
                            fill="#808080"
                          />
                        </svg>
                        <span className="fs-6">
                          John.Doe mentioned you on the tracker for JO#10898
                        </span>
                      </div> */}
                    </section>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </section>
    );
  }
}
