import * as React from "react";
import logo from "../Common/Assets/Images/logo.png";
import maintenance from "../Common/Assets/Images/maintenance.png";
import { User } from "../Common/Services/User";

export class Maintenance extends React.Component<any, any> {


    componentDidMount() {
        User.isAuthenticated = false;
        User.apiLoginResponse = null;
        localStorage.clear();
        sessionStorage.clear();
        this.isUnderMaintenance();
      }


      isUnderMaintenance() {
        User.UnderMaintenance().then(async (result: any | null) => {
          if (result.message === "success") {
            this.setState({ isMaintenance: result.result });
            if (result.result === 0) {
              window.location.href = "/login";
              return false;
            }
          };
        });
      }

    render() {
        return (
            <div className="maintenance">
                <div className="header d-flex justify-content-center align-items-center">
                    <div className="logo">
                         
                    </div>
                    <div className="logo-text">Cimmple</div>
                </div>                
                <div className="body d-flex flex-column justify-content-center align-items-center">
                    
                    
                </div>
            </div>            
        );
    };
}